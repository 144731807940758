<template>
    <div>
        <!-- Sidebar -->
        <ul class="navbar-nav bg-gradient-success sidebar sidebar-dark accordion" id="accordionSidebar">

            <!-- Sidebar - Brand -->
            <router-link to="/DashboardHome" class="sidebar-brand d-flex align-items-center justify-content-center">                   
                <div class="sidebar-brand-icon rotate-n-15">
                    <i class="fas fa-laugh-wink fa-sm"></i>
                </div>
                <div class="sidebar-brand-text mx-3">AF Workspace </div>
            </router-link>

            <!-- Divider -->
            <hr class="sidebar-divider my-0">

            <!-- Nav Item - Dashboard -->
            <li class="nav-item active">
                <router-link to="/DashboardHome" class="nav-link">
                    <i class="fas fa-fw fa-chart-pie"></i>
                    <span>Dashboard</span>
                </router-link>
            </li>

            <!-- Divider -->
            <hr class="sidebar-divider">

            <!-- Heading -->
            <div class="sidebar-heading">
                LYRICS REQUEST
            </div>

            <template v-for="(route, i) in routes">
            <!---Single Menu -->
            <li class="nav-item" :key="i" v-if="route.session=='lyrics'">
                <router-link :to="`${route.to}`" class="nav-link">
                <i :class="route.icon"></i>
                <span>{{ route.title }}</span>
                </router-link>
            </li>
            </template>

            <!-- Divider -->
            <hr class="sidebar-divider">

            <!-- Heading -->
            <div class="sidebar-heading">
                Lyrics
            </div>
            
            <template v-for="(route, i) in routes">
            <!---Single Menu -->
            <li class="nav-item" :key="i" v-if="route.session=='lrcActions' && (!route.restricted || isEditorUser)">
                <router-link :to="`${route.to}`" class="nav-link">
                <i :class="route.icon"></i>
                <span>{{ route.title }}</span>
                </router-link>
            </li>
            </template>
            <li class="nav-item">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseOtherAction" aria-expanded="true" aria-controls="collapseOtherAction">
                    <i class="fas fa-fw fa-plus-circle"></i>
                    <span>Others actions</span>
                </a>                    
                <div id="collapseOtherAction" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <router-link to="/CheckLyrics" class="collapse-item">
                           Check Lyrics
                        </router-link>
                        <router-link to="/FormLocalSong" class="collapse-item">
                           Local player
                        </router-link>
                        <router-link to="/PlayerForAppleMusic" class="collapse-item">
                           Apple player
                        </router-link>                        
                    </div>
                </div>
            </li>
            <hr>
            
            <div class="sidebar-heading" v-if="isEditorUser">
                Settings
            </div>
            
            <li class="nav-item" v-if="isEditorUser">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                    <i class="fas fa-fw fa-user"></i>
                    <span>Users</span>
                </a>                    
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <template v-for="(route, i) in routes">
                            <router-link :to="`${route.to}`" class="collapse-item" :key="i" v-if="route.session=='admins' && route.subSession=='users'">
                                {{ route.title }}
                            </router-link>
                        </template>
                    </div>
                </div>
            </li>
            
            <template v-for="(route, i) in routes">
            <!---Single Menu -->
            <li class="nav-item" :key="i" v-if="route.session=='admins' && !route.subSession">
                <router-link :to="`${route.to}`" class="nav-link">
                <i :class="route.icon"></i>
                <span>{{ route.title }}</span>
                </router-link>
            </li>
            </template>

            <!-- Divider -->
            <hr class="sidebar-divider d-none d-md-block">

            <!-- Sidebar Toggler (Sidebar) -->
            <div class="text-center d-none d-md-inline">
                <button class="rounded-circle border-0" id="sidebarToggle"></button>
            </div>
        </ul>
        <!-- End of Sidebar -->
        <!-- End of Content Wrapper -->
    </div>
</template>

<script>
import SidebarData from "./SidebarData";
import {Constants} from "../utils/DC_player"

const CONSTANTS = new Constants();
const $ = require("jquery");
window.$ = $;
export default {
  data() {
    return {
        routes: SidebarData,
        isEditorUser: false,
        isAdminUser: false,
    }
  },

  methods: {
     /*** Manage permission and role, use just in vue */
    configuration() { 
      this.isEditorUser = (CONSTANTS.SHOW_ALL_LRC.includes(this.user.roleId));
      this.isAdminUser = (CONSTANTS.LRC_MANAGE_USER_ROLES_PERMISSIONS.includes(this.user.roleId));
    },
  },

  mounted: async function () {
    this.configuration();
  },

  computed: {
    user() {
      return this.$store.state.User;
    }
  },
}
</script>

<style scoped>

</style>