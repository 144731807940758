<template>
<div>
    <div class="modal fade" id="editLrcModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content" id="modelLrcEditContent">
                <div class="modal-header">
                    <h4 class="modal-title headerEdit" id="staticBackdropLabel">
                        EDIT LRC FILE: <strong> {{music.artist}}, {{music.title}} </strong>
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBtn" v-on:click="closeModal">
                        
                    </button>
                </div>
                <div class="modal-body modalBodyContent">
                    
                    <Spinner v-bind:showSpinner="showSpinner" type="lg"></Spinner>

                    <div class="text-start mx-3"  :hidden="!showSpinner">
                        <textarea id="LRCText" class="form-control areaEditLrc" v-model="lyrics"></textarea>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="closeModal">Cancel</button>
                    <button type="button" class="btn btn-success" @click="saveFileEdited()" :disabled="isNoEdit">Update LRC</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Spinner from './SpinnerAfBig.vue';
import router from "../router";
import * as service from "./../utils/DC_CalloutService.js";

const $ = require("jquery");
window.$ = $;

export default {
    name: "LyricsEditContent",

    components:{
        Spinner
    },

    data() {
        return {
            lrcEdited: '',
            lyricsTmp: '',
            showSpinner: false
        };
    },

    props: {
        music: Object,
        isShowModal: Boolean,
        lyrics: String,
        isSettings: Boolean
    },

    methods: {
        async saveFileEdited() {
            let fileLrc = new File(
                [this.lyrics],
                `[AF_Lyrics]${this.music.title}_${this.music.artist}.lrc`
            );
            const data = await service.updateLrc(this.music.id, this.music.artist, this.music.title, fileLrc, null, this.user.token);
            const res = data.status == 200;
            if(res) {
                this.showToast('Success update', 'success');
                const btn = document.getElementById("closeBtn");
                btn.click();
                // this.closeModal();
            }else{
                this.showToast('Update error', 'error');
            }
        },

        createBtn() {
            let btn = document.createElement('button');
            btn.setAttribute('data-bs-toggle', "modal");
            btn.setAttribute('data-bs-target', "#editLrcModal");
            btn.style.display = "none";
            document.body.appendChild(btn);
            btn.click();
        },

        closeModal() {
            this.showSpinner = false;
            this.$parent.lyrics_p = '';
            this.$parent.isShowModal = false;
        },

        showToast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 5000
            })
        }
    },

    mounted: function () {
        if (!this.isLoggedIn) router.push({ name: "login" });
    },

    computed: {
        isLoggedIn() {
            return this.$store.state.login;
        },

        user() {
            return this.$store.state.User;
        },

        isNoEdit() {
            return this.lyrics != this.music.lyrics ? false : true;
        }
    },

    watch: {
        isShowModal: function (val) {
            if (val == true) {                
                this.createBtn();
                this.showSpinner = (this.lyrics == '') ? false : true;
            }
        },

        lyrics: function(val){
            this.showSpinner = (val == '' && this.isNoEdit) ? false : true;
        },

        isSettings: function(val){
            if(val == true){
                $('#modelLrcEditContent').addClass('editLrcContentModal');
                $('#LRCText').removeClass('form-control');
            }else{
                $('#modelLrcEditContent').removeClass('editLrcContentModal');
            }
        }
    }
};
</script>

<style scoped>
html, body {
  height:100%;
}

.headerEdit {
    text-align: center;
    color: rgb(9, 46, 9);
}

.modalBodyContent{
    height: 100vh;
    overflow: hidden;
}

.areaEditLrc {
    width: 100%;
    height: 500px;
}

.editLrcContentModal{
    /* background-color: #171818; */
    color: rgb(165, 168, 167);
}

.editLrcContentModal textarea {
    font-family: "Poppins", sans-serif;
    border: 1px solid green;
    /* color: rgb(208, 211, 210);
    background-color: #171818; */
    padding: 10px;
    width: 100%;
}

.editLrcContentModal textarea ::placeholder {
    border: 0px;
    /* color: white; */
    /* background-color: #171818; */
}

.editLrcContentModal textarea:focus {
    /* background-color: #171818;
    color: white; */
    outline: none !important;
}
</style>
