import WFPlayer from 'wfplayer';
import {Sub} from '@/components/export_video/utils/sub'
export const editor = {
   namespaced: true,
   
    state: {
        availableClientWidth:0,
        waveform: null,
        count:0,
        subs:[],
        render:{
          padding: 2,
          duration: 90,
          gridGap: 10,
          gridNum: 110,
          beginTime: -5,
        },
        currentTime:0,
        grabbing:false,
        player:null
    },
    actions:{
        //[container]document.querySelector('#waveform')
        //[mediaElement]document.querySelector('#video')
        initPlayer(context, {container, mediaElement}){
          console.log("initPlayer", {container, mediaElement});
           var wfplayer =  new WFPlayer({
                scrollable: true,
                useWorker: false,
                duration: 10,
                padding: 1,
                wave: true,
                pixelRatio: 2,
                container:container,
                mediaElement: mediaElement,
               
            });
           wfplayer.load(mediaElement)

           // click event
         wfplayer.on('click', (currentTime) => {
           context.commit("setCurrentTime",currentTime)
          });

          // grab event
         wfplayer.on('grabbing', (currentTime) => {
           context.commit("setCurrentTime",currentTime)
          });
          // scroll event
         wfplayer.on('scroll', (deltaY) => {
          context.commit("setCurrentTime",wfplayer.currentTime + deltaY / 10)
        });
        wfplayer.on('update', (val)=>{
          var newRender = {duration:val.totalDuration,...val}
          context.commit("setRender",newRender)
        });
         context.commit('setWaveform',wfplayer)
        },
        initVideoPlayer(context,player){
          context.commit("setPlayer",player)
         
          player.addEventListener('timeupdate',  () =>{
            context.commit('updateStoreCurrentTime', player.currentTime)
           })
        },
       
    },
    mutations: {
      setSubtitle (state,newVal){
        state.subs =newVal
      },
      clearSubs  (state) {
          state.subs =[]
      },
       newSub (state,item) {
        return new Sub(item)
       },
       updateSub (state, {sub, obj}) {
          const index = this.getters['editor/hasSub'](sub);
          if (index < 0) return;
          const subs = this.getters['editor/copySubs']()
          const subClone = this.getters['editor/formatSub'](sub) 

         
          Object.assign(subClone, obj);
          if (subClone.check) {
              subs[index] = subClone;
              state.subs =subs;
          }
      },
      setRender(state,value){
        state.render =value
      },
      updateStoreCurrentTime(state,time){
        state.currentTime =time
      },
      setPlayer(state,value){
        state.player =value
      },
      setAvailableClientWidth(state,value){
        state.availableClientWidth =value
      },
      setGrabbing(state,value){
        state.grabbing =value
      },
     
      setCurrentTime(state,time){
        state.waveform.seek(time)
        state.currentTime =time
        state.player.currentTime =time
      },
      setWaveform(state,wfplayer){
        state.waveform =wfplayer
      },
      setSubs (state,subs) {
        state.subs =subs
      },
      removeSub(state,sub)  {
          const index = this.getters['editor/hasSub'](sub);
          if (index < 0) return;
          const subs = this.getters.copySubs();
          subs.splice(index, 1);
          state.subs =subs
      }

    },
    getters: {
      formatSub:()=>(sub) => {
        if (Array.isArray(sub)) {
            return sub.map((item) => new Sub(item));
        }
        return new Sub(sub);
    },
      copySubs:(state,getters) => ()=> {
        return getters.formatSub(state.subs)
      },
      checkSub:(state,getters)=>(sub) => {
        const index =getters.hasSub(sub);
        if (index < 0) return;
        const previous = state.subs[index - 1];
        return (previous && sub.startTime < previous.endTime) || !sub.check || sub.duration < 0.2;
    },
      hasSub: (state) => (sub) => {
        return state.subs.indexOf(sub)
      },
      currentIndex(state){
        if(!state.subs || !state.currentTime){
          return null
        }
        return   state.subs.findIndex((item) => item.startTime <= state.currentTime && item.endTime > state.currentTime)
      }
    }
  }