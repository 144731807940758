const axios = require("axios");
axios.interceptors.request.use(
    (config) => {
      if (config.authorization !== false) {
        const token = localStorage.getItem("access_token");
        if (token) {
          config.headers.Authorization = "Bearer " + token;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// const SERVER_BASE_URL = 'http://localhost:8080';
const SERVER_BASE_URL = 'https://lrc.afrikalyrics.com'
const CLIENT_SECRET = 'spamUGenOjl99BBFVpRDMKcTTvtf2QiB43a2oxWJ'
const CLIENT_ID = '3'

async function getLogin(username, password) {
    const config = {
        grant_type: "password",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        username: username,
        password: password,
        scope: "*",
    };
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Access-Control-Allow-Origin": "*"
    };
    let token = '';
    await axios
        .post(`${SERVER_BASE_URL}/oauth/token`, config, headers)
        .then((result) => {
            token = result.data.access_token;
            localStorage.setItem("access_token",token);
        })
        .catch((error) => {
            console.log('error code to log user: ', error);
            token = 'error';
        });
    return token;
}

async function getClientToken() {
    const config = {
        grant_type: "client_credentials",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        scope: "*",
    };
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Access-Control-Allow-Origin": "*"
    };
    let clientToken = '';
    await axios
        .post(`${SERVER_BASE_URL}/oauth/token`, config, headers)
        .then((result) => {
            clientToken = result.data.access_token;
        })
        .catch(() => {
            clientToken = 'error';
        });
    return clientToken;
}

const getClientToken2 = async ()=>{
   let config = {
        "grant_type": "client_credentials",
        "client_id": CLIENT_ID,
        "client_secret":CLIENT_SECRET,
        "scope": "*"
    }
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };
   let response =  await axios
        .post(`${SERVER_BASE_URL}/oauth/token`, config, headers)
    return response.data.access_token
}
async function getSyncAuthLogin(token) {
    const config = {
        token
    };
    let clientToken = await getClientToken2()
    console.log(clientToken);
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization":`Bearer ${clientToken}`
    };
   
    var response =  await axios
        .post(`${SERVER_BASE_URL}/api/register-main-user`, config, {headers})
        .then((result) => {
            return result.data;
        })

    console.log(response);
       
    return response;
}

async function resetPassword(email, clientToken) {
    let data;
    var headers = {
        headers: {
            Authorization: `Bearer ${clientToken}`,
            Accept: 'application/json'
        }
    };

    const config = {
        email: email
    }

    await axios
        .post(`${SERVER_BASE_URL}/api/oauth/forgot-password`, config, headers)
        .then((result) => {
            data = result;
        })
        .catch((error) => {
            console.log('@@@@ reset error: ', error);
            data = 'error';
        });
    return data;
}

async function fetchLyrics(s, token) {
    let data;
    var headers = {
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
            Accept: 'application/json'
        }
    };
    await axios.get(`${SERVER_BASE_URL}/api/lyrics/suggestions?s=${s}`, headers).then((result) => {
            data = result;
        })
        .catch(function(error) {
            console.log(error);
        });
    return data;
}

async function getUser(token) {
    let data;
    var headers = {
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json'
        }
    };
    await axios.get(`${SERVER_BASE_URL}/api/me`, headers).then((result) => {
            data = result;
        })
        .catch(function(error) {
            console.log(error);
        });
    return data;
}

async function saveLrc(artistName, title, fileLrc, fileTxt, provider, externalId, token, lrcStatus, otherParams) {
    const data = new FormData();
    data.append('artist_name', artistName);
    data.append('title', title);
    data.append('lrc', fileLrc, fileLrc.name);
    data.append('lyric', fileTxt, fileTxt.name);
    data.append('lang_id', '1');
    data.append('external_id', externalId);
    data.append('provider', provider);
    data.append('status', lrcStatus);
    if (otherParams) {
        data.append('album', otherParams.album);
        data.append('author', otherParams.musicWrittenBy);
        data.append('assigned_to', otherParams.usersSelected[0]);
        data.append('assigned_by', otherParams.userId);
    }

    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
    }

    let res;
    await axios.post(`${SERVER_BASE_URL}/api/lyric-media`, data, { headers: headers })
        .then((result) => {
            res = result
        })
        .catch((error) => {
            console.log(error);
            res = error;
        });
    return res;
}

async function updateLrc(id, artistName, title, fileLrc, lrcTag, token, fileText = '', lrcStatus) {
    const data = new FormData();
    data.append('artist_name', artistName);
    data.append('title', title);
    if (fileLrc) data.append('lrc', fileLrc, fileLrc.name);
    if (fileText) data.append('lyric', fileText, fileText.name);
    if (lrcTag) data.append('tags', lrcTag);
    if (lrcStatus) data.append('status', lrcStatus);
    // data.append('lang_id', '1');
    data.append('_method', 'PUT');

    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
    }

    let res;
    await axios.post(`${SERVER_BASE_URL}/api/lyric-media/${id}`, data, { headers: headers })
        .then((result) => {
            res = result
        })
        .catch((error) => {
            console.log(error);
            res = error;
        });
    return res;
}

async function getUserListLrc(idUser, pageNumber, token) {
    let data;
    var headers = {
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json'
        }
    };
    await axios.get(`${SERVER_BASE_URL}/api/lyric-media?user_id=${idUser}&page=${pageNumber}`, headers).then((result) => {
            data = result;
        })
        .catch(function(error) {
            console.log(error);
        });
    return data;
}

async function getFile(fileName, token=null) {
    let data;
    console.log(token);
    await axios.get(`${SERVER_BASE_URL}/api/files/${fileName}`)
        .then((result) => {
            data = result;
        })
        .catch(function(error) {
            console.log(error);
        });
    return data;
}

async function getAllListLrc(pageNumber, token, idUser = null) {
    // return new Promise((res, rej) => {
    const userParam = idUser ? `?user_id=${idUser}&` : '?';
    let data = [];
    var headers = {
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json'
        }
    };
    await axios.get(`${SERVER_BASE_URL}/api/lyric-media${userParam}page=${pageNumber}`, headers)
        .then((result) => {
            data = result;
            // res(data);
        })
        .catch(function(error) {
            console.log(error);
            // rej(error);
        });
    // })
    return data;
}

const getLyricDetails =async (id,token)=>{
    let data = [];
    var options = {
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json'
        }
    };
    data= await axios.get(`${SERVER_BASE_URL}/api/lyric-media/${id}`, options)
        
    return data.data.data;
}

async function searchExistLyricsSync(searchWith, searchKey, token) {
    let data = [];
    var headers = {
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json'
        }
    };
    await axios.get(`${SERVER_BASE_URL}/api/lyric-media?${searchWith}=${searchKey}`, headers)
        .then((result) => {
            data = result;
        })
        .catch(function(error) {
            console.log(error);
        });
    return data;
}

async function searchExistLrcComp(artist, title, token) {
    let data = [];
    var headers = {
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json'
        }
    };
    await axios.get(`${SERVER_BASE_URL}/api/lyric-media?artist_name=${artist}&title=${title}`, headers)
        .then((result) => {
            data = result;
        })
        .catch(function(error) {
            console.log(error);
        });
    return data;
}


async function getUsersList(token) {
    let data;
    var headers = {
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json'
        }
    };
    await axios.get(`${SERVER_BASE_URL}/api/users`, headers).then((result) => {
            data = result;
        })
        .catch(function(error) {
            console.log(error);
        });
    return data;
}

async function getRolesPermissions(token) {
    let data;
    var headers = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json'
        }
    };
    await axios.get(`${SERVER_BASE_URL}/api/roles-permissions`, headers).then((result) => {
            data = result;
        })
        .catch(function(error) {
            console.log(error);
        });
    return data;
}

async function updateUserRolesPermissions(userId, roles, permissions, token) {
    const config = {
        user_id: userId,
        roles: roles,
        permissions: permissions
    };
    const headers = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    let data;
    await axios
        .put(`${SERVER_BASE_URL}/api/roles-permissions`, config, headers)
        .then((result) => {
            data = result;
        })
        .catch((error) => {
            data = error;
        });
    return data;
}

async function createUser(user, token) {
    let requestBody = {
        "name": user.name,
        "username": user.username,
        "email": user.email,
        "password": user.password,
        "role": user.role
    }
    const headers = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res;
    await axios
        .post(`${SERVER_BASE_URL}/api/oauth/register`, requestBody, headers)
        .then((result) => {
            res = result
        })
        .catch((error) => {
            res = error;
        });
    return res;
}

export {
    getLogin,
    fetchLyrics,
    saveLrc,
    getUser,
    getUserListLrc,
    getFile,
    updateLrc,
    getAllListLrc,
    searchExistLyricsSync,
    getClientToken,
    resetPassword,
    getUsersList,
    getRolesPermissions,
    updateUserRolesPermissions,
    createUser,
    searchExistLrcComp,
    getSyncAuthLogin,
    getLyricDetails
}
