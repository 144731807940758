export default class SubStyle{

    constructor(obj) {
        this.Name = obj.Name??'Default';
        this.Fontname = obj.start??'Microsoft YaHei';
        this.Fontsize = obj.Fontsize??20;
        this.PrimaryColour = obj.PrimaryColour?? '&H00FFFFFF';
        this.SecondaryColour = obj.SecondaryColour??'&H000000FF';
        this.OutlineColour = obj.OutlineColour??'&H00000000'

        this.BackColour = obj.BackColour??'&H00000000'
        this.Bold = obj.Bold??0
        this.Italic = obj.Italic??0
        this.Underline = obj.Underline??0
        this.StrikeOut = obj.StrikeOut??0

        this.ScaleX = obj.ScaleX ?? 100
        this.ScaleY = obj.ScaleY??100

        this.Spacing = obj.Spacing??0
        this.Angle = obj.Angle??0
        this.BorderStyle = obj.BorderStyle??1
        this.Outline = obj.Outline??1

        this.Shadow = obj.Shadow??0
        this.Alignment = obj.Alignment??2
        this.MarginL = obj.MarginL??10
        this.MarginR = obj.MarginR??10
        this.MarginV = obj.MarginV??10
        this.Encoding = obj.Encoding??134
    }
   
toString(){
   return `${this.Name}, ${this.Fontname},${this.Fontsize}, ${this.PrimaryColour}, ${this.SecondaryColour}, ${this.OutlineColour}, ${this.BackColour}, ${this.Bold}, ${this.Italic}, ${this.Underline}, ${this.StrikeOut}, ${this.ScaleX}, ${this.ScaleY}, ${this.Spacing}, ${this.Angle}, ${this.BorderStyle}, ${this.Outline}, ${this.Shadow}, ${this.Alignment}, ${this.MarginL}, ${this.MarginR}, ${this.MarginV}, ${this.Encoding}`
}
}