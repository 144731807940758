import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../components/pages/Dashboard.vue';
import DashboardHome from '../components/adminComponents/AdminHome.vue';
import addLyricRequestForm from '../components/adminComponents/addLyricRequestForm.vue';
import AllLyricsRequest from '../components/adminComponents/AllLyricsRequest.vue';
import UserLyricsToCreate from '../components/adminComponents/UserLyricsToCreate.vue';
import UserLyricsReviews from '../components/adminComponents/UserLyricsReviews.vue';
import UserCompletedLyrics from '../components/adminComponents/UserCompletedLyrics.vue';
import UsersTracking from '../components/adminComponents/UsersTracking.vue';
import AllLyricsSync from '../components/adminComponents/AllLyricsSync.vue';
import AddNewUser from '../components/adminComponents/AddNewUser.vue';
import AllUsers from '../components/adminComponents/AllUsers.vue';
import CheckLyrics from '../components/adminComponents/CheckLyrics.vue';
import FormLocalSong from '../components/adminComponents/FormLocalSong.vue';
import PlayerForLocalSong from '../components/adminComponents/PlayerForLocalSong.vue';
import PlayerForAppleMusic from '../components/adminComponents/PlayerForAppleMusic.vue';
import LoginPage from '../components/pages/Login.vue';
import AuthSync from '../components/pages/AuthSync.vue'
import ExportVideoHome from '../components/export_video/export_video_home.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/auth-sync',
        name: 'AuthSync',
        component: AuthSync
    },
    {
        path: "/dashboard",
        component: Dashboard,
        children: [
            {
                name: "addLyricRequestForm",
                path: "/addLyricRequestForm",
                component: addLyricRequestForm,
                props: true
            },
            {
                name: "DashboardHome",
                path: "/DashboardHome",
                component: DashboardHome,
                props: true
            },
            {
                name: "AllLyricsRequest",
                path: "/AllLyricsRequest",
                component: AllLyricsRequest,
                props: true
            },
            {
                name: "UserLyricsToCreate",
                path: "/UserLyricsToCreate",
                component: UserLyricsToCreate,
                props: true
            },
            {
                name: "UserLyricsReviews",
                path: "/UserLyricsReviews",
                component: UserLyricsReviews,
                props: true
            },
            {
                name: "UserCompletedLyrics",
                path: "/UserCompletedLyrics",
                component: UserCompletedLyrics,
                props: true
            },
            {
                name: "UsersTracking",
                path: "/UsersTracking",
                component: UsersTracking,
                props: true
            },
            {
                name: "AllLyricsSync",
                path: "/AllLyricsSync",
                component: AllLyricsSync,
                props: true,
            },
            {
                name: "AddNewUser",
                path: "/AddNewUser",
                component: AddNewUser,
                props: true
            },
            {
                name: "AllUsers",
                path: "/AllUsers",
                component: AllUsers,
                props: true
            },
            {
                name: "CheckLyrics",
                path: "/CheckLyrics",
                component: CheckLyrics,
                props: true
            },
            {
                name: "FormLocalSong",
                path: "/FormLocalSong",
                component: FormLocalSong,
                props: true
            },
            {
                name: "PlayerForLocalSong",
                path: "/PlayerForLocalSong",
                component: PlayerForLocalSong,
                props: true
            },
            {
                name: "PlayerForAppleMusic",
                path: "/PlayerForAppleMusic",
                component: PlayerForAppleMusic,
                props: true
            },
            {
                name: "export-video ",
                path: "/export-video",
                component: ExportVideoHome,
                props: true
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router