<template>
    <div ref="blockRef" class="timeline">
        <div ref="subsRef">
            <SubTimelineItem v-for="(sub,key) of currentSubs" 
            :key="key" 
            :sub="sub"
            :currentIndex="currentIndex"
            :highlight="currentIndex==key"
            :left="render.padding * gridGap + (sub.startTime - render.beginTime) * gridGap * 10" 
            :width="(sub.endTime - sub.startTime) * gridGap * 10"
            @onMouseDown="onMouseDown"
             />
        </div>
    </div>
</template>

<script>
import DT from 'duration-time-conversion';

import {getKeyCode} from '@/components/export_video/utils/index'

import SubTimelineItem from "./SubTimelineItem.vue"

export default {
    components:{
        SubTimelineItem
    },
    data(){
        return {
                lastTarget: null,
                lastSub : null,
                lastType : '',
                lastX : 0,
                lastIndex :-1,
                lastWidth : 0,
                lastDiffX : 0,
                isDroging : false,
                leftBarW:0
        }
    },
    methods:{
        getCurrentSubs(subs, beginTime, duration) {
            return subs.filter((item) => {
                return (
                    (item.startTime >= beginTime && item.startTime <= beginTime + duration) ||
                    (item.endTime >= beginTime && item.endTime <= beginTime + duration) ||
                    (item.startTime < beginTime && item.endTime > beginTime + duration)
                );
            });
        },
        magnetically(time, closeTime) {
            if (!closeTime) return time;
                if (time > closeTime - 0.1 && closeTime + 0.1 > time) {
                    return closeTime;
                }
                return time;
        },
         onMouseDown  (sub, event, type)  {
            this.lastSub = sub;
            console.log(sub);
            if (event.button !== 0) return;
            this.isDroging = true;
            this.lastType = type;
            this.lastX = event.pageX;
            this.lastIndex = this.currentSubs.indexOf(sub);
            this.lastTarget = this.$refs.subsRef.children[this.lastIndex];
            this.lastWidth = parseFloat(this.lastTarget.style.width);

            console.log(" this.lastIndex:"+ this.lastIndex,type);
        },

         onDocumentMouseUp ()  {
            if (this.isDroging && this.lastTarget && this.lastDiffX) {
                const timeDiff = this.lastDiffX / this.gridGap / 10;
                const index = this.$store.getters['editor/hasSub'](this.lastSub);
                const previou = this.subtitle[index - 1];
                const next = this.subtitle[index + 1];

                const startTime =this. magnetically(this.lastSub.startTime + timeDiff, previou ? previou.endTime : null);
                const endTime =this. magnetically(this.lastSub.endTime + timeDiff, next ? next.startTime : null);
                const width = (endTime - startTime) * 10 * this.gridGap;

                if ((previou && endTime < previou.startTime) || (next && startTime > next.endTime)) {
                    //
                } else {
                    if (this.lastType === 'left') {
                        if (startTime >= 0 && this.lastSub.endTime - startTime >= 0.2) {
                            const start = DT.d2t(startTime);
                            this.$store.commit("editor/updateSub",{
                              sub:  this.lastSub,obj: { start }
                            });
                        } else {
                            this.lastTarget.style.width = `${width}px`;
                        }
                    } else if (this.lastType === 'right') {
                        if (endTime >= 0 && endTime - this.lastSub.startTime >= 0.2) {
                            const end = DT.d2t(endTime);
                            this.$store.commit("editor/updateSub",{
                                sub:this.lastSub, obj:{ end }
                            });
                        } else {
                            this.lastTarget.style.width = `${width}px`;
                        }
                    } else {
                        if (startTime > 0 && endTime > 0 && endTime - startTime >= 0.2) {
                            const start = DT.d2t(startTime);
                            const end = DT.d2t(endTime);
                            this.$store.commit("editor/updateSub",{
                                sub:this.lastSub,obj: {
                                start,
                                end,
                            }
                            });
                        } else {
                            this.lastTarget.style.width = `${width}px`;
                        }
                    }
                }

                this.lastTarget.style.transform = `translate(0)`;
            }

            this.lastType = '';
            this.lastX = 0;
            this.lastWidth = 0;
            this.lastDiffX = 0;
            this.isDroging = false;
        },
       onDocumentMouseMove(event)  {
            if (this.isDroging && this.lastTarget) {
                this.lastDiffX = event.pageX - this.lastX
                if (this.lastType === 'left') {
                    this.lastTarget.style.width = `${this.lastWidth - this.lastDiffX}px`;
                    this.lastTarget.style.transform = `translate(${this.lastDiffX}px)`;
                } else if (this.lastType === 'right') {
                    this.lastTarget.style.width = `${this.lastWidth + this.lastDiffX}px`;
                } else {
                    this.lastTarget.style.transform = `translate(${this.lastDiffX}px)`;
                }
            }
        },
         onKeyDown(event) {
            console.log("Key down",event,getKeyCode(event));
                const sub = this.currentSubs[this.lastIndex];
               if (sub && this.lastTarget) {
                    const keyCode = getKeyCode(event);
                    switch (keyCode) {
                        case 37:
                            this.$store.commit("editor/updateSub",{
                                sub, obj:{
                                start: DT.d2t(sub.startTime - 0.1),
                                end: DT.d2t(sub.endTime - 0.1),
                            }
                            });
                          
                           this.$store.commit("editor/setCurrentTime",sub.startTime - 0.1)
                            break;
                        case 39:
                            this.$store.commit("editor/updateSub",{
                                sub,obj: {
                                start: DT.d2t(sub.startTime + 0.1),
                                end: DT.d2t(sub.endTime + 0.1),
                            }
                            });
                          
                            this.$store.commit("editor/setCurrentTime",sub.startTime +0.1)
                            break;
                        case 8:
                        case 46:
                        this.$store.commit("editor/removeSub",sub);
                            break;
                        default:
                            break;
                    }
                }
            },
        observeSideBarW() {
            const resizeObserver = new ResizeObserver(()=> {
                console.log("Size changed");
                let box = document.querySelector('#accordionSidebar');
                let  leftW = this.leftBarW;
                if(box){
                    leftW = box.offsetWidth;
                }
                this.leftBarW = leftW
                this.$store.commit("editor/setAvailableClientWidth",document.body.clientWidth -this.leftBarW)
            });

            console.log(document.getElementById('#accordionSidebar'));
            if(document.querySelector('#accordionSidebar')){
                resizeObserver.observe(document.querySelector('#accordionSidebar'));
            }else{
                setTimeout(()=>{
                    this.observeSideBarW()
                },2000)
            }

            
            }
    },

    computed:{
        player(){
            return this.$store.state.editor.player
        },
        render(){
            return this.$store.state.editor.render
        },
        subtitle() {
            return this.$store.state.editor.subs;
        },
        currentSubs() {
            return this.getCurrentSubs(this.subtitle, this.render.beginTime, this.render.duration);
        },
        availableWidth(){
            return this.$store.state.editor.availableClientWidth
        },
        gridGap(){
            return this.availableWidth /  this.render.gridNum;
        },
        currentTime(){
            return this.$store.state.editor.currentTime;
        },
        currentIndex(){
            return this.currentSubs.findIndex( (item) => item.startTime <= this.currentTime && item.endTime > this.currentTime )
        }
        
    },


    mounted(){
        document.addEventListener('mousemove', this.onDocumentMouseMove);
        document.addEventListener('mouseup', this.onDocumentMouseUp);
        window.addEventListener('keydown', this.onKeyDown);
        this.observeSideBarW()
    }
}
</script>

<style lang="scss" scoped>
    .timeline{
        position: absolute;
        z-index: 9;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .react-contextmenu-wrapper {
            position: absolute;
            z-index: 9;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: all;
        }

       
    }
</style>