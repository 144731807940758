<template>
    <div class="tools">
            <div class="top">
                <div class="import">
                    <div class="btn">
                       OPEN_VIDEO
                        <input class="file" type="file" @change="onVideoChange" @click="onInputClick" />
                    </div>
                    <!-- <div class="btn">
                        OPEN_SUB
                        <input class="file" type="file" @change="onSubtitleChange" @click="onInputClick" />
                    </div> -->
                </div>
                <div class="import">
                    <div class="btn" >
                        OPEN_SUB
                        <input  class="file" type="file" @change="onSubtitleChange" @click="onInputClick" />
                    </div>
                </div>
              
                    <div class="burn" @click="burnSubtitles">
                        <div class="btn">
                            EXPORT_VIDEO {{processing!=null && processing!=0? parseFloat(`${processing}`).toFixed(2)+"%":''  }}
                        </div>
                    </div>
                <div class="export">
                    <div class="btn" @click="() => downloadSub('ass')">
                        EXPORT_ASS
                    </div>
                    <!-- <div class="btn" @click="() => downloadSub('srt')">
                        EXPORT_SRT
                    </div>
                    <div class="btn" @click="() => downloadSub('vtt')">
                        EXPORT_VTT
                    </div> -->
                </div>
                <!-- <div class="operate">
                    <div
                        class="btn"
                        @click="onClearSubsClicked"
                    >
                    CLEAR
                    </div>
                   
                </div> -->
                
               
               
            </div>
          
        </div>
</template>

<script>
import { getExt, download ,isVideo} from '../utils';
import { file2sub, sub2vtt, sub2srt, sub2txt } from '../libs/readSub';
import sub2ass from '../libs/readSub/sub2ass';
import FFmpeg from '@ffmpeg/ffmpeg';
import SimpleFS from '@forlagshuset/simple-fs';
import Sub from '../libs/Sub';
const fs = new SimpleFS.FileSystem();
const notify =({message, level})=>{
    console.log(`${level}:${message}`);
}
    export default {
        data(){
            return {
                loading :"",
                processing: 0,
                videoFile:null,
                isFileVideo:null
            }
        },
        computed:{

            subtitle(){
                return  this.$store.state.editor.subs
            },
            waveform(){
                return  this.$store.state.editor.waveform
            },
            player(){
                return  this.$store.state.editor.player
            }
        },
        methods:{
            onClearSubsClicked(){
                if (window.confirm('CLEAR_TIP') === true) {
                    this.$store.commit("editor/clearSubs");
                    window.location.reload();
                }
            },
            onInputClick (event) {
                event.target.value = '';
            },
            async decodeAudioData   (file) {
                try {
                    const { createFFmpeg, fetchFile } = FFmpeg;
                    const ffmpeg = createFFmpeg({ log: true ,corePath: "/ffmpeg-core/ffmpeg-core.js",});
                    ffmpeg.setProgress(({ ratio }) => {
                        this.processing = (ratio * 100)
                    });
                    this.loading = 'LOADING_FFMPEG'
                    await ffmpeg.load();
                    console.info("Fetching file "+file.name)
                    var fetchedFile = await fetchFile(file)

                    var newName = `${Date.now()}`
                    ffmpeg.FS('writeFile', newName, fetchedFile);
                    this.loading = ''
                    const output = `${Date.now()}.mp3`;
                    await ffmpeg.run('-i', newName, '-ac', '1', '-ar', '8000', output);
                    const uint8 = ffmpeg.FS('readFile', output);
                    // download(URL.createObjectURL(new Blob([uint8])), `${output}`);
                    await this.waveform.decoder.decodeAudioData(uint8);
                    this.waveform.drawer.update();
                   this.processing =0;
                    ffmpeg.setProgress(() => null);
                   
                } catch (error) {
                    console.log("decodeAudioData",error);
                    this.loading ="";
                   this.processing =0;
                    
                }
            },

           async  burnSubtitles (){
                try {
                    const { createFFmpeg, fetchFile } = FFmpeg;
                    const ffmpeg = createFFmpeg({ log: false ,corePath: "/ffmpeg-core/ffmpeg-core.js", });
                    ffmpeg.setProgress(({ ratio }) =>this.processing = ratio * 100);
                    this.loading ='LOADING_FFMPEG';
                    await ffmpeg.load();
                    this.loading ='LOADING_FONT';

                    await fs.mkdir('/fonts');
                    const fontExist = await fs.exists('/fonts/Microsoft-YaHei.ttf');
                    if (fontExist) {
                        const fontBlob = await fs.readFile('/fonts/Microsoft-YaHei.ttf');
                        ffmpeg.FS('writeFile', `tmp/Microsoft-YaHei.ttf`, await fetchFile(fontBlob));
                    } else {
                        const fontUrl = 'https://cdn.jsdelivr.net/gh/zhw2590582/SubPlayer/docs/Microsoft-YaHei.ttf';
                        const fontBlob = await fetch(fontUrl).then((res) => res.blob());
                        await fs.writeFile('/fonts/Microsoft-YaHei.ttf', fontBlob);
                        ffmpeg.FS('writeFile', `tmp/Microsoft-YaHei.ttf`, await fetchFile(fontBlob));
                    }
                    this.loading ='LOADING_VIDEO';
                    ffmpeg.FS(
                        'writeFile',
                        this.videoFile.name,
                        await fetchFile(this.videoFile || 'sample.mp4'),
                    );
                   this.loading ='LOADING_SUB';
                    const subtitleFile = new File([new Blob([sub2ass(this.subtitle)])], 'subtitle.ass');
                    ffmpeg.FS('writeFile', subtitleFile.name, await fetchFile(subtitleFile));
                    this.loading ="";
                    notify({
                        message: ('BURN_START'),
                        level: 'info',
                    });
                    let output = `${Date.now()}.mp4`;

                    //convert audio to  video if the input is audio
                    if(!this.isFileVideo){
                        await ffmpeg.run(
                        '-i',
                        this.videoFile.name,
                        '-f',
                        'lavfi',
                        '-i',
                        'color=c=blue:s=640x480:r=15',
                        '-acodec',
                        'copy',
                        '-shortest',
                        output,
                        );
                        
                        let output2 = `${Date.now()}.mp4`;
                    await ffmpeg.run(
                        '-i',
                        output,
                        '-vf',
                        `subtitles=${subtitleFile.name}`,
                        output2,
                    );
                        const uint8 = ffmpeg.FS('readFile', output2);
                        download(URL.createObjectURL(new Blob([uint8])), `${output2}`);
                        const uintass = ffmpeg.FS('readFile', subtitleFile.name);
                        download(URL.createObjectURL(new Blob([uintass])),subtitleFile.name);
                    }else{
                        await ffmpeg.run(
                            '-i',
                           this.videoFile.name,
                            '-vf',
                            `subtitles=${subtitleFile.name}`,
                            output,
                        );
                        const uint8 = ffmpeg.FS('readFile', output);
                        download(URL.createObjectURL(new Blob([uint8])), `${output}`);
                    }
                    this.processing =0;
                    ffmpeg.setProgress(() => null);
                    
                } catch (error) {
                    console.log(error);
                    this.loading ="";
                   this.processing =0;
                    
                }
           },

     onVideoChange (event)  {
            const file = event.target.files[0];
            if (file) {
                const ext = getExt(file.name);
                this.isFileVideo = isVideo(file.name)
                const canPlayType = this.player.canPlayType(file.type);
                if (canPlayType === 'maybe' || canPlayType === 'probably') {
                    this.videoFile =  new File([file], `${Date.now()}.${ext}`);
                    this.decodeAudioData(file);
                    const url = URL.createObjectURL(new Blob([file]));
                    this.player.src = url;

                    this.waveform.decoder.destroy();
                    this.waveform.drawer.update();

                    this.$nextTick(() => {
                        this.$store.commit("editor/updateStoreCurrentTime",0)

                        if(!this.$store.state.editor.subs){
                            this.$store.commit("editor/clearSubs")
                            this.$store.commit("editor/setSubtitle",[new  Sub({
                                    start: '00:00:00.000',
                                    end: '00:00:01.000',
                                    text: 'SUB_TEXT',
                                })])
                        }
                        
                        
                        this.player.src = url;
                    });


                    
                } else {
                    notify({
                        message: `${('VIDEO_EXT_ERR')}: ${file.type || ext}`,
                        level: 'error',
                    });
                }
            }
        },

     onSubtitleChange (event)  {
            const file = event.target.files[0];
            if (file) {
                const ext = getExt(file.name);
                if (['ass', 'vtt', 'srt', 'json'].includes(ext)) {
                    file2sub(file)
                        .then((res) => {
                            this.$store.commit("editor/clearSubs")
                            this.$store.commit("editor/setSubtitle",res)
                          
                        })
                        .catch((err) => {
                            notify({
                                message: err.message,
                                level: 'error',
                            });
                        });
                } else {
                    notify({
                        message: `${('SUB_EXT_ERR')}: ${ext}`,
                        level: 'error',
                    });
                }
            }
        },

     downloadSub(type) {
            let text = '';
            const name = `${Date.now()}.${type}`;
            switch (type) {
                case 'vtt':
                    text = sub2vtt(this.subtitle);
                    break;
                case 'srt':
                    text = sub2srt(this.subtitle);
                    break;
                case 'ass':
                    text = sub2ass(this.subtitle);
                    break;
                case 'txt':
                    text = sub2txt(this.subtitle);
                    break;
                case 'json':
                    text = JSON.stringify(this.subtitle);
                    break;
                default:
                    break;
            }
            const url = URL.createObjectURL(new Blob([text]));
            download(url, name);
        }
    },}


        
    
</script>

<style lang="scss" scoped>
    .tools{
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;
    background-color: rgb(0 0 0 / 100%);
    border-left: 1px solid rgb(255 255 255 / 20%);

    .import {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);
        cursor: pointer;

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 100%;
            border-radius: 3px;
            color: #fff;
           
            font-size: 13px;
            background-color: #3f51b5;
            transition: all 0.2s ease 0s;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        }

        .file {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            cursor: pointer;
            height: 100%;
            opacity: 0;
        }
    }

    .burn {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 100%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #673ab7;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .export {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
           
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 100%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #009688;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .operate {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        .btn {
            position: relative;
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 48%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #009688;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .translate {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid rgb(255 255 255 / 20%);

        select {
            width: 65%;
            outline: none;
            padding: 0 5px;
            border-radius: 3px;
        }

        .btn {
            opacity: 0.85;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 33%;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            background-color: #673ab7;
            transition: all 0.2s ease 0s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .hotkey {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        span {
            width: 49%;
            font-size: 13px;
            padding: 5px 0;
            border-radius: 3px;
            text-align: center;
            color: rgb(255 255 255 / 75%);
            background-color: rgb(255 255 255 / 20%);
        }
    }

    .bottom {
        padding: 10px;
        a {
            display: flex;
            flex-direction: column;
            border: 1px solid rgb(255 255 255 / 30%);
            text-decoration: none;

            .title {
                color: #ffeb3b;
                padding: 5px 10px;
                animation: animation 3s infinite;
                border-bottom: 1px solid rgb(255 255 255 / 30%);
            }

            @keyframes animation {
                50% {
                    color: #00bcd4;
                }
            }

            img {
                max-width: 100%;
            }
        }
    }

    .progress {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 9;
        height: 2px;
        background-color: rgb(0 0 0 / 50%);

        span {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0;
            height: 100%;
            background-color: #ff9800;
            transition: all 0.2s ease 0s;
        }
    }
}
</style>