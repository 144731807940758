<template>
  <div>
    <div id="music-player">
      <div class="row pt-2 align-items-cente">
        <div class="col-2 text-center"></div>
        <div class="col-4 text-center">
          <!-- <div class="d-flex"> -->
            <!-- <div>
              <img src="https://afrikalyrics.com/assets/images/logo-fav.png" style="width: 60px; height: 60px" class="rounded-circle"/>
            </div> -->
            <!-- <div> -->
            <marquee>
              <h5 class="song-name">{{ music ? music.title : '' }}</h5>
              <h5 class="artist-name">{{ music ? music.artist : ''}}</h5>
            </marquee>
            <!-- </div> -->
          <!-- </div> -->
        </div>
        <div class="col-6 float-end" :hidden="!isPublishAccess">
            <a type="button" class="btn btn-sm btn-success float-end me-3" @click="downloadLyrics(lrcStatusPublished, 0)">
                <i class="bi bi-cloud-arrow-up"></i>
                Publish
            </a>
        </div>
      </div>

      <!-- Begin print lyrics -->
      <div class="lyrics hideItem" id="lrcItem">
        <div class="lyrics-content">
          <div class="mt-5">
            <h2 v-for="(line, index) in lyricsLines" :key="index">
              {{ line }}
            </h2>
          </div>
          <div
            class="float-end mt-2"
            style="margin-right: 250px"
            :hidden="showSaveLrc"
          >
          <button type="button" class="btn btn-success" @click="saveLrcInput(lrcStatusWaiting)">Save LRC</button>
          </div>
        </div>
      </div>

      <div class="lyrics hideItem" id="lrcPlay">
        <div class="lyrics-content mt-5">
          <h3 v-for="(line, index) in lyricsLinesNew" :key="index">
            {{ line }}
          </h3>
        </div>
      </div>

      <div class="lyrics mb-2" id="lyricsItem">
        <textarea placeholder="Paste Your Lyrics Text Here"  v-model="lyricsInput"></textarea><br />
        <div class="float-end mt-2 mb-2" style="margin-right: 80px">
          <button class="btn btn-secondary mx-4"  data-bs-toggle="modal"  data-bs-target="#searchLyricsModal" @click="searchLyrics()">Search Lyrics</button>
          <button type="submit"  class="btn btn-secondary" @click="saveLyricsInput()" :disabled="showSaveLyrics">Save</button>
        </div>
      </div>
      <!-- End print Lyrics -->

      <!-- Begin player -->
      <audio  id="player"  preload="true" :src="music ? music.src : ''" @durationchange="setupSeek()" @timeupdate="initProgressBar()"></audio>

      <div id="playerMedia">
        <div id="bar">
          <div id="seekObjContainer">
            <div id="currentTime" class="start-time"></div>
            <input  id="seekObj"  type="range"  name="rng"  min="0"  step="0.25"  value="0" @input="seekAudio()"  class="seek custom"/>
            <div id="totalTime" class="end-time"></div>
          </div>
        </div>
        <div class="menu row">
          <div class="col-sm-6">
            <span class="hideSmallMedia">
              <button> <i class="bi bi-volume-up"></i></button>
              <input  id="volume"  type="range"  name="volu"  min="0"  max="100"  step="10"  value="50" @change="volumeSetup()" @input="volumeSetup()"  class="vol" />
            </span>

            <button @click="repeat()"  id="repeat"  class="btnColor"  data-bs-toggle="tooltip"  data-bs-placement="top"  title="Repeat">
              <i class="bi bi-arrow-repeat"></i>
            </button>

            <button id="play1-btn" @click="play()">
              <img :src="getImgUrl(playIconSrc)" style="height: 35px; width: 35px"/>
            </button>
            <button id="play2-btn" class="hideItem" @click="play()">
              <img :src="getImgUrl(playIconSrc)" style="height: 35px; width: 35px"/>
            </button>

            <button @click="keepTime()"  id="lrcOk"  class="btnColor"  data-bs-toggle="tooltip"  data-bs-placement="top"  title="Keep Time" :disabled="isPlayLrc">
              <i class="bi bi-stopwatch"></i>
            </button>

            <button @click="previous()"  data-bs-toggle="tooltip"  data-bs-placement="top"  title="Previous Line"  class="btnColor" :disabled="isPlayLrc">
              <i class="bi bi-arrow-left"></i>
            </button>

            <button @click="next()"  data-bs-toggle="tooltip"  data-bs-placement="top"  title="Next Line"  class="btnColor" :disabled="isPlayLrc">
              <i class="bi bi-arrow-right"></i>
            </button>
          </div>

          <div class="col-sm-6">
            <button class="menuItem float-end" :disabled="!isEditLRC" @click="editLrc()">
              <span id="editLrc"> <i class="bi bi-music-note-list"></i> LRC </span>
            </button>

            <button class="menuItem float-end" :disabled="!isEditLRC" @click="editLyrics()">
              <span id="editLyrics"><i class="bi bi-card-text"></i> Lyrics </span>
            </button>

            <button class="menuItem float-end" @click="playAsLrc()">
              <span id="playAsLrc"><i class="bi bi-fonts"></i> LRC sync </span>
            </button>
          </div>
        </div>
        <pre id="event_listener" style="height: 100px; overflow: auto"></pre>
      </div>
      <!-- End player -->
    </div>

    <!-- Modal -->
    <button type="button" id="warnModal" class="hideItem" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ></button>
    <div class="modal fade" id="staticBackdrop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body alert alert-warning" role="alert" id="modalBody">
            {{ toastMessage }}
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="searchLyricsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Search lyrics text for:
              <strong><b>{{ music.title }} </b></strong>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBtn"></button>
          </div>
          <div class="modal-body">
            <div  class="spinner-grow text-success  text-center"  style="width: 3rem; height: 3rem"  role="status" :hidden="showSpinner">
              <span class="sr-only"></span>
            </div>
            <div :hidden="!showSpinner">
              <select class="form-select"  aria-label="Default select example" v-model="lyricsSelected">
                <option disabled value="">Choose lyrics text</option>
                <option v-for="(lyric, index) in lyricsSearch" :key="index">
                  {{ lyric.tag }}
                </option>
              </select>
              <div id="parole" class="mt-5"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-success" click="saveSelected()" disabled="isEmptySearch">Save selected</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
"use strict";
import { Lrc } from "lrc-kit";
import { Runner } from "lrc-kit";
import router from "../../router";
import { Constants } from '../../utils/DC_player.js';
import * as service from "../../utils/DC_CalloutService.js";

const $ = require("jquery");
window.$ = $;
const CONSTANTS = new Constants();

export default {
  name: "LyricsPlayerLocal",
  components: {
      
  },
  props: {
    music_p: Object,
  },
  data() {
    return {
      music: {},
      isSeeking: false,
      seek: document.getElementById("seekObj"),
      volume: document.getElementById("volume"),
      player: document.getElementById("player"),
      prevcurrentime: 0,
      playIconSrc: "play_button.png",

      lrcStatusDraft: CONSTANTS.LRC_STATUS_DRAFT,
      lrcStatusWaiting: CONSTANTS.LRC_STATUS_WAITING,
      lrcStatusVerfied: CONSTANTS.LRC_STATUS_VERFIED,
      lrcStatusPublished: CONSTANTS.LRC_STATUS_PUBLISHED,

      lrc: {},
      lyricsInput: "",
      lyricsAlternativeText: "NO LYRICS TEXT FOR THIS SONG.",
      isLyricsEmpty: true,
      lyricsLines: [],
      lyricsLinesNew: [],
      lyricsTimestamp: 0,
      linePosition: 0,
      isDownloadLrc: true,
      htmlLineIndex: 0,
      counter: 0,
      play1: 0,
      isPlayLrc: true,
      playList: [],
      playListIndex: 0,
      tmpTimestamp: [],
      timeList: [],
      lyricsSearch: [],
      showSpinner: true,
      lyricsSelected: "",
      isEmptySearch: true,
      oldTitle: "",
      toastMessage: '',
      isSaveDisable: true,
      isPublishAccess: false,
      isEditLRC: true,
    };
  },
  methods: {
    init() {
      this.isSeeking = false;
      this.seek = document.getElementById("seekObj");
      this.player = document.getElementById("player");
      this.volume = document.getElementById("volume");
      this.lyricsLines.push(this.lyricsAlternativeText);
      this.player.volume = this.volume.value / 100;
      this.prevcurrentime = 0;
      this.checkPlayList(this.music);
    },
    
    getImgUrl(pet) {
      let images = require.context("../../assets/artistsImg/", false, /\.*$/);
      return images("./" + pet);
    },

    pushLyrics(content, timestamp, i) {
      let current = $("#lrcItem .current");
      if (current.length != 0) {
        this.tmpTimestamp[i] = {
          content: content.split("]").slice(-1),
          timestamp: timestamp,
        };
      }
    },

    loadSyncLrc() {
      let content;
      // this.isPlayLrc = true;
      if (this.lrc && !this.lrc.lyrics.length) {
        this.lyricsLinesNew.pop();
        this.lyricsLinesNew.push(this.lyricsAlternativeText);
        return;
      }
      content = this.lrc.toString({ combine: false });
      let lrcRunner = new Runner(Lrc.parse(content));
      this.timeList = [];
      this.lyricsLinesNew = [];
      lrcRunner.lrc.lyrics.forEach((element) => {
        this.timeList.push(element.timestamp - 0.5);
        this.lyricsLinesNew.push(element.content);
      });
    },

    saveLyricsInput() {
      this.lyricsLines = [];
      this.lyricsLines = this.lyricsInput
        .trim()
        .replace(/\[[^\]]*\]/g, "")
        .split("\n")
        .filter((line) => line.trim());
        
      for (let index = 0; index < this.lyricsLines.length; index++) {
        let t = $(`#lrcItem h2:nth-child(${index + 1})`)
          .text()
          .split("]");
        if (t.length == 2) {
          $(`#lrcItem h2:nth-child(${index + 1})`).html(
            `${t[0]}]${this.lyricsLines[index]}`
          );

          this.pushLyrics(
            this.lyricsLines[index],
            this.transformTextTimeToSecond(t[0].replaceAll('[', '')),
            index
          );   
        }
      }
      this.playList[this.playListIndex].lyrics = this.lyricsLines;
      this.editLrc();
    },

    saveLrcInput(lrcStatus, source = 0) {
      if (!this.tmpTimestamp[0]) {
        $("modalBody").removeClass("alert-success");
        $("modalBody").addClass("alert-warning");
        this.toastMessage = "Please add at least one line of lyrics with time before saving.";
        $("#warnModal").click();
        return false;
      }

      this.lrc.lyrics = [];
      this.lrc.lyrics = this.tmpTimestamp;
      this.loadSyncLrc();
      this.playAsLrc();

      if(source != 1) this.downloadLyrics(lrcStatus, 1);

      return true;
    },

    wrapperLyrics(lines) {
      let li = [];
      lines.forEach((line) => {
        li.push(line.split("]").slice(-1));
      });
      this.lyricsInput = [];
      this.lyricsInput = li.join("\n");
    },

    editLyrics() {
      $("#lrcItem").addClass("hideItem");
      $("#lrcPlay").addClass("hideItem");
      $("#lyricsItem").removeClass("hideItem");

      $("#editLrc").removeClass("currentItem");
      $("#playAsLrc").removeClass("currentItem");
      $("#editLyrics").addClass("currentItem");
      // $("#lrcItem").scrollTop(0);
      this.isPlayLrc = true;
    },

    editLrc() {
      $("#lyricsItem").addClass("hideItem");
      $("#lrcPlay").addClass("hideItem");
      $("#lrcItem").removeClass("hideItem");

      $("#editLrc").addClass("currentItem");
      $("#playAsLrc").removeClass("currentItem");
      $("#editLyrics").removeClass("currentItem");
      this.isPlayLrc = false;
    },    

    downloadLyrics(lrcStatus, source) {      
      if (source == 0) {
          if (this.tmpTimestamp.length != this.lyricsLines.length) {
            $("modalBody").removeClass("alert-success");
            $("modalBody").addClass("alert-warning");
            this.toastMessage = "Please complete all lyrics Lines with time before saving";
            $("#warnModal").click();
            return;
        }    
      }

      this.lrc.info["ar"] = this.music.artist;
      this.lrc.info["ti"] = this.music.title;
      this.lrc.info["al"] = this.music.album;
      this.lrc.info["length"] = this.music.time;
      this.lrc.info["by"] = "Afrika Lyrics";
      this.lrc.info["re"] = "LRC Editor Web App";
      this.lrc.info["ve"] = "Version 1.0";
      const content = this.lrc.toString({ combine: false });

      let fileLrc = new File(
        [content],
        `[AF_Lyrics]${this.music.title}_${this.music.artist}.lrc`
      );
      let fileTxt = new File(
        [this.lyricsInput],
        `[AF_Lyrics]${this.music.title}_${this.music.artist}.txt`
      );

      if(+this.music_p.id){
        this.ws_updateLrc(fileLrc, fileTxt, lrcStatus);
      }else{
        this.ws_saveLrc(this.music.artist, this.music.title, fileLrc, fileTxt, lrcStatus);
      }
      
      if(this.playlist) this.playlist[this.playListIndex].lrc = this.lrc;
    },

    downloadLyricsText() {
      if (!this.lyricsInput) {
          $("modalBody").removeClass("alert-success");
          $("modalBody").addClass("alert-warning");
          this.toastMessage = "Please complete lyrics text area before saving";
          $("#warnModal").click();
          return;
      }

      this.download(
        this.lyricsInput,
        `[AF_Lyrics]${this.music.title}_${this.music.artist}.txt`
      );
      
      let fileTxt = new File(
        [this.lyricsInput],
        `[AF_Lyrics]${this.music.title}_${this.music.artist}.txt`
      );

      this.ws_saveLrc(this.music.artist, this.music.title, new File(['empty'], 'empty.lrc'), fileTxt);
    },

    download(contentFile, fileName) {
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(contentFile)
      );
      element.setAttribute("download", fileName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },

    ws_saveLrc(artistName, title, fileLrc, fileTxt, lrcStatus) {
      this.toastMessage = '';
      service.saveLrc(artistName, title, fileLrc, fileTxt, 'Local', '', this.user.token, lrcStatus)
        .then((result) => {
          if (result.data) {
            $("modalBody").removeClass("alert-warning");
            $("modalBody").addClass("alert-success");
            this.toastMessage = "File save success !!";
            this.music.id = result.data.data.id;
          } else {
            $("modalBody").removeClass("alert-success");
            $("modalBody").addClass("alert-warning");
            this.toastMessage = "File not save !!";
          }
        })
        .catch(function (error) {
          console.log("@@@@@ fetch lyrics error: ", error);
          $("modalBody").removeClass("alert-success");
          $("modalBody").addClass("alert-warning");
          this.toastMessage = "System Error! Please contact administrator";
        });
        $("#warnModal").click();
    },

    ws_updateLrc(fileLrc, fileTxt, lrcStatus) {
      this.toastMessage = '';
      service.updateLrc(this.music.id, this.music.artist, this.music.title, fileLrc, null, this.user.token, fileTxt, lrcStatus)
        .then((result) => {
          console.log("@@@@@ result fetch lyrics: " + result);
          if (result) {
            $("modalBody").removeClass("alert-warning");
            $("modalBody").addClass("alert-success");
            this.toastMessage = "File update success !!";
            console.log("@@@@@ result fetch lyrics: " + result);
          } else {
            $("modalBody").removeClass("alert-success");
            $("modalBody").addClass("alert-warning");
            this.toastMessage = "File not update !!";
          }
        })
        .catch(function (error) {
          console.log("@@@@@ fetch lyrics error: ", error);
          $("modalBody").removeClass("alert-success");
          $("modalBody").addClass("alert-warning");
          this.toastMessage = "System Error! Please contact administrator";
        });
        $("#warnModal").click();
    },

    playAsLrc() {
      $("#lrcItem").addClass("hideItem");
      $("#lyricsItem").addClass("hideItem");
      $("#lrcPlay").removeClass("hideItem");

      $("#editLrc").removeClass("currentItem");
      $("#playAsLrc").addClass("currentItem");
      $("#editLyrics").removeClass("currentItem");
      // if (!this.lrc.lyrics.length) {
      //   this.lyricsLinesNew = [];
      //   this.lyricsLinesNew.push(this.lyricsAlternativeText);
      //   return;
      // }

      // this.counter = 0;
      this.player.addEventListener("timeupdate", () => {
        if (this.counter == 0) {
          if (this.player.currentTime < this.timeList[this.counter]) {
            this.previous1();
          }
        }
        if (
          this.counter == this.timeList.length &&
          this.player.currentTime <= this.timeList[this.counter - 1]
        ) {
          this.counter--;
          this.previous1();
        }
        if (this.player.currentTime >= this.timeList[this.counter]) {
          if (this.counter <= this.timeList.length) {
            this.counter++;
          }
          this.next1();
          this.centerize1();
        } else if (this.player.currentTime < this.timeList[this.counter - 1]) {
          this.counter--;
          this.previous1();
        }
      });
    },

    centerize1() {
      if (this.play1 == 0) return;
      if ($(".current1").length == 0) return;
      let a = $(".current1").height();
      let c = $("#lrcPlay").height();
      let d =
        $(".current1").offset().top - $(".current1").parent().offset().top;
      let e = d + a / 2 - (c * 1) / 4;
      $("#lrcPlay").animate({ scrollTop: e + "px" });
    },

    next1() {
      let current = $("#lrcPlay .current1");
      if (current.length == 0) {
        $("#lrcPlay h3:nth-child(1)").addClass("current1");
        return;
      }
      current.removeClass("current1");
      current.next().addClass("current1");
    },

    previous1() {
      let current = $("#lrcPlay .current1");
      if (current.length == 0) {
        return;
      }
      let first = $("#lrcPlay h3:nth-child(1)");
      current.removeClass("current1");
      if (current === first) {
        return;
      }
      current.prev().addClass("current1");
    },

    repeat() {
      this.player.currentTime = 0;
    },

    checkPlayList(mus) {
      this.lrc = new Lrc();
      console.log("@@@ playlist song: ", this.playList);
      for (let index = 1; index < this.playList.length; index++) {
        if (this.playList[index].id === mus.id) {
          this.music = this.playList[index];
          this.playListIndex = index;
          this.isExit = true;
          break;
        }
      }
      if (mus && mus.lyricsText) {
        this.lyricsInput = mus.lyricsText;
        if (!mus.lyrics) this.saveLyricsInput();
      }
      if (mus && mus.lrc) {
        this.lrc = mus.lrc;
      }
      if (mus && mus.lrc) {
        this.lyricsLines = mus.lyrics;
        this.tmpTimestamp = mus.lrc.lyrics;
        // this.saveLrcInput();
        this.saveLrcInput(this.lrcStatusDraft, 1);
        if (!mus.lyricsText) {
          this.wrapperLyrics(mus.lyrics);
        }
      }
    },

    /** Player function */
    calculateTotalValue(length) {
      var minutes = Math.floor(length / 60),
        seconds_int = length - minutes * 60,
        seconds_str = seconds_int.toString(),
        seconds = seconds_str.split(".")[0],
        temp_min = minutes.toString().length === 1 ? "0" + minutes : minutes,
        temp_sec = seconds.toString().length === 1 ? "0" + seconds : seconds;
      return temp_min + ":" + temp_sec;
    },

    padTime(t) {
      return t < 10 ? "0" + t : t;
    },

    calculateCurrentValue(_seconds) {
      console;
      if (typeof _seconds !== "number") return "";
      if (_seconds < 0) {
        _seconds = Math.abs(_seconds);
        //console.log(_seconds);
      }
      var hours = Math.floor(_seconds / 3600),
        minutes = Math.floor((_seconds % 3600) / 60),
        seconds = Math.floor(_seconds % 60);
      var hour = hours > 0 ? this.padTime(hours) + ":" : "";
      return hour + this.padTime(minutes) + ":" + this.padTime(seconds);
    },

    calculateCurrentValue2(_seconds) {
      let milliseconds = (_seconds % 1).toFixed(2).substring(2);
      if (typeof _seconds !== "number") return "";
      if (_seconds < 0) {
        _seconds = Math.abs(_seconds);
        //console.log(_seconds);
      }
      var hours = Math.floor(_seconds / 3600),
        minutes = Math.floor((_seconds % 3600) / 60),
        seconds = Math.floor(_seconds % 60);
      var hour = hours > 0 ? this.padTime(hours) + ":" : "";
      return (
        hour +
        this.padTime(minutes) +
        ":" +
        this.padTime(seconds) +
        ":" +
        this.padTime(+milliseconds)
      );
    },

    transformTextTimeToSecond(textTime){
      const tb = textTime.split(':');
      return (+tb[0])*60+(+(tb[1]+'.'+tb[2]));
    },

    setupSeek() {
      this.seek.max = this.player.duration;
    },

    seekAudio() {
      this.isSeeking = true;
      this.player.currentTime = this.seek.value;
      this.isSeeking = false;
    },

    volumeSetup() {
      this.player.volume = this.volume.value / 100;
    },

    initProgressBar() {
      if (!this.isSeeking) {
        this.seek.value = this.player.currentTime;
      }
      var length = this.player.duration;
      var current_time = this.player.currentTime;

      // calculate total length of value
      var totalLength = this.calculateTotalValue(length);

      // calculate current value time
      var currentTime = this.calculateCurrentValue(current_time);
      if (this.player.readyState === 4) {
        $(".end-time").html(totalLength);
        $(".start-time").html(currentTime);
      }
      //checking if the current time is bigger than the previous or else there will be sync different between remaining and current
      if (currentTime > this.prevcurrentime) {
        //calculate the remaining time
        var rem_time = length - current_time;
        $(".rem-time").html(this.calculateCurrentValue(rem_time));
      }
      //setting the previouscurrent time to this current time
      this.prevcurrentime = currentTime;

      if (this.player.currentTime == this.player.duration) {
        // $("#play-btn").removeClass("pause");
        this.playIconSrc = "play_button.png";
      }
    },

    play() {
      if(this.music){
        let player = document.getElementById("player");
        if (player.paused === false) {
          this.play1 = 0;
          player.pause();
          // isPlaying = false;
          $("#play2-btn").addClass("hideItem");
          $("#play1-btn").removeClass("hideItem");
          this.playIconSrc = "play_button.png";
        } else {
          this.play1 = 1;
          $(".start-time").html("Loading...");
          player.play();
          $("#play1-btn").addClass("hideItem");
          $("#play2-btn").removeClass("hideItem");
          this.playIconSrc = "pause_button.png";
          // isPlaying = true;
        }
      }
    },

    keepTime() {
      let first = $("#lrcItem .current");
      if (first.length == 0 || this.linePosition == this.lyricsLines.length) {
        $("#lrcItem h2:nth-child(1)").addClass("current");
        this.linePosition = 0;
      }
      if(this.linePosition > 0){
        let current = $("#lrcItem .current");
        current.removeClass("current");
        current.next().addClass("current");
      }
      if (this.player.readyState === 4 && this.lyricsLines.length != this.linePosition && this.lyricsLines.length > 1) {
        let timeCurrent = this.player.currentTime;
        $("#lrcItem .current").html(
          `[${this.calculateCurrentValue2(timeCurrent)}]${this.lyricsLines[
            this.linePosition
          ]
            .split("]")
            .slice(-1)}`
        );
        this.pushLyrics(
          this.lyricsLines[this.linePosition],
          timeCurrent,
          this.linePosition
        );
        
        this.next();
      }
    },

    next2() {
      let current = $("#lrcItem .current");
      if (
        current.length == 0 ||
        this.lyricsLines.length - 1 == this.linePosition
      ) {
        $(`#lrcItem h2:nth-child(${this.lyricsLines.length})`).removeClass(
          "current"
        );
        $("#lrcItem h2:nth-child(1)").addClass("current");
        this.linePosition = 0;
        this.centerize();
        return;
      }
      current.removeClass("current");
      current.next().addClass("current");
      this.lyricsLines.length > this.linePosition
        ? this.linePosition++
        : (this.linePosition = 0);
      this.centerize();
    },

    next() {
      let current = $("#lrcItem .current");
      if (this.lyricsLines.length - 1 == this.linePosition) {
        this.linePosition++;
        this.centerize();
        return;
      }
        
      current.removeClass("current");
      $(`#lrcItem h2:nth-child(${this.linePosition+1})`).addClass("current");

      this.lyricsLines.length > this.linePosition
        ? this.linePosition++
        : (this.linePosition = 0);
      this.centerize();
    },

    previous() {
      var current = $("#lrcItem .current");
      if (current.length == 0 || this.linePosition == 0) {
        $("#lrcItem h2:nth-child(1)").removeClass("current");
        $(`#lrcItem h2:nth-child(${this.lyricsLines.length})`).addClass(
          "current"
        );
        this.linePosition = this.lyricsLines.length - 1;
        this.centerize();
        return;
      }
      var first = $("#lrcItem h2:nth-child(1)");
      current.removeClass("current");
      if (current === first) {
        return;
      }
      current.prev().addClass("current");
      this.linePosition--;
      this.centerize();
    },

    centerize() {
      if ($(".current").length == 0) return;
      var a = $(".current").height();
      var c = $("#lrcItem").height();
      var d = $(".current").offset().top - $(".current").parent().offset().top;
      var e = d + a / 2 - (c * 1) / 4;
      $("#lrcItem").animate(
        { scrollTop: e + "px" },
        { easing: "swing", duration: 500 }
      );
    },

    searchLyrics() {
      this.showSpinner = false;
      if (this.oldTitle === this.music.title && this.lyricsSearch.length > 0) {
        console.log("@@@ song title: ", this.oldTitle);
        this.showSpinner = true;
        return;
      }
      this.lyricsSelected = "";
      $("#parole").html("");
      this.lyricsSearch = [];
      service
        .fetchLyrics(this.music.title, this.user.token)
        .then((result) => {
          this.showSpinner = true;
          console.log("@@@@@ result fetch lyrics: " + result);
          if (result.data.data.data.length > 0) {
            this.isEmptySearch = false;
            this.lyricsSearch = result.data.data.data;
            console.log("@@@ lyrics search: ", this.lyricsSearch);
          } else {
            $("#parole").html(
              '<p style="color: green; text-align: center;"> No Lyrics Found ! </p>'
            );
          }
        })
        .catch(function (error) {
          this.showSpinner = true;
          console.log("@@@@@ fetch lyrics error: ", error);
          $("#parole").html(
            '<p class="error"> System Error! Please contact administrator </p>'
          );
        });
      this.oldTitle = this.music.title;
    },

    saveSelected() {
      this.lyricsInput = document.getElementById("parole").innerText;
      document.getElementById("closeBtn").click();
    },

    //TODO add existing lrc search and parole search popup
    /** End player functions */


    /*** Manage permission and role, use just in vue */
    configuration() {
      this.isPublishAccess = (CONSTANTS.LRC_SHOW_PUBLISH_BTN.includes(this.user.roleId));
      if((this.music_p && this.music_p?.userEmail == this.user.email) || CONSTANTS.SHOW_ALL_LRC.includes(this.user.roleId)){
        this.isEditLRC = true;
      }
      if(this.music_p && CONSTANTS.LRC_BLOCK_STATUS.includes(this.music_p.status) && !CONSTANTS.LRC_EDIT_ALL_LYRICS.includes(this.user.roleId)){
        this.isEditLRC = false;
      }
    },
  },
  mounted: function () {
      $('.current_tab').removeClass('current_tab');
      $(`#newLyrics`).addClass('current_tab'); 

    if (!this.isLoggedIn) router.push({ name: "login" });

    console.log("@@@ params: ", this.music_p);
    this.music = this.music_p;
    this.playList.push(this.music);
    this.init();
    this.configuration();
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.login;
    },
    showSaveLyrics: function () {
      if (this.lyricsInput) return false;
      else return true;
    },
    showSaveLrc: function () {
      if (this.lyricsLines.length > 1 && !this.isPlayLrc) return false;
      else return true;
    },
    user() {
      return this.$store.state.User;
    },
  },
  
  watch: {
    lyricsSelected: function (val) {
      this.isEmptySearch = false;
      for (let index = 0; index < this.lyricsSearch.length; index++) {
        if (this.lyricsSearch[index].tag.trim() === val) {
          $("#parole").html("");
          $("#parole").html(this.lyricsSearch[index].paroles);
          break;
        }
      }
    },    
    music: function(val){
      if(val) this.isSaveDisable = false;
    }
  },
};
</script>

<style scoped>
.editor-name {
  font-size: 1.5em;
  font-style: bolder;
  color: white;
  font-style: italic;
  margin: 20px 0 0 10px;
  /* text-align: right; */
  /* margin-right: 5px; */
}

h2::after {
  content: none;
}

.hideItem {
  display: none;
}

.displayLyricsTxt {
  transform: translateY(-40%);
}

.btnColor {
  color: #6c3483;
}

#music-player {
  background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
#repeat {
  color: gray;
  opacity: 1;
  border: 2px;
}
#top-bar {
  position: relative;
  height: 8vh;
  color: white;
  width: 90%;
  padding: 0 0 0 5%;
  z-index: 50;
}
#top-bar > * {
  display: inline-block;
}
#top-bar button {
  margin: 0;
  background: inherit;
  border: none;
  color: white;
  font-size: 100%;
  vertical-align: middle;
  transform: translateY(-40%);
  padding: 5px 10px;
}
#about-song {
  width: 60%;
  margin: 0 5%;
  line-height: 1vh;
  font-size: 70%;
}
.song-name {
  color: white;
  font-weight: bolder;
}
.artist-name {
  color: #ffffff79;
}
@media only screen and (max-width: 340px) {
  #top-bar > button {
    font-size: 15px;
  }
  #top-bar > #about-song * {
    font-size: 120%;
    line-height: 0;
  }
  .menu button {
    font-size: 5vw !important;
    padding: 4px 6px !important;
  }
  #progress-bar {
    width: 50% !important;
  }
}
.lyrics {
  width: 100%;
  height: 60vh;
  color: white;
  text-align: center;
  overflow-y: scroll;
  font-size: 2vh;
}
.lyrics-content {
  margin: 10px;
  padding-bottom: 20vh;
  transition: ease 0.1s all;
}
.lyrics h2,
h3 {
  opacity: 0.25;
}
.lyrics .current {
  opacity: 1;
  font-size: 250%;
  transform: translateY(25%);
}
.lyrics .current + h2 {
  opacity: 0.5;
  font-size: 180%;
}

.lyrics .current1 {
  opacity: 1;
  font-size: 250%;
  transform: translateY(25%);
}
.lyrics .current1 + h3 {
  opacity: 0.5;
  font-size: 180%;
}

#playerMedia {
  background: #00000045;
  position: inherit;
  bottom: 0;
  height: 25vh;
  width: 100%;
  z-index: 50;
}
#bar {
  position: relative;
  text-align: center;
  width: 100%;
  padding-top: 25px;
}
#currentTime,
#totalTime {
  transform: translateY(-50%);
  padding: 0 2%;
  font-size: 3vh;
}
@media only screen and (min-height: 500px) {
  #currentTime,
  #totalTime {
    font-size: 2.25vh !important;
  }
}
#currentTime,
#progress-bar,
#totalTime {
  color: white;
  display: inline;
}
#progress-bar {
  position: relative;
  text-align: center;
  height: 0.25em;
  width: 70%;
  border: 1px solid #222;
  background: #333;
  border-radius: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transform: translateY(-160%);
}
#progress {
  height: 100%;
  width: 0%;
  background: red;
  border-radius: 20px;
  text-align: right;
  transition: ease all;
}
#progress > i {
  position: absolute;
  transform: translate(-50%, -35%);
}
.menu {
  position: relative;
  text-align: center;
  /* width: 100%; */
  /* overflow: hidden; */
}
.menu button {
  padding: 10px 14px;
  border-radius: 50%;
  border: none;
  margin: 0 3px;
  background: inherit;
  color: white;
  font-size: 20px;
  text-align: center;
  opacity: 0.75;
  cursor: pointer;
}
.menu button > i {
  padding: 5px 3px 5px 5px;
}
.menu button#play {
  opacity: 1;
  /* border: 2px solid white; */
}
.menu button:focus {
  outline: none;
}
#show-box {
  position: absolute;
  top: 70%;
  left: 50%;
  height: 30vh;
  width: 70%;
  padding: 4vh;
  transform: translate(-50%, -70%);
  overflow: auto;
}

.menuItem {
  text-align: right;
}
/* .float-song-card > h2,
.float-song-card > h4 {
  position: relative;
  z-index: 49;
  margin: 2px 0;
} */
.lyrics::-webkit-scrollbar,
#show-box::-webkit-scrollbar {
  width: 5px;
}
.lyrics::-webkit-scrollbar-track,
#show-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.lyrics::-webkit-scrollbar-thumb,
#show-box::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 15px;
}
.floating-icon {
  position: absolute;
  bottom: 5%;
  z-index: 1000;
  font-size: 4vh;
  border: 1.5px solid white;
  border-radius: 50%;
  padding: 1vh 2vh;
  background: #222;
}
.floating-icon:nth-child(1) {
  right: 3vh;
}
.floating-icon:nth-child(2) {
  right: 12vh;
}

.seek {
  align-items: center;
  justify-content: center;
  flex: 3;
  width: 80% !important;
  margin: auto;
}

@media (max-width: 800px) {
  .seek {
    width: 65% !important;
  }
}
.vol {
  align-items: center;
  justify-content: center;
  flex: 3;
  /* width: 5%; */
  margin: 2px auto auto 0;
}

/** Text area */
textarea {
  font-size: 1.3em;
  font-family: "Poppins", sans-serif;
  font-style: bolder;
  width: 900px;
  height: 230px;
  line-height: 1.5;
  padding: 15px 10px;
  border: 1px solid gray;
  color: white;
  text-align: center;
  background: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
  transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
    transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
  margin-top: 100px;
}

@media (max-width: 916px) {
  textarea {
    width: 330px;
  }
}

textarea ::placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  color: white;
  opacity: 1;
}

textarea:focus {
  background: rgb(57, 134, 153, 0.1);
  color: white;
  font-style: italic;
  font-size: 1.5em;
  text-align: center;
  /* background: hsl(0, 7%, 20%); */
  transform: scale(1.02);
}
/** End text area */

@media only screen and (min-height: 700px) {
  .textarea {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .hideSmallMedia {
    display: none;
  }
  .lyrics {
    width: 100%;
    height: 60vh;
    color: white;
    text-align: center;
    overflow-y: scroll;
    font-size: 1vh;
  }
  .lyrics .current + h2 {
    opacity: 0.5;
    font-size: 150%;
  }
  .lyrics .current1 + h3 {
    opacity: 0.5;
    font-size: 150%;
  }
  .lyrics .current {
    opacity: 1;
    font-size: 350%;
    transform: translateY(25%);
  }

  .lyrics .current1 {
    opacity: 1;
    font-size: 350%;
    transform: translateY(25%);
  }
}
.currentItem {
  color: #4abd22;
  font-weight: bolder;
}

/* Dropdown Button */
.dropdown {
  position: relative;
  display: inline-block;
  background-color: #198754;
  border-radius: 3px;
  padding: 10px;
  color: white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  /* padding: 12px 16px; */
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
  background-color: #0d324d;
}

.dropdown-content p:hover{
  background-color: #63b175;
  cursor: pointer;
  text-decoration: none;
  color: white;
  padding:  12px 12px;
}
</style>