const axios = require("axios");
const jwt = require('jsonwebtoken');

const TEAM_ID = 'T2X65JN54Z'; 
const KEY_ID = 'B3AX535RS4'; 
//const KEY_NAME = 'afrikaLyricsKey';

const private_key = '-----BEGIN PRIVATE KEY-----'+
'MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgv8V/5Ks4LXGYq4sn'+
'jQzdwkF7gL9MDkSJig0Gp1YjaUugCgYIKoZIzj0DAQehRANCAASxWYHZSVx9kype'+
'jBxC4h9lqiYEXJEofc1Fsx8nw/h73hqUMW3ERF87iyZmdpoLnkfBx/Ori9BWABDt'+
'Ibhf8zQx'+
'-----END PRIVATE KEY-----';
const token = jwt.sign({}, private_key, {
            algorithm: 'ES256',
            expiresIn: '180d',
            issuer: TEAM_ID,
            header: {
                  alg: 'ES256',
                  kid: KEY_ID
            }
      });

function getToken(){ 
     return token;
}
async function searchSong(searchInput) {
      var result;
      var headers = {
        headers: {
            Authorization: `Bearer ${token}`
        }
      };
      await axios.get(
            `https://api.music.apple.com/v1/catalog/us/search?term=${searchInput}&limit=2&types=songs`, headers
      )
      .then((response) => {
            console.log('@@@ Search music response: ', response);
            result= response;
      })
      .catch(function(error) {
            console.log(error);
      });
      return result;
}
async function searchSongWithId(songIds) {
      var result;
      var headers = {
        headers: {
            Authorization: `Bearer ${token}`
        }
      };
      await axios.get(
            `https://api.music.apple.com/v1/catalog/us/songs?ids=${songIds}`, headers
      )
      .then((response) => {
            result= response;
      })
      .catch(function(error) {
            console.log(error);
      });
      return result;
}
async function getPlaylist(playlistId) {
      var result;
      var headers = {
        headers: {
            Authorization: `Bearer ${token}`
        }
      };
      await axios.get(
            `https://api.music.apple.com/v1/catalog/us/playlists/${playlistId}`, headers
      )
      .then((response) => {
            result= response;
      })
      .catch(function(error) {
            console.log(error);
      });
      return result;
}
export {getToken, searchSong, searchSongWithId, getPlaylist}