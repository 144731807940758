<template>
    <div id="wrapper">
        <!-- Sidebar -->
        <DashboardVerticalSide/>
        <!-- End of Sidebar -->

        <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">
            <!-- Main Content -->
            <div id="content">
                <!-- Topbar -->
                <DashboardHeader/>
                <!-- End of Topbar -->

                <!-- Begin Page Content -->
                <router-view/>
            </div>
            <!-- End of Main Content -->

            <!-- Footer -->
            <DashboardFooter/>
            <!-- End of Footer -->

        </div>
        <!-- End of Content Wrapper -->
    </div>
</template>

<script>
const $ = require("jquery");
window.$ = $;

import 'datatables.net-dt/css/jquery.dataTables.css';
const dt = require('datatables.net');
window.$.DataTable = dt;

import DashboardFooter from '../../layouts/DashboardFooter.vue'
import DashboardHeader from '../../layouts/DashboardHeader.vue'
import DashboardVerticalSide from '../../layouts/DashboardVerticalSide.vue'
export default {
    name: "Dashboard",
    components:{
        DashboardHeader,
        DashboardFooter,
        DashboardVerticalSide,
    },
  data() {
    return {}
  },

  methods: {
      pluginIntegration(url){          
        const plugin = document.createElement("script");
        plugin.setAttribute("src",url);
        plugin.async = true;
        document.head.appendChild(plugin);
      }
  },

  mounted: async function () {
    this.pluginIntegration("//cdnjs.cloudflare.com/ajax/libs/startbootstrap-sb-admin-2/4.1.3/js/sb-admin-2.min.js");
  }
}
</script>

<style scoped>
@import "../../assets/css/customCheckbox.css";
@import "../../assets/vendor/fontawesome-free/css/all.min.css";
@import "../../assets/css/sb-admin-2.min.css";
@import url("https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i");
@import url("https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.2/css/bootstrap.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");


html, body {
  height:100%;
}
</style>