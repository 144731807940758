<template>
    <div>
        <h2 class="text-center title py-2 mt-3"> Search lyrics with LRC artist name or title </h2>
        <div class="container">
            <div class="row container-fluid pt-2">
                <form v-on:submit.prevent="searchLyrics()">
                    <div class="row" id="form">
                        <div class="col-lg-2 mb-3"></div>
                        <div class="col-lg-3 mb-3">
                            <label for="searchWith" class="form-label">Search With </label>
                            <select class="form-select" required v-model="inputSearchWith">
                                <option value="artist_name" selected>Artist Name</option>
                                <option value="title">Song Title</option>
                            </select>
                        </div>

                        <div class="col-lg-3  mb-3">
                            <label for="searchKey" class="form-label">Search key</label>
                            <input id="searchKey" required v-model="inputSearchKey" type="text" class="form-control" aria-label="key">
                        </div>

                        <div class="col-lg-2 mb-3 mt-2"><br />
                            <span class="mx-3">
                                <button id="sear" type="submit" class="btn btn-success" @click="searchLyrics()">                                    
                                    Search
                                    <i class="bi bi-search ml-1"></i>
                                </button>
                            </span>
                        </div>
                        <div class="col-lg-2 mb-1"></div>
                    </div>
                </form>
            </div><br />

            <Spinner v-bind:showSpinner="showSpinner" type="xl"></Spinner>

            <h3 class="warning_msg pb-1" :hidden="this.searchResults.length > 0">{{emptyMsg}}</h3>
        </div>
        <div v-if="this.searchResults.length > 0" class="mx-4">
            <h6 class="editor-name">
                <p class="text-center">{{ this.searchResults.length }} LRC found</p>
            </h6>
            <table id="searchLyricsTable text-center" class="table table-striped">
                <thead>
                    <tr>
                        <!-- <th class="text-wrap" scope="col">Number</th> -->
                        <th>Title</th>
                        <th>Artist</th>
                        <th>Provider</th>
                        <th>User</th>
                        <th>Creation Date</th>
                        <th>Last update date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(lrc, index) in searchResults" :key="index">
                        <!-- <th scope="col">{{ index+1 }}</th> -->
                        <td>{{ lrc.title }}</td>
                        <td>{{ lrc.artist_name }}</td>
                        <!-- <td>{{ lrc.provider }}</td> -->
                         <td>
                            <a href="#" :class="`btn ${ (lrc.provider != 'Apple Music') ? 'btn-warning' : 'btn-primary'} btn-circle`">
                                <i :class="`${(lrc.provider != 'Apple Music') ? 'fas fa-laptop' : 'fab fa-apple'}`"></i>
                            </a>  
                         </td>
                        <td>{{ lrc.user.name ? lrc.user.name : lrc.user.email}}</td>
                        <td>{{ lrc.created_at.substring(0, 16).replace('T', ' ') }}</td>
                        <td>{{ lrc.updated_at.substring(0, 16).replace('T', ' ') }}</td>
                        <td>
                             <a class="btn btn-primary btn-circle" href="#" customClass='colorTooltip' data-bs-toggle="tooltip" data-bs-placement="top" title="Play in Apple Player" @click="playInApplePlayer(index)">
                                <i class="bi bi-play-circle"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table><br />
        </div>

        <div :hidden="isPlayInApple">
            <Spinner v-bind:showSpinner="isPlayInApple" type="lg"></Spinner>
        </div>
        <ChooseLocalSong v-bind:music="this.music_l" v-bind:is-show-modal="isPlayInLocal"></ChooseLocalSong>
    </div>
</template>

<script>
import Spinner from './../SpinnerAfBig.vue';
import ChooseLocalSong from '../../components/ChooseLocalSong.vue';
import Toast from './../ToastAf.vue';
import * as service from "../../utils/DC_CalloutService.js";
import router from "../../router";
// import * as dc_player from '../utils/DC_player.js';
import { Lrc } from "lrc-kit";
import { Music } from '../../utils/DC_player.js';

const $ = require("jquery");
window.$ = $;

// const CONSTANTS = new Constants();

export default {
    name: "LyricsPlayersLocalSong",

    props: {
        music_p: Object
    },

    components: {
        /* eslint-disable vue/no-unused-components */
        Spinner,
        Toast,
        ChooseLocalSong
    },

    data() {
        return {
            inputSearchWith: 'artist_name',
            inputSearchKey: '',
            showSpinner: true,
            searchResults: [],
            emptyMsg: '',
            showResult: false,
            resultSize: 0,
            currentLrc: {},
            isPlayInApple: true,
            isPlayInLocal: false,
            music_l: {}
        };
    },

    methods: {
        searchLyrics() {
            if (!this.inputSearchKey) return;
            this.searchResults = []
            this.showSpinner = false;
            this.showResult = false
            service.searchExistLyricsSync(this.inputSearchWith, this.inputSearchKey, this.user.token)
                .then((result) => {
                    if (result.data.data.data.length > 0) {
                        this.searchResults = result.data.data.data;
                        this.showResult = true;
                        this.resultSize = this.searchResults.length;
                    } else {
                        this.emptyMsg = `LRC File not Found for ${this.inputSearchKey}`
                    }
                    this.showSpinner = true;
                })
                .catch(function (error) {
                    this.showSpinner = true;
                    console.log('@@@@@ fetch lyrics error: ', error)
                });
        },

        ws_getFile(fileUrl) {
            service.getFile(fileUrl, this.user.token)
                .then((res) => {
                    return res.data;
                }).catch((err) => {
                    console.log(err)
                    return false;
                })
        },

        async getFile(index) {
            const selected = this.searchResults[index];
            // const fileContent;
            if (!selected.lyric_url && !selected.lrc_url) {
                this.showToast('Url not found for this row', 'info');
                return true;
            }

            if (selected.lyric_url) {
                const data = await service.getFile(selected.lyric_url, this.user.token);
                this.currentLrc.lyricsText = (typeof data.data == 'string') ? data.data : '';
            }

            if (selected.lrc_url) {
                const url = selected.lrc_url;
                const dataLrc = await service.getFile(url, this.user.token);
                this.currentLrc.lyrics = (typeof dataLrc.data == 'string') ? dataLrc.data : '';
            }
            return true;
        },

        currentLrcSelected(index) {
            const selected = this.searchResults[index];
            this.currentLrc.id = selected.id;
            this.currentLrc.artist = selected.artist_name;
            this.currentLrc.title = selected.title;
            this.currentLrc.externalId = selected.external_id;
            this.currentLrc.lrc_url = selected.lrc_url;
            this.currentLrc.lyric_url = selected.lyric_url;
            this.currentLrc.email = selected.user?.email;
            this.currentLrc.status = selected.status;
        },

        async playInApplePlayer(index) {
            try {
                this.currentLrcSelected(index);
                if (!this.currentLrc.externalId) {
                    this.playLocalsong();
                    return;
                }

                this.isPlayInApple = false;

                const res = await this.getFile(index);
                if (!res && !this.currentLrc.externalId) {
                    this.showToast('This lyrics does not have apple id!', 'error');
                    this.isPlayInApple = true;
                }

                let lrc, lineslrc = [];
                if (this.currentLrc.lyrics) {
                    lrc = Lrc.parse(this.currentLrc.lyrics);
                    let tab = this.currentLrc.lyrics.trim().split("\n").filter(line => line.trim());
                    for (let index = 0; index < tab.length; index++) {
                        if (tab[index].includes('[0')) {
                            lineslrc.push(tab[index]);
                        }
                    }

                    let mus = new Music(
                        this.currentLrc.id,
                        this.currentLrc.title,
                        this.currentLrc.artist,
                        this.currentLrc.album,
                        undefined,
                        "https://afrikalyrics.com/assets/images/logo-fav.png",
                        undefined,
                        lrc,
                        lineslrc,
                        this.currentLrc.lyricsText,
                        this.currentLrc.externalId,
                        this.currentLrc.email,
                        this.currentLrc.status);

                    this.isPlayInApple = true;
                    if (mus.id && mus.externalId) router.push({ name: "lyricsPlayerApple", params: { music_p: mus } });
                }
            } catch (error) {
                this.isPlayInApple = true;
                this.showToast('An error occur! we cannot play this song in Apple Player', 'error');
            }
        },

        async playLocalsong() {
            // const res = await this.getFile(index);
            this.isPlayInLocal = true;
            this.music_l = this.currentLrc;
        },

        showToast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 5000
            })
        },
    },

    mounted: function () {
        $('.current_tab').removeClass('current_tab');
        $(`#searchLyricsForm`).addClass('current_tab');

        if (!this.isLoggedIn) router.push({ name: "login" });
        // this.configuration();
    },

    computed: {
        isLoggedIn() {
            return this.$store.state.login;
        },
        user() {
            return this.$store.state.User;
        }
    },

    watch: {

    }
};
</script>

<style scoped>
.editor-name {
    font-size: 1.5em;
    font-style: bolder;
    color: black;
    font-style: italic;
    /* margin: 20px 0 45px 10px; */
    padding-top: 10px;
    font-weight: bolder;
    color: #144d07;
}

.searchContainer {
    min-height: 80vh;
    background-color: #daecda;
}

.warning_msg {
    text-align: center;
    color: rgb(185 0 0 / 64%);
    font-weight: 3px;
}

.title {
    /*background-color: #63b175;*/
    color: #198754;
}
</style>
