<template>
    <div>
        <div class="container-fluid mt-3">
            <!-- DataTales -->
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <h1 class="h4 text-gray-900 mb-4"><i class="bi bi-people-fill"></i> Afrika Lyrics Users</h1>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <div class="text-center">
                            <div class="dropdown no-arrow">
                                <a class="dropdown-toggle btn btn-primary" href="#" role="button" id="displayColumns"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="icon text-white-50">
                                        <i class="fas fa-filter"></i>
                                    </span>
                                    <span class="text pl-2">Display columns</span>                                     
                                </a>
                                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                    aria-labelledby="displayColumns">
                                    <template  v-for="(c, i) in columns">
                                        <a class="dropdown-item toggle-vis"  :id="c" :key="i" :data-column="i">
                                            {{ c }}
                                        </a>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <table class="table table-bordered text-center" id="dataTableAllUsers" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Username</th>
                                    <th>Roles</th>
                                    <th>Created_at</th>
                                    <th>Update_at</th>
                                    <th>Email_verified_at</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(u, i) in usersList" :key="i">
                                    <td>{{ u.id }}</td>
                                    <td>{{ u.name }}</td>
                                    <td>{{ u.email }}</td>
                                    <td>{{ u.username && u.username != 'null' ? u.username : '' }}</td>
                                    <td>{{ u.roles.map(r=>r.name).join(', ') || '' }}</td>
                                    <td>{{ u.created_at ? u.created_at.substring(0, 10) : '' }}</td>
                                    <td>{{ u.updated_at ? u.updated_at.substring(0, 10) : ''}}</td>
                                    <td>{{ u.email_verified_at ? u.email_verified_at.substring(0, 10) : ''}}</td>
                                    <td>
                                        <router-link :to="{name: 'AllLyricsSync', params: {userId: u.id}}" class="btn btn-success">
                                             <span class="icon text-white-50">
                                                <i class="fas fa-music"></i>
                                            </span>
                                            <span class="text pl-2">Lrc list</span>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="card-body p-0 container">
            <div class="p-5">
                <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4"><i class="bi bi-people-fill"></i> Afrika Lyrics Users</h1>
                </div>
                <div>
                    <div class="table-responsive">
                        <div class="text-center">
                            <div class="dropdown no-arrow">
                                <a class="dropdown-toggle btn btn-primary" href="#" role="button" id="displayColumns"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="icon text-white-50">
                                        <i class="fas fa-filter"></i>
                                    </span>
                                    <span class="text pl-2">Display columns</span>                                     
                                </a>
                                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                    aria-labelledby="displayColumns">
                                    <template  v-for="(c, index) in columns">
                                        <a class="dropdown-item toggle-vis" :key="index" :data-column="index">
                                            {{ c }}
                                        </a>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <table class="table table-bordered text-center" id="dataTableAllUsers" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Username</th>
                                    <th>Username</th>
                                    <th>Role</th>
                                    <th>Last Update</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(u, i) in usersList" :key="u.id">
                                    <td>{{ i.id }}</td>
                                    <td>{{ u.name }}</td>
                                    <td>{{ u.email }}</td>
                                    <td>{{ u.username || '' }}</td>
                                    <td>{{ u.role || '' }}</td>
                                    <td>{{ u.createdBy || '' }}</td>
                                    <td>{{ u.lastupdate || ''}}</td>
                                    <td>
                                        <router-link :to="{name: 'AllLyricsSync', params: {userId: u.id}}" class="btn btn-success">
                                             <span class="icon text-white-50">
                                                <i class="fas fa-music"></i>
                                            </span>
                                            <span class="text pl-2">Lrc list</span>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" v-for="(u, index) in usersList" :key="index">
                        <hr class="mt-3"/>
                        <div class="col-11">
                             <div class="d-flex align-items-center">
                                <div class="dropdown-list-image mr-3">
                                    <img class="rounded-circle" style="width: 50px; height: 50px" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                        alt="...">
                                    <div class="status-indicator bg-success"></div>
                                </div>
                                <div>
                                    <div class="text-truncate user-list-name">{{u.name ? u.name +', ' : ''}} {{ u.email }}</div>
                                    <div class="small user-list-role">{{ u.roles.map(elt => elt.name ).join(' - ') }}</div>
                                    <div class="small text-gray-500 user-list-perm">{{ u.permissions.map(elt => elt.name ).join(' - ') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1">
                            <div class="dropdown no-arrow">
                                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                    aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item" href="#">Edit</a>
                                    <a class="dropdown-item" href="#">Delete</a>
                                    <router-link :to="{name: 'AllLyricsSync', params: {userId: u.id}}" class="dropdown-item">Show lrc sync</router-link>
                                </div>
                            </div>
                        </div>                        
                        <hr class="mt-3" v-if="usersList.length == index+1"/>
                    </div> 
                </div>
            </div>
            <Spinner v-bind:showSpinner="userLoader" type="lg"></Spinner>
        </div> -->
        <Spinner v-bind:showSpinner="userLoader" type="lg"></Spinner>
    </div>
</template>

<script>
    import Spinner from '../SpinnerAfBig.vue';
    import * as service from "../../utils/DC_CalloutService.js";
    import router from "../../router";

    const $ = require("jquery");
    window.$ = $;

export default { 
    data() {
        return {
            columns: ['Id', 'Name', 'Email', 'Username', 'Roles', 'Created_at', 'Update_at', 'Email_verified_at', 'Action'],
            columnsToHide: ['Created_at', 'Update_at', 'Email_verified_at'],
            usersList: [],
            userSelected: {},
            isShowRegisteredUser: true,
            isShowUserList: false,
            searchKey: '',
            name: '',
            username: '',
            email: '',
            password: '',
            currentUserIndex: 0,
            isDisableEditUser: true,
            isHiddenSave: true,
            isShowAllLrc: false,
            allLrcArray: [],
            footerStyle: 'dark',
            isShowUserLrc: false,
            isLoadUsers: false,
            isSearchUsers: false,
            showUserPassword: false,
            u_name: '',
            u_email: '',
            u_username: '',
            u_updated_at: '',
            u_userRoles: '',
            u_userPermissions: '',
            usersRolesPermissions: [],
            usersRoles: [],
            usersPermissions: [],
            roleSelected: '',
            permissionsSelected: [],
            showPermissions: false,
            userLoader: false,
        };
    },
    components: {
        Spinner,
    },

    methods: {
        showCallapse() {
            setTimeout(() => {
                if (this.showColp) {
                    this.showColp = false;
                    return;
                }
                this.showColp = true;
                document
                    .getElementById("navbarAdminContent")
                    .classList.remove("show");
            }, 500);
        },

        settingsPage() {
            router.push({ name: "settingsPage" });
        },

        viewUserDetail(index) {
            this.backToUserInfoPage();
            $(`#${this.currentUserIndex}`).attr('hidden', true);
            $(`#${this.currentUserIndex}`).parent().removeClass('currentUserDetail');
            $(`#${index}`).removeAttr('hidden');
            $(`#${index}`).parent().addClass('currentUserDetail');
            this.currentUserIndex = index;
            this.userSelected = this.usersList[index];
            this.userSelected.userRoles = this.usersList[index].roles.map(elt => elt.name ).join(' - ');
            this.userSelected.userPermissions = this.usersList[index].permissions.map(elt => elt.name ).join(' - ');
            
            this.u_name = this.userSelected.name
            this.u_email = this.userSelected.email
            this.u_username = this.userSelected.username
            this.u_updated_at = this.userSelected.updated_at
            this.u_userRoles = this.userSelected.userRoles
            this.u_userPermissions = this.userSelected.userPermissions
            $('.userDetailInput').removeClass('u_editForm');
            this.isHiddenSave = true;
            this.showPermissions = false;
        },

        editUserInfo(){
            $('.userDetailInput').addClass('u_editForm');
            this.isDisableEditUser = false;
            this.showPermissions = true;
            this.isHiddenSave = false;

            setTimeout(() => {
                const userPermsTmp = this.usersList[this.currentUserIndex].permissions.map(elt => elt.name );
                for (let index = 0; index < userPermsTmp.length; index++) {
                    const element = userPermsTmp[index];
                    if(this.usersPermissions.includes(element)) $(`#${element.replaceAll(' ','_')}`).click();                
                }                
            }, 1000);
        },

        cancelEditInfo(){
            $('.userDetailInput').removeClass('u_editForm');
            this.viewUserDetail(this.currentUserIndex);
            this.isHiddenSave = true;            
        },

        async saveUserInfo(){
            if(this.permissionsSelected.length != 0){
                await service.updateUserRolesPermissions(this.userSelected.id, [this.u_userRoles], this.permissionsSelected, this.user.token)
                .then((result) => {
                    this.showToast(result.data.message, result.status == 200 ? 'success' : 'error');
                    if(result.status == 200){
                        this.u_userPermissions = this.permissionsSelected.join(' - ');
                        this.usersList[this.currentUserIndex].permissions = result.data.data.permissions;
                        this.usersList[this.currentUserIndex].roles = result.data.data.roles;
                    }
                })
                .catch((error) => {
                    this.showToast(error, 'error');
                })
            }else{
                this.showToast('Please Permissions cannot be empty!', 'warning');
            }
        },

        async userList(index = null) {
            this.isShowRegisteredUser = false;
            this.isShowAllLrc = false;            
            this.isShowUserList = true;
            $('a#currentSettingTab').removeAttr('id');
            $('.userList').attr("id", "currentSettingTab");
            this.isLoadUsers = true;
            if(!index) await this.getUsers();
            await this.getUsersRolesPermissions();

            setTimeout(() => {
                this.viewUserDetail(index ? index : 0); 
                this.isLoadUsers = false;
            }, 1000);
        },

        addUser() {
            console.log(" kjdhfklshkjg");
            this.isShowUserList = false;
            this.isShowAllLrc = false;
            this.isShowRegisteredUser = true;
            $('a#currentSettingTab').removeAttr('id');
            $('.addUser').attr("id", "currentSettingTab");
        },
        
        allFileLrcSync(){
            this.isShowRegisteredUser = false;
            this.isShowUserList = false;
            this.isShowAllLrc = true;
            $('a#currentSettingTab').removeAttr('id');
            $('.allFileLrcSync').attr("id", "currentSettingTab");
        },

        lockOut(){
            router.push({ name: "lyricsPlayerLocalForm" });
        },

        userLrcList(){
            this.isShowUserLrc = true;
        },

        backToUserInfoPage(){
             this.isShowUserLrc = false;
        },

        async getUsers(){
             await service.getUsersList(this.user.token)
                .then((res) => {
                    if (res.data.data.length) {
                        this.usersList = res.data.data;
                    }else{
                        this.showToast('Not user found', 'warning');
                    }
                    this.userLoader = true;
                }).catch(() => {this.showToast('Get users error', 'error'); this.userLoader = true;});
        },

        async getUsersRolesPermissions(){
             await service.getRolesPermissions(this.user.token)
                .then((res) => {
                    if (res.data?.roles) {
                        this.usersRolesPermissions = res.data;                        
                        this.usersRoles = this.usersRolesPermissions.roles.map( elt => elt.name );
                        this.usersPermissions = this.usersRolesPermissions.permissions.map( elt => elt.name );
                    }
                }).catch((error) => {this.showToast(error, 'error');});
        },

        async updateRolesPermissions(userId, roles, permissions){
            await service.updateUserRolesPermissions(userId, roles, permissions, this.user.token)
                .then((res) => {
                    if (res.data.data.length) {
                        this.showToast(res.message, 'success');
                    }
                }).catch((error) => {this.showToast(error, 'error')});
        },

        showToast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 5000
            })
        },

        async registerUser(){
            await service.createUser(
                {
                    "name": this.name,
                    "username": this.username,
                    "email": this.email,
                    "password": this.password,
                }, this.user.token)
                .then((result)=>{                    
                    this.showToast(result.data.message, (result.status == 200) ? 'success' : 'error');
                    if(result.status == 200){
                        this.showUserPassword = true;
                    }
                }).catch((error)=>{
                    console.log('@@@@@ error on registered user: ', error);
                    // this.showToast(error, 'error');
                }).finally(()=>{

                })
        },

        async userSearch(){
            this.isSearchUsers = true;
            await this.getUsers();
            let obj = this.usersList.find((o, i) => {
                if(o.name?.includes(this.searchKey) || o.username?.includes(this.searchKey) || o.email?.includes(this.searchKey)){
                    this.currentUserIndex = i;
                    return true;
                }
            })

            if(obj){
                this.showToast('User found!', 'success');
                this.userList(this.currentUserIndex);
            }else{
                this.showToast('user not found', 'error');
            }

            this.isSearchUsers = false;
        },
    },

    mounted: async function(){
        let hideCols = this.columnsToHide;
        await this.getUsers();
        console.log(">>>>>>>>>>>>>>>> users list: ", this.usersList);

        $(document).ready(function() {
            let table = $('#dataTableAllUsers').DataTable();
            $('a.toggle-vis').on( 'click', function (e) {
                e.preventDefault();
                let column = table.column( $(this).attr('data-column') );
                column.visible() ? $(`#${$(this).attr('id')}`).addClass('text-danger') : $(`#${$(this).attr('id')}`).removeClass('text-danger');
                column.visible( ! column.visible() );
            } );

            hideCols.forEach(c => {
                let column = table.column( $(`#${c}`).attr('data-column'));
                $(`#${c}`).addClass('text-danger');
                column.visible( false );
            });
        });
    },

    computed: {
        isLoggedIn() {
            return this.$store.state.login;
        },

        user() {
            return this.$store.state.User;
        },
    },
}
</script>

<style scoped>
.user-list-name{
    color: black;
}
.user-list-role{
    color: #0d6efd;
    font-weight: bold;
}
.user-list-perm{
    color: black;
}
a{
    cursor: pointer;
}
</style>