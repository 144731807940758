import lrcParser from 'lrc-parser'
import Sub from '../Sub';
import SubStyle from '../SubStyle';


const fixTime = (seconds)=>{
  return `${new Date(seconds * 1000).toISOString().slice(11, 19)}.00`;
}

export default function  lrc2Sub (lrcString){
    const data = lrcParser(lrcString)
    console.log(data.scripts);
  return   data.scripts.map((item,index) => new Sub({
    start:fixTime(item.start),
    end:fixTime(item.end),
    text:item.text,
    style: new SubStyle({Name:`P${index}`})
  }))
}