export default [{
        id: "1",
        icon: "fas fa-fw fa-plus-circle",
        title: "Add Request",
        to: "/addLyricRequestForm",
        session: "lyrics",
    },
    {
        id: "2",
        icon: "fas fa-fw fa-table",
        title: "All Request",
        to: "/AllLyricsRequest",
        session: "lyrics",
    },
    {
        id: "3",
        icon: "fas fa-fw fa-tasks",
        title: "Lyrics to create",
        to: "/UserLyricsToCreate",
        session: "lrcActions",
    },
    {
        id: "4",
        icon: "fas fa-fw fa-user-check",
        title: "Lyrics review",
        to: "/UserLyricsReviews",
        session: "lrcActions",
    },
    {
        id: "5",
        icon: "fas fa-fw fa-check-double",
        title: "Lyrics completed",
        to: "/UserCompletedLyrics",
        session: "lrcActions",
    },
    {
        id: "6",
        icon: "fas fa-fw fa-clipboard-check",
        title: "All LRC Sync",
        to: "/AllLyricsSync",
        session: "lrcActions",
        restricted: true,
    },
    {
        id: "7",
        icon: "fas fa-fw fa-chart-pie",
        title: "Add User",
        to: "/AddNewUser",
        session: "admins",
        subSession: 'users'
    },
    {
        id: "8",
        icon: "fas fa-fw fa-chart-pie",
        title: "All Users",
        to: "/AllUsers",
        session: "admins",
        subSession: 'users'
    },
    // {
    //     id: "9",
    //     icon: "fas fa-fw fa-chart-pie",
    //     title: "Users Tracking",
    //     to: "/UsersTracking",
    //     session: "admins",
    // },
];