<template>
  
        <div 
            v-bind:class="[`sub-item`, highlight ? `sub-highlight` : ``, $store.getters['editor/checkSub'](sub) ? `sub-illegal` : '',]"
            :style="style" 
            @doubleclick = "(event)=>onDoubleClick(sub,event)"
            @click = "(event)=>onClick(sub)"
            >
            <div>
                <div
                class="sub-handle"
                :style="{
                        left: 0,
                    width: 10+'px',
                }"
                 @mousedown="(event)=>onMouseDown(event,'left') "
                    ></div>

            <div
                class="sub-text"
                :title="sub.text"
                @mousedown="(event)=>onMouseDown(event) "
                v-html="splittedText"
                
            >
          
            
            </div>
            <div
                class="sub-handle"
                :style="{
                        right: 0,
                    width: 10+'px',
                }"
                 @mousedown="(event)=>onMouseDown(event,'right') "
                
            ></div>
            <div class="sub-duration">{{sub.duration}}</div>
            </div>
           
        </div>
       
  
 
        
</template>

<script>
import DT from 'duration-time-conversion';


export default {
    components:{
     
    },
    props:[
        "currentIndex",
        "left",
        "width",
        "sub",
        "highlight"
    ],
    computed:{
        style(){
            return { left: this.left+'px',  width: this.width+'px'}
        },
        subtitle() {
            return this.$store.state.editor.subs;
        },
        player() {
            return this.$store.state.editor.player;
        },
        splittedText(){
          
            return `${this.sub.text}`.split(/\r?\n/).map((line, index) => (
                                            `<p key=${index}>${line}</p>`
                                        )).join('')
        }
    },
    methods:{
        handleSelect(event){
            console.log(event);
        },
        onMouseDown(event,side){
            this.$emit("onMouseDown", this.sub, event, side)
        },
        onClick(sub){
            if (this.player.duration >= sub.startTime) {
                this.$store.commit("editor/setCurrentTime", sub.startTime + 0.001)
            }
        },

        onDoubleClick  (sub, event)  {
            console.log(event);
            const $subs = event.currentTarget;
            const index = this.$store.getters['editor/hasSub'](sub);
            const previou = this.subtitle[index - 1];
            const next = this.subtitle[index + 1];
            if (previou && next) {
                const width = (next.startTime - previou.endTime) * 10 * this.gridGap;
                $subs.style.width = `${width}px`;
                const start = DT.d2t(previou.endTime);
                const end = DT.d2t(next.startTime);

                this.$store.commit("editor/updateSub",{
                    sub,obj:{
                    start,
                    end,
                }
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
     .sub-item {
            position: absolute;
            top: 30%;
            left: 0;
            height: 40%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #fff;
            font-size: 14px;
            cursor: move;
            user-select: none;
            pointer-events: all;
            background-color: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.2);

            &:hover {
                background-color: rgba(255, 255, 255, 0.3);
            }

            &.sub-highlight {
                background-color: rgba(33, 150, 243, 0.5);
                border: 1px solid rgba(33, 150, 243, 0.5);
            }

            &.sub-illegal {
                background-color: rgba(199, 81, 35, 0.5);
            }

            .sub-handle {
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: 1;
                height: 100%;
                cursor: col-resize;
                user-select: none;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }

            .sub-text {
                position: relative;
                z-index: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                word-break: break-all;
                white-space: nowrap;
                text-shadow: rgb(0 0 0) 1px 0px 1px, rgb(0 0 0) 0px 1px 1px, rgb(0 0 0) -1px 0px 1px,
                    rgb(0 0 0) 0px -1px 1px;
                width: 100%;
                height: 100%;

                p {
                    margin: 2px 0;
                    line-height: 1;

                    &.bilingual {
                        transform: scale(0.8);
                    }
                }
            }

            .sub-duration {
                opacity: 0.5;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                text-align: center;
                font-size: 12px;
            }
        }
</style>