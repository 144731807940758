<template>
<div>
    <h1>Bootstrap 4 Toast Example</h1>
    <button id="showToast" class="btn btn-primary btn-lg w-25 mx-auto" @click="testToast()">Show Toast</button>
    <!-- from https://getbootstrap.com/docs/4.3/components/toasts/ -->
    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000" style="position: absolute; top: 1rem; right: 1rem;">
        <div class="toast-header">

            <strong class="mr-auto">Bootstrap
                <font-awesome-icon icon="user-secret" /> </strong>
            <font-awesome-icon icon="user-secret" />

            <!-- It's better to be explicit -->
            <!-- Don't forget to bind the property with ":" (we forget all the time!) -->
            <font-awesome-icon :icon="['fas', 'user-secret']" />
            <small>11 mins ago</small>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="toast-body">
            Hello, world! This is a toast message.
        </div>
    </div>
    <!-- <div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center w-100">
        <div class="toast align-items-center toastBody" role="alert" aria-live="assertive" aria-atomic="true" :hidden="isActive">
            <div class="d-flex">
                <div class="toast-body">
                    {{message}}
                </div>
                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    </div> -->
</div>
</template>

<script>
const $ = require("jquery");
window.$ = $;

export default {
    name: "SuccessToast",
    props: {
        isActive: Boolean,
        type: String,
        message: String
    },

    data() {
        return {

        };
    },

    methods: {
        showToast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: 'primary',
                position: 'top-center',
                duration: 5000
            })
        }
    },

    mounted() {
        console.log('@@@@@ toastt......')
        // $('.toast').toast('show');
    },

    watch: {
        isActive: function (val) {
            if (val) {
                $('.toast').toast('show');
            }
        },

        type: function (val) {
            switch (val) {
                case 'error':
                    $('.toastBody').addClass('errorToast');
                    break;

                case 'warning':
                    $('.toastBody').addClass('warningToast');
                    break;

                case 'success':
                    $('.toastBody').addClass('successToast');
                    break;
            }
        }
    }
};
</script>

<style scoped>
.toastBody {
    color: white;
}

.errorToast {
    background-color: red;
}

.warningToast {
    background-color: #FF9900;
}

.successToast {
    background-color: green;
}
</style>
