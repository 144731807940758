<template>
    <div class="card o-hidden border-0 shadow-lg">
        <div class="card-body p-0 container">
            <div class="p-5">
                <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4"><i class="bi bi-music-note-beamed"></i> Sync a New Song from Local  Storage</h1>
                </div>
                
                <form class="user">
                    <div class="row" id="form">
                        <div class="col-lg-6 mb-3">
                            <label for="Song_title" class="form-label">Song Title</label>
                            <input id="Song_title" required v-model="inputTitle" type="text" class="form-control" placeholder="Song title" aria-label="Song title">
                        </div>
                        <div class="col-lg-6  mb-3">
                            <label for="Artist_name" class="form-label">Artist Name</label>
                            <input id="Artist_name" required v-model="inputArtist" type="text" class="form-control" placeholder="Artist name" aria-label="Artist name">
                        </div>
                        <div class="col-lg-6 mb-3">
                            <label for="Album_name" class="form-label">Album Title</label>
                            <input id="Album_name" v-model="inputAlbum" type="text" class="form-control" placeholder="Album" aria-label="album" >
                        </div>

                        <div class="col-lg-6  mb-3 mt-2">
                        <span class="float-start"><br/>
                            <input id="file" type="file" accept=".mp3, .m4a, .aac, .wav" @change="loadAudioFile()" />
                            <label for="file">Select a music file</label> <span class="" id=""> {{ inputFile }} </span>
                        </span>
                        </div>

                        <div class="col-lg-6  mb-3">
                            <label for="lyricsText" class="form-label">
                                Lyrics text
                                <button class="btn btn-secondary btn-sm mx-4" data-bs-toggle="modal" data-bs-target="#searchLyricsModal" @click="searchLyrics()">
                                Search Lyrics
                                </button>
                            </label>
                            <textarea id="lyricsText" placeholder="Paste Your Lyrics Text Here" v-model="lyricsInput"></textarea>
                        </div>
                        <div class="col-lg-6 mb-3" style="margin-top: 5px;">
                            <label for="LRCText" class="form-label">LRC text</label>
                            <textarea id="LRCText" placeholder="Paste Your LRC Text Here" v-model="lrcInput"></textarea>
                        </div>

                        <div class="col-lg-6  mb-3"></div>
                        <div class="col-lg-6 text-right  mb-3">
                            <span class="mx-3">
                                <button type="reset" class="btn btn-secondary" @click="reset()">Reset</button>
                            </span>
                            <span class="mx-3">
                                <button type="submit" class="btn btn-success" @click="createNewLyrics()">Sync Lyrics with Music</button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal fade" id="searchLyricsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Search lyrics text for: <strong><b>{{ inputTitle }} </b></strong></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBtn"></button>
              </div>
              <div class="modal-body">
                <div class="spinner-grow text-success text-center" style="width: 3rem; height: 3rem;" role="status" :hidden="showSpinner">
                  <span class="sr-only"></span>
                </div>
                <div :hidden="!showSpinner">
                  <select class="form-select" aria-label="Default select example" v-model="lyricsSelected">
                    <option disabled value="">Choose lyrics text</option>
                    <option v-for="(lyric, index) in lyricsSearch" :key="index">{{ lyric.tag }}</option>
                  </select>
                  <div id="parole" class="mt-5"> </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success" @click="saveSelected()" :disabled="isEmptySearch">Save selected</button>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import Spinner from '../SpinnerAfBig.vue';
import Toast from '../ToastAf.vue';
import { Lrc } from "lrc-kit";
// import { Runner } from "lrc-kit";
import { Music } from '../../utils/DC_player.js';
import * as service from "../../utils/DC_CalloutService.js";
import router from "../../router";

const $ = require("jquery");
window.$ = $;

export default {
  name: "LyricsPlayersLocalSong",
  props: {
    music_p: Object
  },

  components: {
    /* eslint-disable vue/no-unused-components */
    Spinner,
    Toast
  },

  data() {
    return {
      inputTitle: '',
      inputTitleError: '',
      inputArtist: '',
      inputArtistError: '',
      inputAlbum: '',
      inputFile: '',
      lyricsInput: '',
      lrcInput: '',
      file: '',
      lyricsSearch: [],
      showSpinner: true,
      lyricsSelected: '',
      isEmptySearch: true,
      oldTitle: '',
      lyricsCallout: {},
      toastMessage: 'test test',
      toastType: 'error',
      isShowToast: false
    };
  },

  methods: {
    createNewLyrics(){
      if(!this.file || !this.inputTitle || !this.inputArtist){
        $("#fil").addClass("error");
        $("#ti").addClass("error");
        $("#ar").addClass("error");
        this.inputFile = 'Please select file';
        this.inputTitleError = 'Please enter song title'
        this.inputArtistError = 'Please enter artist name'
        return;
      }
      this.inputTitleError = ''
      this.inputArtistError = ''
      let lrc, lineslrc = [];
      if(this.lrcInput){
        lrc = Lrc.parse(this.lrcInput);
        let tab = this.lrcInput.trim().split("\n").filter(line => line.trim());
        for (let index = 0; index < tab.length; index++) {
          if(tab[index].includes('[0')){
            lineslrc.push(tab[index]);
          }
        }
      }else{
        lrc = undefined;
        lineslrc = undefined;
      }

      const lines = this.lyricsInput ? this.lyricsInput.trim().replace(/\[[^\]]*\]/g, '').split("\n").filter(line => line.trim()) : undefined;
      let mus = new Music(
                      this.inputTitle+'__'+this.inputArtist,
                      this.inputTitle,
                      this.inputArtist,
                      this.inputAlbum,
                      undefined,
                      "https://afrikalyrics.com/assets/images/logo-fav.png",
                      URL.createObjectURL(this.file),
                      lrc,
                      lineslrc,
                      lines ? this.lyricsInput : '');
      console.log('@@ music file: ', mus);
      router.push({ name: "PlayerForLocalSong", params: { music_p: mus } });
    },

    reset(){
      this.inputTitle = '';
      this.inputArtist = '';
      this.inputAlbum = '';
      this.inputFile = '';
      this.lyricsInput = '';
      this.lrcInput = '';
      this.file = '';
    },

    loadAudioFile() {
      this.file = document.getElementById("file").files[0];
      this.inputFile = this.file.name;
      $("#fil").removeClass("error");
    },

    searchLyrics(){
      this.showSpinner = false;
      if(this.oldTitle === this.inputTitle && this.lyricsSearch.length > 0){
        console.log('@@@ song title: ', this.oldTitle);
        this.showSpinner = true;
        return;
      }
      this.lyricsSelected = '';
      $("#parole").html('');
      this.lyricsSearch = [];
      service.fetchLyrics(this.inputTitle, this.user.token)
        .then((result) => {
            this.showSpinner = true;
            console.log("@@@@@ result fetch lyrics: " + result);
            if (result.data.data.data.length > 0) {
              this.isEmptySearch = false;
              this.lyricsSearch = result.data.data.data;
              console.log('@@@ lyrics search: ',  this.lyricsSearch);
            } else {
              $("#parole").html('<p style="color: green; text-align: center;"> No Lyrics Found ! </p>');
            }
        })
        .catch(function (error) {
          this.showSpinner = true;
          console.log('@@@@@ fetch lyrics error: ', error)
          $("#parole").html('<p class="error"> System Error! Please contact administrator </p>');
        });
        this.oldTitle = this.inputTitle;
    },

    saveSelected(){
      this.lyricsInput = document.getElementById("parole").innerText;
      document.getElementById("closeBtn").click();
    },

    validation(){
      let forms = document.querySelectorAll('.needs-validation')
        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
          .forEach(function (form) {
            form.addEventListener('submit', function (event) {
              if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
              }
              form.classList.add('was-validated')
            }, false)
          })
    },

    getImgUrl(pet) {
      let images = require.context("../../assets/artistsImg/", false, /\.*$/);
      return images("./" + pet);
    },
  },

  mounted: function() {
      $('.current_tab').removeClass('current_tab');
      $(`#newLyrics`).addClass('current_tab');
      
    if (!this.isLoggedIn) router.push({ name: "login" });
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.login;
    },
    user(){
      return this.$store.state.User;
    }
  },
  
  watch: {
    lyricsSelected: function (val) {
      this.isEmptySearch = false;
      for (let index = 0; index < this.lyricsSearch.length; index++) {
        if((this.lyricsSearch[index].tag).trim() === val){
          $("#parole").html('');
          $("#parole").html(this.lyricsSearch[index].paroles);
          this.lyricsCallout = this.lyricsSearch[index];
          break
        }
      }
    }
  }
};
</script>

<style scoped>

/** Text area */
textarea {
  font-size: 1em;
  font-family: "Poppins", sans-serif;
  font-style: bolder;
  width: 100%; 
  height: 180px;
  line-height: 1.5;
  padding: 15px 10px;
  border: 0.2px solid #ced4da;
  color: black;
  text-align: center;
  background: linear-gradient(315deg, #f6f6f6 0%, #ffffff 74%);
  transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
    transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

/* @media (max-width: 916px) {
  textarea {
    width: 330px;
  }
} */

textarea ::placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  color: gray;
  opacity: 1;
}

textarea:focus {
  background: white;
  color: black;
  font-style: italic;
  font-size: 1em;
  text-align: center;
  /* background: hsl(0, 7%, 20%); */
  transform: scale(1);
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  background: #11a568 50%;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Poppins", sans-serif;/*: "Rubik", sans-serif;*/
  font-size: inherit;
  font-weight: 400;
  margin: 0px 0px 5px 0px;
  outline: none;
  padding: 7px 16px 7px 16px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}

@media (max-width: 1000px) {
 .hideSmallMedia {
   display: none;
 }
}

.error{
  color: red;
}
.hideModal{
  display: none;
}
</style>