import Vue from 'vue'
import App from './App.vue'
import router from './router';
import './assets/css/DC_AfricaLyrics.css';
import store from './store';
import Toasted from 'vue-toasted';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import "./assets/scss/sb-admin-2.scss";

library.add(faUserSecret)

Vue.config.productionTip = false
Vue.use(Toasted)

Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')