<template>
    <div class="grab" :class="grabbing?'grabbing':''" @mouseout="onGrabUp" @mouseup="onGrabUp" @mousedown="onGrabDown" @mousemove="onGrabMove"></div>
</template>

<script>
import clamp from 'lodash/clamp';
    export default {
        data(){
            return {
                grabStartX:0,
                grabStartTime:0
            }
        },
        computed:{
            grabbing(){
                return this.$store.state.editor.grabbing
            },
            player(){
                return this.$store.state.editor.player
            },
            waveform(){
                return this.$store.state.editor.waveform
            },
        },
        methods:{
            onGrabDown (event) {
               
                if (event.button !== 0) return;
                this.grabStartX =event.pageX;
                this.grabStartTime =this.player.currentTime;
                this.$store.commit("editor/setGrabbing",true)
               
            },
            onGrabUp (){
               
                this.grabStartX =0;
                this.grabStartTime =0;
                this.$store.commit("editor/setGrabbing",false)
               
            },
            onGrabMove(event) {
                if (this.grabbing && this.player && this.waveform) {
                   const currentTime = clamp(
                        this.grabStartTime - ((event.pageX - this.grabStartX) / this.$store.state.editor.availableClientWidth ) * 10,
                        0,
                        this.player.duration,
                    );
                  
                    this.$store.commit("editor/setCurrentTime",currentTime)
                }
            },
            mounted(){
                document.addEventListener('mouseup',this.onGrabUp);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .grab {
        position: absolute;
        top: 0;
        z-index: 11;
        cursor: grab;
        height: 20%;
        width: 100%;
        user-select: none;
        background-color: rgb(33 150 243 / 20%);
        border-top: 1px solid rgb(33 150 243 / 30%);
        border-bottom: 1px solid rgb(33 150 243 / 30%);

        &.grabbing {
            cursor: grabbing;
        }
    }
    </style>
