<template>
<div>
    <div class="modal fade" id="playInLocalPlayer" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xs modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title headerEdit" id="staticBackdropLabel">
                        This lyrics does not have apple id, play with local song
                    </h6>
                    <!-- <h6 class="modal-title">Play <strong> {{music.artist}}, {{music.title}} </strong> in local player</h6> -->
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeChooseBtn" v-on:click="closeModal">
                    </button>
                </div>
                <div class="modal-body modalBodyChooseSong">
                    <div class="input-group">
                        <input type="file" class="form-control" id="file" aria-describedby="inputGroupFileAddon04" aria-label="Upload" accept=".mp3, .m4a, .aac, .wav" @change="loadAudioFile()">                        
                    </div><br/>
                    <Spinner v-bind:showSpinner="showSpinner" type="xl"></Spinner>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-on:click="closeModal">Cancel</button>
                    <button type="button" class="btn btn-success" @click="playInLocalPlayer()" :disabled="isPlay">Play in local player</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Spinner from "../components/SpinnerAfBig.vue"
import router from "../router";
import * as service from "./../utils/DC_CalloutService.js";
import { Lrc } from "lrc-kit";
import { Music } from '../utils/DC_player.js';

const $ = require("jquery");
window.$ = $;

export default {
    name: "ChooseLocalSong",

    data() {
        return {
            file: {},
            showSpinner: true,
            isPlay: true
        };
    },

    props: {
        music: Object,
        isShowModal: Boolean
    },

    components: {
        Spinner
    },

    methods: {
        async getFile() {
            if (this.music.lyric_url) {
                const data = await service.getFile(this.music.lyric_url, this.user.token);
                this.music.lyricsText = (typeof data.data == 'string') ? data.data : '';
            }

            if (this.music.lrc_url) {
                const dataLrc = await service.getFile(this.music.lrc_url, this.user.token);
                this.music.lyrics = (typeof dataLrc.data == 'string') ? dataLrc.data : '';
            }
            return true;
        },

        async playInLocalPlayer() {
            this.showSpinner = false;
            await this.getFile();

            let lrc, lineslrc = [];
            if(this.music.lyrics){
                lrc = Lrc.parse(this.music.lyrics);
                let tab = this.music.lyrics.trim().split("\n").filter(line => line.trim());
                for (let index = 0; index < tab.length; index++) {
                    if(tab[index].includes('[0')){
                        lineslrc.push(tab[index]);
                    }
                }
            }else{
                lrc = undefined;
                lineslrc = undefined;
            }
            
            this.showSpinner = true;
            let mus = new Music(
                            this.music.id,
                            this.music.title,
                            this.music.artist,
                            this.music.album,
                            undefined,
                            "https://afrikalyrics.com/assets/images/logo-fav.png",
                            URL.createObjectURL(this.file),
                            lrc,
                            lineslrc,
                            this.music.lyricsText ? this.music.lyricsText : '', 
                            this.music.externalId,
                            this.music.email,
                            this.music.status);
            this.closeModalBtn();
            router.push({ name: "lyricsPlayerLocal", params: { music_p: mus } });
        },

        loadAudioFile() {
            this.file = document.getElementById("file").files[0];
            this.isPlay = false;
        },

        closeModal() {
            this.$parent.isPlayInLocal = false;
        },

        showToast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 5000
            })
        },

        createBtn() {
            let btn = document.createElement('button');
            btn.setAttribute('data-bs-toggle', "modal");
            btn.setAttribute('data-bs-target', "#playInLocalPlayer");
            btn.style.display = "none";
            document.body.appendChild(btn);
            btn.click();
        },

        closeModalBtn() {
            let btn = document.getElementById('closeChooseBtn');
            btn.click();
        },
    },

    mounted: function () {
        if (!this.isLoggedIn) router.push({ name: "login" });
    },

    computed: {
        isLoggedIn() {
            return this.$store.state.login;
        },

        user() {
            return this.$store.state.User;
        }
    },

    watch: {
        isShowModal: function (val) {
            if (val == true) {                
                this.createBtn();
            }
        }
    }
};
</script>
