<template>
    <div class="subtitles">
        <div v-for="(sub,index) of subtitle" :key="sub.startTime" class="sub-row">
            <div class="item" @click="()=>onItemClicked(sub)">
                <textarea maxlength="200" spellcheck="false" :value="sub.text"
                @input="(event)=>updateSub(event,sub)"
                class="textarea"
                :class="[currentIndex === index ? 'highlight' : '',$store.getters['editor/checkSub'](sub) ? 'illegal' : '',]"
                />

            </div>
        </div>
    </div>
</template>

<script >
export default {
    computed:{
        render(){
            return this.$store.state.editor.render
        },
        subtitle() {
            return this.$store.state.editor.subs;
        },
        currentSubs() {
            return this.getCurrentSubs(this.subtitle, this.render.beginTime, this.render.duration);
        },
        availableWidth(){
            return this.$store.state.editor.availableClientWidth
        },
        gridGap(){
            return this.availableWidth /  this.render.gridNum;
        },
        currentTime(){
            return this.$store.state.editor.currentTime;
        },
        currentIndex(){
            return  this.$store.getters['editor/currentIndex']
        }
        
    },
    methods:{
        onItemClicked(sub){
            const player =this.$store.state.editor.player
           
            if(player ){
              
                 player.pause()
                if (player.duration >=sub.startTime) {
                    this.$store.commit("editor/setCurrentTime", sub.startTime + 0.001)
                }
            }
           
            
        },
        getCurrentSubs(subs, beginTime, duration) {
            return subs.filter((item) => {
                return (
                    (item.startTime >= beginTime && item.startTime <= beginTime + duration) ||
                    (item.endTime >= beginTime && item.endTime <= beginTime + duration) ||
                    (item.startTime < beginTime && item.endTime > beginTime + duration)
                );
            });
        },
        updateSub(event,sub){
            console.log("updateSub",sub,event.target.value);
            this.$store.commit("editor/updateSub",{
                sub,
                obj:{
                    text:event.target.value
                }
            })
        }
    }
}

</script>

<style lang="scss" scoped>
.subtitles{
    padding-top: 10px;
    max-height: calc(100vh - 150px - 200px);
    box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 80%);
    background-color: rgb(0 0 0 / 100%);
    overflow-y: scroll;


    .sub-row {
            .item {
                height: 100%;
                padding: 5px;

                .textarea {
                    border: none;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    font-size: 12px;
                    padding: 10px;
                    text-align: center;
                    background-color: rgba(255, 255, 255, 0.05);
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    transition: all 0.2s ease;
                    resize: none;
                    outline: none;

                    &.highlight {
                        background-color: rgb(0 87 158);
                        border: 1px solid rgba(255, 255, 255, 0.3);
                    }

                    &.illegal {
                        background-color: rgb(123 29 0);
                        border: 1px solid rgba(255, 255, 255, 0.3);
                    }
                }
            }
        }
}

</style>