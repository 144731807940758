<template>
<div>
  <div class="containerLog">
    <div class="forms-containerLog">
      <div class="signin-signup">
        <form class="sign-in-form" v-on:submit.prevent="validateLogin()">
          <img class="logo" src="../../assets/Logo3.png" alt="Africa Lyrics" />
          <h2 class="title mt-5">Sign in</h2>
          <h2 class="dc_error-login" v-if="error">
            Your password or username is incorrect
          </h2>
          <div class="input-field">
            <i class="fa fa-user"></i>
            <input
              type="email"
              placeholder="Username"
              v-model="username"
              required
            />
          </div>
          <div class="input-field">
            <i class="fa fa-lock"></i>
            <input
              type="password"
              placeholder="Password"
              v-model="password"
              required
            />
          </div>
          <Spinner v-bind:showSpinner="showSpinner" type="xl"></Spinner>
          <input
            type="submit"
            value="Login"
            class="btnLogin solid"
            @click="validateLogin()"
          />          
        </form>       

          <p class="user_registered" @click="resetPassword()"> Forgot your credentials? </p>

          <div class="resetPwdForm" v-if="displayResetForm">
            <form class="sign-in-form" v-on:submit.prevent="sendEmailToResetPwd()">
              <div class="row">
                <div class="col input-field mx-3">
                  <input id="inputEmailReset"
                    type="email"
                    placeholder="Email address"
                    v-model="emailToReset"
                    required
                  />
                </div>
                <div class="col">
                  <button type="submit" id="buttonEmailReset" class="btnLogin solid" :disabled="disabledResetBtn" @click="sendEmailToResetPwd()">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" :hidden="!disabledResetBtn"></span>
                  Reset Password
                </button>
                </div>
              </div>
            </form>
          </div>        
      </div>
    </div>

    <div class="panels-containerLog">
      <div class="panel left-panel">
        <div class="content">
          <h3>WELCOME ON AFRIKA LYRICS EDITOR</h3>
          <p>
            Play local songs or from cloud with Apple Music. Edit lyrics file and play song with LRC SYNC selected or generated... 
          </p>
          <h3>ENJOY!!</h3>
        </div>
        <img src="img/log.svg" class="image" alt />
      </div>
    </div>
  </div>
  
  </div>
</template>

<script>
import Spinner from "../SpinnerAfBig.vue"
import router from "../../router";
import * as service from "../../utils/DC_CalloutService.js";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      error: false,
      showSpinner: true,
      showSpinnerReset: true,
      emailToReset: '',
      displayResetForm: false,
      disabledResetBtn: false,
    };
  },

  components: {
    Spinner
  },

  methods: {
    getUser(){
      service.getUser(this.user.token)
        .then((result) => {
          if (result.data.id) {
            this.$store.dispatch("login", {
              id: result.data.id,
              name: result.data.name,
              email: result.data.email,
              email_verified_at: result.data.email_verified_at,
              updated_at: result.data.updated_at,
              date_modification: result.data.date_modification,
              roleName: result.data.roles[0]?.name,
              roleId: result.data.roles[0]?.id,
              permissionsNames: result.data.permissions.map(elt=>elt.name).join(','),
              permissionsIds: result.data.permissions.map(elt=>elt.id).join(','),
              username: result.data.username,
              token: this.user.token
            });
          }
        })
        .catch(function (error) {
          console.log("@@@@@ get user infos error " + error);
        });
    },

    async validateLogin() {
      this.error = false;
      this.showSpinner = false;
      service.getLogin(this.username, this.password)
        .then((result) => {
          if (result != null && result != "error") {
            this.$store.dispatch("login", {
              username: this.username,
              token: result
            });
            this.getUser();
            router.push({ name: "DashboardHome" }).catch(() => {});
          } else {
            this.error = true;
            this.password = "";
          }
          this.showSpinner = true;
        })
        .catch(function (error) {
          console.log('@@@@ error to log user: ', error);
          this.error = true;
          this.password = "";
        });
    },

    createBtn() {
      let btnLogin = document.createElement('button');
      btnLogin.setAttribute('data-bs-toggle', "modal");
      btnLogin.setAttribute('data-bs-target', "#forgotPassword");
      btnLogin.style.display = "none";
      document.body.appendChild(btnLogin);
      btnLogin.click();
    },

    resetPassword(){
      // this.createBtn();
      this.displayResetForm = !this.displayResetForm;
    },

    async sendEmailToResetPwd(){
      if(!this.emailToReset) return;
      this.disabledResetBtn = true;
      let clientToken = await service.getClientToken();
      let res = await service.resetPassword(this.emailToReset, clientToken);
      if(res.status == 200){
        if(res.data.status) this.showToast('We have emailed your password reset link!', 'success');
        if(res.data.email) this.showToast('We cannot find a user with that email address', 'error');
      }else{
        this.showToast('Failed to reset contact your administrator', 'error');
      }
      this.disabledResetBtn = false;
    },

    showToast(message, type) {
      this.$toasted.show(message, {
          type: type,
          theme: 'toasted-primary',
          position: 'top-center',
          duration: 10000
      })
    },

    closeModalBtn() {
      let btnLogin = document.getElementById('closeChooseBtn');
      btnLogin.click();
    },
  },
  mounted() {
    
  },
  computed: {
     user() {
      return this.$store.state.User;
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.user_registered{
  color: green;
  font-size: 1em;
  margin-top: 10px;
  cursor: pointer;
  font-weight: bolder;
  font-style: italic;
  text-decoration: underline;
}

.user_registered:hover{
  color: gray;
}

.resetPwdForm{
  transition: visibility 0s, opacity 0.5s linear;
}

.logo {
  width: 180px;
  height: 50px;
}
input {
  font-family: "Poppins", sans-serif;
}
h2::after {
  content: none;
}
.containerLog {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}
.forms-containerLog {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}
form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
form.sign-up-form {
  opacity: 0;
  z-index: 1;
}
form.sign-in-form {
  z-index: 2;
}
.title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}
.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}
.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}
.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}
.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}
.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}
.social-media {
  display: flex;
  justify-content: center;
}
.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}
.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}
.btnLogin {
  width: 150px;
  /* background-color: #0e5325; */
  background-image: linear-gradient(-45deg, #4481eb 0%, #2fe767 100%);
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}
.btnLogin:hover {
  background-color: #4d84e2;
}
.panels-containerLog {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.containerLog:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #4481eb 0%, #68fe04 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}
.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}
.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}
.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}
.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}
.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}
.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}
.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}
.btnLogin.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}
.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}
@media (max-width: 870px) {
  .containerLog {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup,
  .containerLog.sign-up-mode .signin-signup {
    left: 50%;
  }
  .panels-containerLog {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }
  .right-panel {
    grid-row: 3 / 4;
  }
  .left-panel {
    grid-row: 1 / 2;
  }
  .panel h3 {
    font-size: 1.2rem;
  }
  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }
  .btnLogin.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }
}
@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }
  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .containerLog {
    padding: 1.5rem;
  }
  .containerLog:before {
    bottom: 72%;
    left: 50%;
  }
  .containerLog.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}

@media (max-width: 1000px) {
  .panels-containerLog {
    display: none;
  }
  .containerLog:before {
    top: -28%;
  }
  .sign-in-form {
    padding-left: 0px;
    padding-right: 0px;
  }
}

#inputEmailReset{
  width: 250px;
}

#buttonEmailReset {
  width: 200px;
}
</style>