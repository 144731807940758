<template>
    <div class="card o-hidden border-0 shadow-lg mt-3">
        <div class="card-body p-0 container">
            <div class="p-5">
                <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4"><i class="bi bi-person-plus-fill"></i> Register new user</h1>
                </div>
                <div class="mb-4 shadow animated--fade-in" id="recap-user-info" v-if="isUserCreated">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center ">
                                <div class="col mr-2">
                                    <div class="mb-0 font-weigh text-gray-800 mb-1">
                                       New User login: <span class="text-success ">{{ userToCreate.email }}</span></div>
                                    <div class="mb-0 font-weight text-gray-800">New User password: <span class="text-success "> {{ userToCreate.password }}</span></div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-user fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form class="user">
                    <div class="form-group row">
                        <div class="col-sm-6 mb-3 mb-sm-0">
                            <label for="firstName">FirstName</label>
                            <input type="text" class="form-control" id="firstname" v-model="userToCreate.firstname"
                                placeholder="" required>
                        </div>
                        <div class="col-sm-6">
                            <label for="lastname">LastName</label>
                            <input type="text" class="form-control" id="lastname" v-model="userToCreate.lastname"
                                placeholder="">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6 mb-3 mb-sm-0">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" v-model="userToCreate.email"
                                placeholder="" required>
                        </div>
                        <div class="col-sm-6">
                            <label for="username">Username</label>
                            <input type="text" class="form-control" id="username" v-model="userToCreate.username"
                                placeholder="" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label for="password">Password</label>
                            <input type="text" class="form-control" id="password" v-model="userToCreate.password"
                                placeholder="" required>
                        </div>
                        <div class="col-sm-6 mb-3 mb-sm-0">
                            <label for="role">Roles</label>
                            <select class="form-control" id="role" v-model="userToCreate.role" required>
                                <option selected class="form-control">--None--</option>
                                <option class="form-control" v-for="(role, index) in usersRoles" :key="index">{{role}}</option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                        <label>Check or uncheck user permissions</label>
                    </div>
                    <div class="form-group row ml-1">
                        <div class="col-3 mb-2" v-for="(perm, index) in usersPermissions" :key="index">
                            <span class="checked_block">
                                <label class="container_check">{{perm}}
                                    <input type="checkbox" :id="perm.replaceAll(' ','_')" :value="perm" v-model="permissionsSelected">
                                    <span class="checkmark"></span>
                                </label>
                            </span>
                        </div>
                    </div> -->
                    <a href="#" class="btn btn-success btn-user" @click="registerUser()">
                        Create new user
                    </a>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import * as service from "../../utils/DC_CalloutService.js";

    const $ = require("jquery");
    window.$ = $;

export default { 
    data() {
        return {
            usersList: [],
            userSelected: {},
            userToCreate: {
                firstname: '',
                lastname: '',
                username: '',
                email: '',
                password: '',
                role: '',
            },
            showUserPassword: false,
            usersRolesPermissions: [],
            usersRoles: [],
            usersPermissions: [],
            permissionsSelected: [],
            isUserCreated: false
        };
    },

    methods: {
        async getUsers(){
             await service.getUsersList(this.user.token)
                .then((res) => {
                    if (res.data.data.length) {
                        this.usersList = res.data.data;
                    }else{
                        this.showToast('Not user found', 'warning');
                    }
                }).catch(() => {this.showToast('Get users error', 'error');});
        },

        async getUsersRolesPermissions(){
             await service.getRolesPermissions(this.user.token)
                .then((res) => {
                    if (res.data?.roles) {
                        this.usersRolesPermissions = res.data;                        
                        this.usersRoles = this.usersRolesPermissions.roles.map( elt => elt.name );
                        this.usersPermissions = this.usersRolesPermissions.permissions.map( elt => elt.name );
                    }
                }).catch((error) => {this.showToast(error, 'error');});
        },

        async updateRolesPermissions(userId, roles, permissions){
            await service.updateUserRolesPermissions(userId, roles, permissions, this.user.token)
                .then((res) => {
                    if (res.data.data.length) {
                        this.showToast(res.message, 'success');
                    }
                }).catch((error) => {this.showToast(error, 'error')});
        },

        showToast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 5000
            })
        },

        async registerUser(){
            this.isUserCreated = true;
            await service.createUser(
                {
                    "name": `${this.userToCreate.firstname} ${this.userToCreate.lastname}`,
                    "username": this.userToCreate.username,
                    "email": this.userToCreate.email,
                    "password": this.userToCreate.password,
                    "role": [this.userToCreate.role],
                }, this.user.token)
                .then((result)=>{                    
                    this.showToast(result.data.message, (result.status == 200) ? 'success' : 'error');
                    if(result.status == 200){
                            this.isUserCreated = true;
                        $("#recap-user-info").removeClass("user-info");
                    }
                }).catch((error)=>{
                    console.log('@@@@@ error on registered user: ', error);
                    this.showToast(error, 'error');
                     this.isUserCreated = false;
                }).finally(()=>{

                })
        },
    },

    mounted: async function(){
        await this.getUsersRolesPermissions();
    },

    computed: {
        user() {
            return this.$store.state.User;
        },
    },
}
</script>

<style scoped>

</style>