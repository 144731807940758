<template>
  <div>
    <router-view />
  </div>
</template>

<script>

const $ = require("jquery");
window.$ = $;
export default {
  
}
</script>

<style>

</style>
