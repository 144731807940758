<template>
  <div>
      <div v-if="playerLoaded">
          <div id="music-player">
            <div class="row pt-2 mx-2">
              <div class="col-4" id="searchInput">
                <form v-on:submit.prevent="quickSearch()">
                  <input type="search" placeholder="Enter artist and song title" v-model="searchKey" />
                  <i class="bi bi-search"></i>
                </form>
                <Spinner v-bind:showSpinner="!isSearch" type="lg"></Spinner>                
              </div>
              <!-- <div class="col-4"></div> -->
              <div class="col-4 text center">
                <!-- <div class="d-flex">
                  <div>
                    <img :src="music.cover || 'https://afrikalyrics.com/assets/images/logo-fav.png'" style="width: 60px; height: 60px" class="rounded-circle"/>
                  </div> -->
                  <!-- <div class="mx-1"> -->
                    <marquee>
                      <h5 class="song-name text center">{{ music.title }}</h5>
                      <h5 class="artist-name text center">{{ music.artist ? music.artist + ' ---- ' + music.album : ''}}</h5>
                    </marquee>
                  <!-- </div> -->
                <!-- </div> -->
              </div>
              <div class="col-4 float-end" :hidden="!isPublishAccess">
                  <a type="button" class="btn btn-sm btn-success float-end me-3" @click="downloadLyrics(lrcStatusPublished, 0)">
                      <i class="bi bi-cloud-arrow-up"></i>  Publish
                  </a>
              </div>
            </div>

            <!-- Search result quickly -->
            <div id="quickSearch" class="animated--fade-in">
              <div v-for="(song, index) in playlist" :key="index"> 
                <div class="resultItem fw-lighter row" @click='PlaylistSinglePlay(index)'>
                  <div class="col-10">
                    <span class="me-5 text-start">
                      <img :src="song.cover" style="width: 20px; height: 20px" class="rounded-circle"/>
                      {{ song.title }}, {{ song.artist }} -- {{ song.album }}
                    </span>
                  </div>
                  <div class="col-2 row">
                    <div  class="me-3 col" >{{ calculateCurrentValue(song.time) }}</div>
                    <div class="song-animate hideItem col" :id="index+'_playAnimate'">
                      <i></i><i></i><i></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Begin print lyrics -->
            <div class="lyrics hideItem col animated--fade-in" id="lrcItem">
              <div class="lyrics-content">
                <div class="mt-5">
                  <h2 v-for="(line, index) in lyricsLines" :key="index">
                    {{ line }}
                  </h2>
                  <div class="mb-2" id="lrcInput animated--fade-in" v-if="!ifExistingLrc">
                    <textarea class="text-area-custom" placeholder="Paste Your LRC Text Here" v-model="lrcInput"></textarea><br/>
                    <div class="float-end mb-2" style="margin-right: 80px">
                      <button type="submit" class="btn btn-secondary" @click="handleLrcInput()" :hidden="showhandleLrc"> Save </button>
                    </div>
                  </div>
                  <div class="float-end mt-2" style="margin-right: 250px" :hidden="showSaveLrc">               
                    <button type="button" class="btn btn-success" @click="saveLrcInput(lrcStatusWaiting)">Save LRC</button>
                    <!-- <div class="dropdown">
                      <span>Save LRC</span>
                      <div class="dropdown-content">
                        <p @click="saveLrcInput(lrcStatusDraft)">Like {{ lrcStatusDraft }}</p>
                        <p @click="saveLrcInput(lrcStatusWaiting)">Like {{ lrcStatusWaiting }}</p>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="lyrics hideItem col animated--fade-in" id="lrcPlay">
              <div class="lyrics-content mt-5">
                <h3 v-for="(line, index) in lyricsLinesNew" :key="index">
                  {{ line }}
                </h3>
              </div>
            </div>

            <div class="lyrics mb-2 hideItem col animated--grow-in" id="lyricsItem">
              <textarea class="text-area-custom"  placeholder="Paste Your Lyrics Text Here" v-model="lyricsInput"></textarea><br />
              <div class="float-end mt-2 mb-2" style="margin-right: 80px">
                <button class="btn btn-secondary mx-4" data-bs-toggle="modal" data-bs-target="#searchLyricsModal" @click="searchLyrics()">Search Lyrics</button>
                <button  type="submit"  class="btn btn-secondary" @click="saveLyricsInput()" :hidden="showSaveLyrics">Save</button>
              </div>
            </div>
            <!-- End print Lyrics -->
            
            <div id="playerMedia">
              <div id="bar">
                <div id="seekObjContainer">
                  <div id="currentTime" class="start-time"></div>
                  <input  id="seekObj"  type="range"  name="rng"  min="0"  step="0.25"  value="0" @input="seekAudio()"  class="seek custom"/>
                  <div id="totalTime" class="end-time"></div>
                </div>
              </div>
              <div class="menu row text-center">
                <div class="col-sm-7">
                  <span class="hideSmallMedia">
                    <button><i class="bi bi-volume-up"></i></button>
                    <input  id="volume"  type="range"  name="volu"  min="0"  max="100"  step="10"  value="50" @input="volumeSetup()"  class="vol"/>
                  </span>

                  <button @click="repeat()"  id="repeat"  class="btnColor"  data-bs-toggle="tooltip"  data-bs-placement="top"  title="Repeat">
                    <i class="bi bi-arrow-repeat"></i>
                  </button>

                  <button id="play1-btn" @click="play()">
                    <img :src="getImgUrl('play_button.png')" style="height: 35px; width: 35px"/>
                  </button>
                  <button id="play2-btn" class="hideItem" @click="play()">
                    <img :src="getImgUrl('pause_button.png')" style="height: 35px; width: 35px"/>
                  </button>

                  <button @click="keepTime()"  id="lrcOk"  class="btnColor"  data-bs-toggle="tooltip"  data-bs-placement="top"  title="Keep Time" :disabled="isPlayLrc">
                    <i class="bi bi-stopwatch"></i>
                  </button>

                  <button @click="previous()"  data-bs-toggle="tooltip"  data-bs-placement="top"  title="Previous Line"  class="btnColor" :disabled="isPlayLrc">
                  <i class="bi bi-arrow-left"></i>
                  </button>

                  <button @click="next()"  data-bs-toggle="tooltip"  data-bs-placement="top"  title="Next Line"  class="btnColor" :disabled="isPlayLrc">
                    <i class="bi bi-arrow-right"></i>
                  </button>

                  <button @click="showPlaylist()"  data-bs-toggle="tooltip"  data-bs-placement="top"  title="Playlist"  class="btnColor">
                    <i class="bi bi-list-task"></i>
                  </button>
                </div>

                <div class="col-sm-5">
                  <button class="menuItem float-end" role="button" id="otherActions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="ifExistingLrc">
                      <i class="bi bi-three-dots-vertical"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="otherActions">
                      <a class="dropdown-item" href="#"   data-bs-toggle="modal" data-bs-target="#editLrcModal">Edit LRC</a>
                  </div>
                  
                  <button class="menuItem float-end" :disabled="!isEditLRC" @click="editLrc()">
                    <span id="editLrc"><i class="bi bi-music-note-list"></i> LRC</span>
                  </button>

                  <button class="menuItem float-end" :disabled="!isEditLRC" @click="editLyrics()">
                    <span id="editLyrics"><i class="bi bi-card-text"></i> Lyrics </span>
                  </button>

                  <button class="menuItem float-end" @click="playAsLrc()">
                    <span id="playAsLrc"><i class="bi bi-music-note"></i> LRC sync </span>
                  </button>
                </div>
              </div>
            </div>
            <!-- End player -->
          </div>

          <!-- Modal -->
          <button type="button" id="warnModal" class="hideItem" data-bs-toggle="modal" data-bs-target="#staticBackdrop"></button>
          <div class="modal fade" id="staticBackdrop" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body alert alert-warning" role="alert" id="modalBody">
                  {{ toastMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="searchLyricsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    Search lyrics text for:
                    <strong><b>{{ music.title }} </b></strong>
                  </h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBtn"></button>
                </div>
                <div class="modal-body">
                  <div class="spinner-grow text-success text-center" style="width: 3rem; height: 3rem" role="status" :hidden="showSpinner">
                    <span class="sr-only"></span>
                  </div>
                  <div :hidden="!showSpinner">
                    <select class="form-select" aria-label="Default select example" v-model="lyricsSelected">
                      <option disabled value="">Choose lyrics text</option>
                      <option v-for="(lyric, index) in lyricsSearch" :key="index">
                        {{ lyric.tag }}
                      </option>
                    </select>
                    <div id="parole" class="mt-5"></div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button type="button" class="btn btn-success" @click="saveSelected()" :disabled="isEmptySearch">
                    Save selected
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal to Edit current lyrics file  -->
          <div class="modal fade" id="editLrcModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content" id="modelLrcEditContent">
                    <div class="modal-header">
                        <h4 class="modal-title headerEdit" id="staticBackdropLabel">
                            EDIT LRC FILE: <strong> {{music.artist}}, {{music.title}} </strong>
                        </h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBtnLrc">
                            
                        </button>
                    </div>
                    <div class="modal-body modalBodyContent">
                        
                        <Spinner v-bind:showSpinner="showSpinner" type="lg"></Spinner>

                        <div class="text-start mx-3"  :hidden="!showSpinner">
                            <textarea id="LRCText" class="form-control areaEditLrc" v-model="lrcInput"></textarea>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-success" @click="handleLrcInput()">Update LRC for this song</button>
                    </div>
                </div>
            </div>
          </div>
      </div>
      <div class="mt-4" v-else>      
          <Spinner v-bind:showSpinner="playerLoaded" type="lg"></Spinner>
        <center><h2>User authentication...</h2></center>
      </div>
  </div>
</template>

<script>
"use strict";
import Spinner from "../SpinnerAfBig.vue";
import { Lrc } from "lrc-kit";
import { Runner } from "lrc-kit";
import { Music, Constants } from '../../utils/DC_player.js';
import * as service from "../../utils/DC_CalloutService.js";
import * as appleMusic from "../../utils/DC_AppleMusic.js";
import router from "../../router";

const $ = require("jquery");
window.$ = $;
const CONSTANTS = new Constants();

export default {
  name: "LyricsPlayerApple",
  components: { Spinner },

  props: {
    music_p: Object,
    lyrics: String,
    lyricsText: String,
    songId: String
  },

  data() {
    return {
      isSeeking: false,
      seek: document.getElementById("seekObj"),
      volume: 50,
      player: document.getElementById("player"),
      prevcurrentime: 0,
      playIconSrc: "play_button.png",

      p: {
        currentTime: 0,
        paused: true,
        readyState: 0,
        duration: null,
        isPlaying: false,
        started: false,
      },
      lrcStatusDraft: CONSTANTS.LRC_STATUS_DRAFT,
      lrcStatusWaiting: CONSTANTS.LRC_STATUS_WAITING,
      lrcStatusVerfied: CONSTANTS.LRC_STATUS_VERFIED,
      lrcStatusPublished: CONSTANTS.LRC_STATUS_PUBLISHED,

      lrc: {},
      lyricsInput: "",
      lyricsAlternativeText: "NO LYRICS TEXT FOR THIS SONG.",
      isLyricsEmpty: true,
      lyricsLines: [],
      lyricsLinesNew: [],
      lyricsTimestamp: 0,
      linePosition: 0,
      isDownloadLrc: true,
      counter: 0,
      play1: 0,
      isPlayLrc: true,
      playlist: [],
      playListIndex: 0,
      tmpTimestamp: [],
      timeList: [],
      lyricsSearch: [],
      showSpinner: true,
      lyricsSelected: "",
      isEmptySearch: true,
      oldTitle: "",
      toastMessage: "",
      isSyncActive: false,
      playerLoaded: false,
      lrcInput: '',
      isShowAnimation: false,
      searchKey: '',
      isSearch: false,
      music: {},
      appleMusicKit: {},
      mediaCanPlay: false,
      isUserAuthorise: false,
      isSaveDisable: true,
      isPublishAccess: false,
      isEditLRC: true,
      isSettings: false,
      isShowModalToEditLrc: false,
      ifExistingLrc: false,
    };
  },

  methods: {
    init() {
      this.p.currentTime = 0;
      this.p.paused = true;
      //this.p.readyState = 0;
      this.p.duration = null;
      this.p.isPlaying = false;
      this.p.started = false;
      this.p.readyState = 4;
      this.mediaCanPlay = false;
      
      this.isSeeking = false;
      this.playerLoaded = true;
      this.seek = document.getElementById("seekObj");
      this.player = document.getElementById("player");
      this.volume = document.getElementById("volume");
      $('#lrcInput').removeClass('hideItem');
            
      this.appleMusicKit.player.volume = this.volume ? this.volume.value/100 : 0.5;
      this.lyricsInput = '';
      this.lrcInput = '';
      this.lyricsLines = [this.lyricsAlternativeText];
      this.lyricsLinesNew = [this.lyricsAlternativeText];
      this.prevcurrentime = 0;
      this.tmpTimestamp = []
      this.checkPlayList();
    },

    getImgUrl(pet) {
      let images = require.context("../../assets/artistsImg/", false, /\.*$/);
      return images("./" + pet);
    },

    pushLyrics(content, timestamp, i) {
      let current = $("#lrcItem .current");
      if (current.length != 0) {
        this.tmpTimestamp[i] = {
          content: content.split("]").slice(-1),
          timestamp: timestamp,
        };
      }
    },

    loadSyncLrc() {
      let content;
      // this.isPlayLrc = true;
      if (this.lrc.lyrics.length == 0) {
        this.lyricsLinesNew.pop();
        this.lyricsLinesNew.push(this.lyricsAlternativeText);
        return;
      }
      content = this.lrc.toString({ combine: false });
      let lrcRunner = new Runner(Lrc.parse(content));
      this.timeList = [];
      this.lyricsLinesNew = [];
      lrcRunner.lrc.lyrics.forEach((element) => {
        this.timeList.push(element.timestamp - 0.5);
        this.lyricsLinesNew.push(element.content);
      });
      this.playlist[this.playListIndex].lyrics = this.lyricsLines;
      this.playlist[this.playListIndex].lrc = this.lrc;
    },

    saveLyricsInput() {
      this.lyricsLines = [];
      this.lyricsLines = this.lyricsInput
        .trim()
        .replace(/\[[^\]]*\]/g, "")
        .split("\n")
        .filter((line) => line.trim());

      for (let index = 0; index < this.lyricsLines.length; index++) {
        let t = $(`#lrcItem h2:nth-child(${index + 1})`)
          .text()
          .split("]");
        if (t.length == 2) {
          $(`#lrcItem h2:nth-child(${index + 1})`).html(
            `${t[0]}]${this.lyricsLines[index]}`
          );
        
          this.pushLyrics(
            this.lyricsLines[index],
            this.transformTextTimeToSecond(t[0].replaceAll('[', '')),
            index
          );          
        }
      }this.lyricsLines
      this.playlist[this.playListIndex].lyricsText = this.lyricsInput;
      $('#lrcInput').addClass('hideItem');
      this.editLrc();
    },

    saveLrcInput(lrcStatus, source = 0) {
      if (!this.tmpTimestamp[0]) {
        $("modalBody").removeClass("alert-success");
        $("modalBody").addClass("alert-warning");
        this.toastMessage = "Please add at least one line of lyrics with time before saving.";
        $("#warnModal").click();
        return false;
      }

      this.lrc.lyrics = [];
      this.lrc.lyrics = this.tmpTimestamp;
      this.loadSyncLrc();
      this.playAsLrc();

      if(source != 1) this.downloadLyrics(lrcStatus, 1);

      return true;
    },

    handleLrcInput(){
      try{
        this.lrc = Lrc.parse(this.lrcInput);
        this.lyricsLines = [];
        for (let index = 0; index < this.lrc.lyrics.length; index++) {
          this.lyricsLines.push(`[${this.calculateCurrentValue(this.lrc.lyrics[index].timestamp)}]${this.lrc.lyrics[index].content}`);
        }
        this.tmpTimestamp = this.lrc.lyrics;

        this.saveLrcInput(this.lrcStatusDraft, 1);
        this.wrapperLyrics(this.lyricsLines);
        $('#lrcInput').addClass('hideItem');
        document.getElementById("closeBtnLrc").click();
      }catch(e){
        console.log('@@@@ lrc input error: ', e);
      }
    },

    wrapperLyrics(lines) {
      let li = [];
      lines.forEach((line) => {
        li.push(line.split("]").slice(-1));
      });
      this.lyricsInput = [];
      this.lyricsInput = li.join("\n");
    },

    showPlaylist(){
      $("#lrcItem").addClass("hideItem");
      $("#lrcPlay").addClass("hideItem");
      $("#quickSearch").removeClass("hideItem");
      $("#lyricsItem").addClass("hideItem");

      $("#editLrc").removeClass("currentItem");
      $("#playAsLrc").removeClass("currentItem");
      $("#editLyrics").removeClass("currentItem");
      this.isPlayLrc = true;
    },

    editLyrics() {
      $("#lrcItem").addClass("hideItem");
      $("#lrcPlay").addClass("hideItem");
      $("#quickSearch").addClass("hideItem");
      $("#lyricsItem").removeClass("hideItem");

      $("#editLrc").removeClass("currentItem");
      $("#playAsLrc").removeClass("currentItem");
      $("#editLyrics").addClass("currentItem");
      // $("#lrcItem").scrollTop(0);
      this.isPlayLrc = true;
    },

    editLrc() {
      $("#lyricsItem").addClass("hideItem");
      $("#lrcPlay").addClass("hideItem");
      $("#quickSearch").addClass("hideItem");
      $("#lrcItem").removeClass("hideItem");

      $("#editLrc").addClass("currentItem");
      $("#playAsLrc").removeClass("currentItem");
      $("#editLyrics").removeClass("currentItem");
      this.isPlayLrc = false;
    },

    playAsLrc() {
      $("#lrcItem").addClass("hideItem");
      $("#lyricsItem").addClass("hideItem");
      $("#quickSearch").addClass("hideItem");
      $("#lrcPlay").removeClass("hideItem");

      $("#editLrc").removeClass("currentItem");
      $("#quickSearch").removeClass("currentItem");
      $("#playAsLrc").addClass("currentItem");
      $("#editLyrics").removeClass("currentItem");
      if (this.lrc.lyrics.length == 0) {
        this.lyricsLinesNew = [];
        this.lyricsLinesNew.push(this.lyricsAlternativeText);
        return;
      }
      this.isSyncActive = true;
    },

    downloadLyrics(lrcStatus, source) {
      if (source == 0) {
          if (this.tmpTimestamp.length != this.lyricsLines.length) {
            $("modalBody").removeClass("alert-success");
            $("modalBody").addClass("alert-warning");
            this.toastMessage = "Please complete all lyrics Lines with time before saving";
            $("#warnModal").click();
            return;
        }    
      }

      this.lrc.info["ar"] = this.music.artist;
      this.lrc.info["ti"] = this.music.title;
      this.lrc.info["al"] = this.music.album;
      this.lrc.info["length"] = this.music.time;
      this.lrc.info["by"] = "Afrika Lyrics";
      this.lrc.info["re"] = "LRC Editor Web App";
      this.lrc.info["ve"] = "Version 1.0";
      const content = this.lrc.toString({ combine: false });
      
      let fileLrc = new File(
        [content],
        `[AF_Lyrics]${this.music.title}_${this.music.artist}.lrc`
      );
      let fileTxt = new File(
        [this.lyricsInput],
        `[AF_Lyrics]${this.music.title}_${this.music.artist}.txt`
      );

      if(this.music.externalId != this.music.id){
        this.ws_updateLrc(this.music.artist, this.music.title, fileLrc, fileTxt, lrcStatus);
      }else{
        this.ws_saveLrc(this.music.artist, this.music.title, fileLrc, fileTxt, lrcStatus);
      }
      this.playlist[this.playListIndex].lrc = this.lrc;
    },

    download(contentFile, fileName) {
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(contentFile)
      );
      element.setAttribute("download", fileName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },

    ws_saveLrc(artistName, title, fileLrc, fileTxt, lrcStatus) {
      service
        .saveLrc(artistName, title, fileLrc, fileTxt, 'Apple Music', this.music.id, this.user.token, lrcStatus)
        .then((result) => {
          console.log("@@@@@ result fetch lyrics: " + result);
          if (result?.data) {
            $("modalBody").removeClass("alert-warning");
            $("modalBody").addClass("alert-success");
            this.toastMessage = "File save success !!";
            this.music.id = result.data.data.id;
          } else {
            $("modalBody").removeClass("alert-success");
            $("modalBody").addClass("alert-warning");
            this.toastMessage = "File not save !!";
          }
        })
        .catch(function (error) {
          console.log("@@@@@ fetch lyrics error: ", error);
          $("modalBody").removeClass("alert-success");
          $("modalBody").addClass("alert-warning");
          this.toastMessage = "System Error! Please contact administrator";
        });
        $("#warnModal").click();
    },

    ws_updateLrc(artistName, title, fileLrc, fileTxt, lrcStatus) {
      service
        .updateLrc(this.music.id, artistName, title, fileLrc, null, this.user.token, fileTxt, lrcStatus)
        .then((result) => {
          console.log("@@@@@ result fetch lyrics: " + result);
          if (result) {
            $("modalBody").removeClass("alert-warning");
            $("modalBody").addClass("alert-success");
            this.toastMessage = "File update success !!";
            console.log("@@@@@ result fetch lyrics: " + result);
          } else {
            $("modalBody").removeClass("alert-success");
            $("modalBody").addClass("alert-warning");
            this.toastMessage = "File not update !!";
          }
        })
        .catch(function (error) {
          console.log("@@@@@ fetch lyrics error: ", error);
          $("modalBody").removeClass("alert-success");
          $("modalBody").addClass("alert-warning");
          this.toastMessage = "System Error! Please contact administrator";
        });
        $("#warnModal").click();
    },

    updateSyncLyrics(){
        if (this.counter == 0) {
          if (this.p.currentTime < this.timeList[this.counter]) {
            this.previous1();
          }
        }
        if (
          this.counter == this.timeList.length &&
          this.p.currentTime <= this.timeList[this.counter - 1]
        ) {
          this.counter--;
          this.previous1();
        }
        if (this.p.currentTime >= this.timeList[this.counter]) {
          if (this.counter <= this.timeList.length) {
            this.counter++;
          }
          this.next1();
          this.centerize1();
        } else if (this.p.currentTime < this.timeList[this.counter - 1]) {
          this.counter--;
          this.previous1();
        }
    },

    centerize1() {
      if (this.play1 == 0) return;
      if ($(".current1").length == 0) return;
      let a = $(".current1").height();
      let c = $("#lrcPlay").height();
      let d =
        $(".current1").offset().top - $(".current1").parent().offset().top;
      let e = d + a / 2 - (c * 1) / 4;
      $("#lrcPlay").animate({ scrollTop: e + "px" });
    },

    next1() {
      let current = $("#lrcPlay .current1");
      if (current.length == 0) {
        $("#lrcPlay h3:nth-child(1)").addClass("current1");
        return;
      }
      current.removeClass("current1");
      current.next().addClass("current1");
    },

    previous1() {
      let current = $("#lrcPlay .current1");
      if (current.length == 0) {
        return;
      }
      let first = $("#lrcPlay h3:nth-child(1)");
      current.removeClass("current1");
      if (current === first) {
        return;
      }
      current.prev().addClass("current1");
    },

    repeat() {
      //DZ.player.seek();
      $("#play1-btn").addClass("hideItem");
      $("#play2-btn").removeClass("hideItem");
      $(`.currentSong`).removeClass('pause');
    },

    async quickSearch(){
      if(this.searchKey){
          this.isSearch = true;
          const isMusicId = +this.searchKey ? true : false;
          let res;
          const resTmp = isMusicId ? await appleMusic.searchSongWithId(this.searchKey) : await appleMusic.searchSong(this.searchKey.replaceAll(/\s|,|,\s/g,'+'));
          if((isMusicId && (resTmp.data && resTmp.data?.data?.length > 0)) || (!isMusicId && (resTmp.data && resTmp.data?.results?.songs?.data?.length > 0))){
              res = !isMusicId ? resTmp.data.results.songs.data :  resTmp.data.data;
          }
        // const res = await appleMusic.searchSong(this.searchKey.replaceAll(/\s|,|,\s/g,'+'));
        console.log('@@@ from app: ', res);
        try {          
          if(res && res.length > 0){
            if(this.playlist.length > 0){
              this.playlist = [this.playlist[this.playListIndex]];
              this.playListIndex = 0;
              $(`.currentSong`).addClass('hideItem');
              $(`.currentSong`).removeClass('currentSong');            
              $(`#0_playAnimate`).removeClass('hideItem');
              $(`#0_playAnimate`).addClass('currentSong');
              if(this.appleMusicKit.player.isPlaying == false)  $(`.currentSong`).addClass('pause');
            }else{
              this.playlist = [];
            }
            res.forEach(son => {
              this.playlist.push(new Music(son.id, son.attributes.name, son.attributes.artistName, son.attributes.albumName, son.attributes.durationInMillis/1000, 
                                            son.attributes.artwork.url.replaceAll(/\{w\}|\{h\}/g,'1000'), son.attributes.previews[0].url, undefined, undefined, '', son.id));
            });
          }else{
            if(this.playlist.length > 0){
              this.playlist = [this.playlist[this.playListIndex]];
              this.playListIndex = 0;
            }
            this.showToast('Not result found for this search', 'info');
          }
          this.isSearch = false;
        } catch (error) {
          this.playlist = [];
          this.isSearch = false;
          this.showToast('Not result found for this search', 'info');
        }
      }
    },

    async searchSongWithId(){
        this.isSearch = true;
        const res = await appleMusic.searchSongWithId(this.music_p.externalId);
        console.log('@@@ from app: ', res);
        try {          
          if(res.data && res.data.data.length > 0){
            if(this.playlist.length > 0){
              this.playlist = [this.playlist[this.playListIndex]];
              this.playListIndex = 0;
              $(`.currentSong`).addClass('hideItem');
              $(`.currentSong`).removeClass('currentSong');            
              $(`#0_playAnimate`).removeClass('hideItem');
              $(`#0_playAnimate`).addClass('currentSong');
              if(this.appleMusicKit.player.isPlaying == false)  $(`.currentSong`).addClass('pause');
            }else{
              this.playlist = [];
            }
            const son = res.data.data[0];
            this.playlist.push(new Music(this.music_p.id, son.attributes.name, son.attributes.artistName, son.attributes.albumName, 
                                            son.attributes.durationInMillis/1000, 
                                            son.attributes.artwork.url.replaceAll(/\{w\}|\{h\}/g,'1000'), 
                                            son.attributes.previews[0].url, this.music_p?.lrc, this.music_p?.lyrics, 
                                            this.music_p?.lyricsInput, son.id));
            this.isSearch = false;
            this.PlaylistSinglePlay(0);
          }else{
            this.isSearch = false;
            this.showToast('Apple song Id of this song does not exist on Apple Music', 'error');
          }
        } catch (error) {
          this.playlist = [];
          this.isSearch = false;
          this.showToast('Apple song Id of this song does not exist on Apple Music', 'error');
        }
    },

    async PlaylistSinglePlay(index){      
      this.configuration();
      let isNewSelected = true;
      if($('.currentSong').attr('id') === `${index}_playAnimate`){
        isNewSelected = false;
      }
      let isAddQueueError = false;
      if(isNewSelected){
        console.log('@@@@@ playlist values: ', this.playlist);
          $('#music-player').css('background-image',`url(${this.playlist[index].cover})`);
          await this.playTrack(this.playlist[index].externalId)
          .then((res) => {
            console.log('@@@ add queue success', res);
          })
          .catch(() => {
              alert('TRACK CONTENT IS NOT AVAILABLE!');
              isAddQueueError = true;
          })
      }

      if(isAddQueueError) return;

      $(`.currentSong`).addClass('hideItem');
      $(`.currentSong`).removeClass('currentSong');
      this.isShowAnimation = false;
      if(!this.isShowAnimation){
        $(`#${index}_playAnimate`).removeClass('hideItem');
        $(`#${index}_playAnimate`).addClass('currentSong');
        this.isShowAnimation = true;
      }
      this.music = this.playlist[index];
      this.playListIndex = index;
      
      if(isNewSelected){
        this.init()
      }
      this.p.readyState = 4;
      this.play();

      this.searchExistingLrc();
    },

    checkPlayList() {
      this.lrc = new Lrc();
      const mus = this.playlist[this.playListIndex];
      if (mus && mus.lrc) {
        this.lrc.info = mus.lrc.info;
        this.lrc.lyrics = mus.lrc.lyrics;
        this.lyricsLines = [];
        this.lrc.lyrics.forEach((element) => {
          this.lyricsLines.push(`[${this.calculateCurrentValue(element.timestamp)}]${element.content}`);
        });
        this.tmpTimestamp = mus.lrc.lyrics;
        if (this.lyricsLines) {
          this.wrapperLyrics(this.lyricsLines);
        }
        this.loadSyncLrc();
        $('#lrcInput').addClass('hideItem');
      }else if(mus && mus.lyrics){
        this.lyricsLines =  mus.lyrics;
        this.wrapperLyrics(this.lyricsLines);
        $('#lrcInput').addClass('hideItem');
      }else if (mus && mus.lyricsText) {
        this.lyricsInput = mus.lyricsText;
        this.saveLyricsInput();
      }else{
        this.lyricsInput = '';
        this.lyricsLines = [this.lyricsAlternativeText];
      }
    },

    /** Player function */
    calculateTotalValue(length) {
      var minutes = Math.floor(length / 60),
        seconds_int = length - minutes * 60,
        seconds_str = seconds_int.toString(),
        seconds = seconds_str.split(".")[0],
        temp_min = minutes.toString().length === 1 ? "0" + minutes : minutes,
        temp_sec = seconds.toString().length === 1 ? "0" + seconds : seconds;
      return temp_min + ":" + temp_sec;
    },

    padTime(t) {
      return t < 10 ? "0" + t : t;
    },

    calculateCurrentValue(_seconds) {
      console;
      if (typeof _seconds !== "number") return "";
      if (_seconds < 0) {
        _seconds = Math.abs(_seconds);
        //console.log(_seconds);
      }
      var hours = Math.floor(_seconds / 3600),
        minutes = Math.floor((_seconds % 3600) / 60),
        seconds = Math.floor(_seconds % 60);
      var hour = hours > 0 ? this.padTime(hours) + ":" : "";
      return hour + this.padTime(minutes) + ":" + this.padTime(seconds);
    },

    calculateCurrentValue2(_seconds) {
      let milliseconds = (_seconds % 1).toFixed(2).substring(2);
      if (typeof _seconds !== "number") return "";
      if (_seconds < 0) {
        _seconds = Math.abs(_seconds);
        //console.log(_seconds);
      }
      var hours = Math.floor(_seconds / 3600),
        minutes = Math.floor((_seconds % 3600) / 60),
        seconds = Math.floor(_seconds % 60);
      var hour = hours > 0 ? this.padTime(hours) + ":" : "";
      return (
        hour +
        this.padTime(minutes) +
        ":" +
        this.padTime(seconds) +
        ":" +
        this.padTime(+milliseconds)
      );
    },

    transformTextTimeToSecond(textTime){
      const tb = textTime.split(':');
      return (+tb[0])*60+(+(tb[1]+'.'+tb[2]));
    },

    async seekAudio() {
      this.isSeeking = true;
      await this.appleMusicKit.seekToTime(this.seek.value);
      this.p.currentTime = this.seek.value;
      if(this.p.paused == true){
        $("#play1-btn").addClass("hideItem");
        $("#play2-btn").removeClass("hideItem");
      }
      this.isSeeking = false;
    },

    volumeSetup() {
      this.appleMusicKit.player.volume = this.volume.value/100;
    },

    initProgressBar() {
      if (!this.isSeeking) {
        this.seek.value = this.p.currentTime;
      }
      var length = this.p.duration;
      var current_time = this.p.currentTime;

      // calculate total length of value
      var totalLength = this.calculateTotalValue(length);

      // calculate current value time
      var currentTime = this.calculateCurrentValue(current_time);
      if (this.p.readyState === 4 && this.mediaCanPlay) {
        $(".end-time").html(totalLength);
        $(".start-time").html(currentTime);
      }
      //checking if the current time is bigger than the previous or else there will be sync different between remaining and current
      if (currentTime > this.prevcurrentime) {
        //calculate the remaining time
        var rem_time = length - current_time;
        $(".rem-time").html(this.calculateCurrentValue(rem_time));
      }
      //setting the previouscurrent time to this current time
      this.prevcurrentime = currentTime;

      if ((this.p.currentTime == this.p.duration) && (this.p.duration != 0)) {
        $("#play2-btn").addClass("hideItem");
        $("#play1-btn").removeClass("hideItem");
        this.PlaylistSinglePlay(this.playListIndex);
        // if(this.playlist[this.playListIndex+1]){
        //   this.PlaylistSinglePlay(this.playListIndex+1);
        // }else{
        //   this.PlaylistSinglePlay(0);
        // }
      }

      return 0;
    },

    async playTrack(id){
      return new Promise((res, rej) => {
          this.appleMusicKit.setQueue({
            ['song']: id
          }).then((isAble) => {
            console.log('@@@@@ music queue length1111 success: ', isAble);
            res({success: isAble, error: ''});
          })
          .catch((error) => {
            console.log('@@@@@ music queue length1111 error: ', error);
            rej({success: '', error: error});
          })
      })
    },

    async play() {
      if(this.playlist.length > 0){   
        if (this.p.paused === false) {
          this.play1 = 0;
          this.appleMusicKit.pause();
          this.p.paused = true;
          $("#play2-btn").addClass("hideItem");
          $("#play1-btn").removeClass("hideItem");
          $(`.currentSong`).addClass('pause');
        } else {
          this.play1 = 1;
          $(".start-time").html("Loading...");
          if (this.p.started == true) {
            await this.appleMusicKit.play(); 
          } else {
            if(this.appleMusicKit.player.isPlaying) this.appleMusicKit.stop();
            
            await this.appleMusicKit.play();
              
            this.p.started = true;
            this.p.isPlaying = true;
          }
          this.p.paused = false;
          $("#play1-btn").addClass("hideItem");
          $("#play2-btn").removeClass("hideItem");
          $(`.currentSong`).removeClass('pause');
        }
      }
    },

    keepTime() {
      if(this.currentTime == 0){
        return;
      }
      let first = $("#lrcItem .current");
      if (first.length == 0 || this.linePosition == this.lyricsLines.length) {
        $("#lrcItem h2:nth-child(1)").addClass("current");
        this.linePosition = 0;
      }
      if(this.linePosition > 0){
        let current = $("#lrcItem .current");
        current.removeClass("current");
        current.next().addClass("current");
      }
      if (this.p.readyState === 4 && this.lyricsLines.length != this.linePosition && this.lyricsLines.length > 1) {
        let timeCurrent = this.p.currentTime+((new Date()).getMilliseconds())/1000;
        $("#lrcItem .current").html(
          `[${this.calculateCurrentValue2(timeCurrent)}]${this.lyricsLines[
            this.linePosition
          ]
            .split("]")
            .slice(-1)}`
        );
        this.pushLyrics(
          this.lyricsLines[this.linePosition],
          timeCurrent,
          this.linePosition
        );
        
        this.next();
      }
    },

    next() {
      let current = $("#lrcItem .current");
      if (this.lyricsLines.length - 1 == this.linePosition) {
        this.linePosition++;
        this.centerize();
        return;
      }
        
      current.removeClass("current");
      $(`#lrcItem h2:nth-child(${this.linePosition+1})`).addClass("current");

      this.lyricsLines.length > this.linePosition
        ? this.linePosition++
        : (this.linePosition = 0);
      this.centerize();
    },

    previous() {
      var current = $("#lrcItem .current");
      if (current.length == 0 || this.linePosition == 0) {
        $("#lrcItem h2:nth-child(1)").removeClass("current");
        $(`#lrcItem h2:nth-child(${this.lyricsLines.length})`).addClass(
          "current"
        );
        this.linePosition = this.lyricsLines.length - 1;
        this.centerize();
        return;
      }
      var first = $("#lrcItem h2:nth-child(1)");
      current.removeClass("current");
      if (current === first) {
        return;
      }
      current.prev().addClass("current");
      this.linePosition--;
      this.centerize();
    },

    centerize() {
      if ($(".current").length == 0) return;
      var a = $(".current").height();
      var c = $("#lrcItem").height();
      var d = $(".current").offset().top - $(".current").parent().offset().top;
      var e = d + a / 2 - (c * 1) / 4;
      $("#lrcItem").animate(
        { scrollTop: e + "px" },
        { easing: "swing", duration: 500 }
      );
    },

    searchLyrics() {
      this.showSpinner = false;
      if (this.oldTitle === this.music.title && this.lyricsSearch.length > 0) {
        console.log("@@@ song title: ", this.oldTitle);
        this.showSpinner = true;
        return;
      }
      this.lyricsSelected = "";
      $("#parole").html("");
      this.lyricsSearch = [];
      service
        .fetchLyrics(this.music.title, this.user.token)
        .then((result) => {
          this.showSpinner = true;
          console.log("@@@@@ result fetch lyrics: " + result);
          if (result.data.data.length > 0) {
            this.isEmptySearch = false;
            this.lyricsSearch = result.data.data;
            console.log("@@@ lyrics search: ", this.lyricsSearch);
          } else {
            $("#parole").html(
              '<p style="color: green; text-align: center;"> No Lyrics Found ! </p>'
            );
          }
        })
        .catch(function (error) {
          this.showSpinner = true;
          console.log("@@@@@ fetch lyrics error: ", error);
          $("#parole").html(
            '<p class="error"> System Error! Please contact administrator </p>'
          );
        });
      this.oldTitle = this.music.title;
    },

    saveSelected() {
      this.lyricsInput = document.getElementById("parole").innerText;
      document.getElementById("closeBtn").click();
    },
    
    /** End player functions */
    musikitEvents(){
      this.appleMusicKit.addEventListener("playbackTimeDidChange", (arg) => {
        this.p.currentTime = arg.currentPlaybackTime;
        this.p.duration = arg.currentPlaybackDuration;
        this.seek.max = arg.currentPlaybackDuration;
        if(this.isSyncActive){
          this.updateSyncLyrics();
        }
        this.initProgressBar();
      });
      this.appleMusicKit.addEventListener("loaded", () => {
        console.log("@@@@ player loaded!!!!!!!");
      })
      this.appleMusicKit.addEventListener("mediaPlaybackError", (err) => {
        console.log("@@@@ player mediaPlaybackError error: ", err); 
      })
      this.appleMusicKit.addEventListener("mediaCanPlay", (param) => {
        console.log("@@@@ player mediaCanPlay: ", param);
        this.mediaCanPlay = true;
      })
      this.appleMusicKit.addEventListener("eligibleForSubscribeView", (param) => {
        console.log("@@@@ player eligibleForSubscribeView: ", param); 
      })
      
    },

    showToast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 5000
            })
    },

    /*** Manage permission and role, use just in vue */
    configuration() {      
      this.isPublishAccess = (CONSTANTS.LRC_SHOW_PUBLISH_BTN.includes(this.user.roleId));
      if((this.music_p && this.music_p?.userEmail == this.user.email) || CONSTANTS.SHOW_ALL_LRC.includes(this.user.roleId)){
        this.isEditLRC = true;
      }
      if(this.music_p && CONSTANTS.LRC_BLOCK_STATUS.includes(this.music_p.status) && !CONSTANTS.LRC_EDIT_ALL_LYRICS.includes(this.user.roleId)){
        this.isEditLRC = false;
      }
    },

    pluginIntegration(url){          
        const plugin = document.createElement("script");
        plugin.setAttribute("src",url);
        plugin.async = true;
        document.head.appendChild(plugin);
    }, 

    searchExistingLrc() {
      service.searchExistLrcComp(this.music.artist, this.music.title, this.user.token)
        .then(async (result) => {
            if (result.data.data.data.length > 0) {
                const searchResults = result.data.data.data.find(elt => elt.external_id == this.music.externalId);
                if(searchResults && searchResults.lyric_url && searchResults.lrc_url){
                  const data = await service.getFile(searchResults.lyric_url, this.user.token);
                  const lyricsText = (typeof data.data == 'string') ? data.data : '';                  
                  const dataLrc = await service.getFile(searchResults.lrc_url, this.user.token);
                  const lyrics = (typeof dataLrc.data == 'string') ? dataLrc.data : '';

                  if(lyricsText && lyrics){
                    this.music.lyricsText = lyricsText;
                    this.playlist[this.playListIndex].lyricsText = lyricsText;

                    this.lyricsInput = lyricsText; /*********************** Lyrics text text text */
                    this.lrcInput = lyrics; /************************* LRC With Times times */
                    this.handleLrcInput();
                    this.ifExistingLrc = true;
                  }
                }
                console.log('>>>>>>>>>>>>>>>>> searchResults: ', searchResults);
            } else {
              console.log('>>>>>>>>>>>>>>>>> searchResults: No match');
              this.ifExistingLrc = false;
            }
            this.showSpinner = true;
        })
        .catch(function (error) {
            console.log('>>>>>>>>>>>>>>>>> searchResults error: ', error);
            this.ifExistingLrc = false;
        });
    },

    ws_getFile(fileUrl) {
        service.getFile(fileUrl, this.user.token)
            .then((res) => {
                return res.data;
            }).catch((err) => {
                console.log(err)
                return false;
            })
    },

    editLrcContent(){
      this.isShowModalToEditLrc = true;
    },

    closeModalLrc(){
      this.isShowModalToEditLrc = false;
    }
  },


  mounted: async function () {
      this.pluginIntegration("//cdnjs.cloudflare.com/ajax/libs/startbootstrap-sb-admin-2/4.1.3/js/sb-admin-2.min.js");
      $('.current_tab').removeClass('current_tab');
      $(`#appleMusicPlayer`).addClass('current_tab');

    if (!this.isLoggedIn) router.push({ name: "login" });

    const token = appleMusic.getToken();
    this.appleMusicKit = window.MusicKit.configure({
            developerToken: token,
            app: {
              name: "afrikaLyrics",
              build: "1.0.0",
            },
          });
          
    window.mukit = this.appleMusicKit;
    delete window.MusicKit;
    await this.appleMusicKit.authorize();          
    this.isUserAuthorise = await this.appleMusicKit.isAuthorized;
    if(this.isUserAuthorise == true){
      console.log('@@@ appleMusicKit: authorize!!!', this.appleMusicKit);
      this.musikitEvents();

      this.music = new Music();
      this.lrc = new Lrc();
      this.init();
      if(this.music_p?.externalId) this.searchSongWithId();
    }
    // this.playerLoaded = true;
    this.configuration();
  },


  computed: {
    isLoggedIn() {
      return this.$store.state.login;
    },
    showSaveLyrics: function () {
      if (this.lyricsInput) return false;
      else return true;
    },
    showhandleLrc: function () {
      if (this.lrcInput) return false;
      else return true;
    },
    showSaveLrc: function () {
      if (this.lyricsLines.length > 1 && !this.isPlayLrc) return false;
      else return true;
    },
    user() {
      return this.$store.state.User;
    }
  },

  watch: {
    lyricsSelected: function (val) {
      this.isEmptySearch = false;
      for (let index = 0; index < this.lyricsSearch.length; index++) {
        if (this.lyricsSearch[index].tag.trim() === val) {
          $("#parole").html("");
          $("#parole").html(this.lyricsSearch[index].paroles);
          break;
        }
      }
    },
    music: function(val){
      if(val.Lrc) this.isSaveDisable = false;
    },
  },
};
</script>

<style scoped>

.editor-name {
  font-size: 1.5em;
  font-style: bolder;
  color: white;
  font-style: italic;
  margin: 2px 0 0 10px;
  /* text-align: right; */
  /* margin-right: 5px; */
}

h2::after {
  content: none;
}

.hideItem {
  display: none;
}

.displayLyricsTxt {
  transform: translateY(-40%);
}

.btnColor {
  color: #6c3483;
}

.bg-image {
  /* The image used */
  background-image: url("../../assets/img/appleLogo6.jpg");
  
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  
  /* Full height */
  height: 100%; 
  
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}

#music-player {
  position: relative;
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%); */
  background-image: url('../../assets/img/pexels-1.jpg');
  background-size: cover;
}

#music-player::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.8)
}

#music-player22 {
  background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
  filter: blur(1px);
  -webkit-filter: blur(1px);

  width: 100%;
  /* height: 100vh; */
  height: 100%;
  margin: 0;
  padding: 0;
  /* font-family: "Poppins", sans-serif; */
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}

#repeat {
  color: gray;
  opacity: 1;
  border: 2px;
}
#top-bar {
  position: relative;
  height: 8vh;
  color: white;
  width: 90%;
  padding: 0 0 0 5%;
  z-index: 50;
}
#top-bar > * {
  display: inline-block;
}
#top-bar button {
  margin: 0;
  background: inherit;
  border: none;
  color: white;
  font-size: 100%;
  vertical-align: middle;
  transform: translateY(-40%);
  padding: 5px 10px;
}
#about-song {
  width: 60%;
  margin: 0 5%;
  line-height: 1vh;
  font-size: 70%;
}
.song-name {
  color: white;
  font-weight: bolder;
}
.artist-name {
  color: #ffffff79;
}
@media only screen and (max-width: 340px) {
  #top-bar > button {
    font-size: 15px;
  }
  #top-bar > #about-song * {
    font-size: 120%;
    line-height: 0;
  }
  .menu button {
    font-size: 5vw !important;
    padding: 4px 6px !important;
  }
  #progress-bar {
    width: 50% !important;
  }
}
.lyrics {
  width: 100%;
  height: 60vh;
  color: white;
  text-align: center;
  overflow-y: scroll;
  font-size: 2vh;
}
#playerMedia button{
  color: white;
}
.lyrics-content {
  margin: 10px;
  padding-bottom: 20vh;
  transition: ease 0.1s all;
}
.lyrics h2,
h3 {
  opacity: 0.25;
}
.lyrics .current {
  opacity: 1;
  font-size: 250%;
  transform: translateY(25%);
}
.lyrics .current + h2 {
  opacity: 0.5;
  font-size: 180%;
}

.lyrics .current1 {
  opacity: 1;
  font-size: 250%;
  transform: translateY(25%);
}
.lyrics .current1 + h3 {
  opacity: 0.5;
  font-size: 180%;
}

#playerMedia {
  background: #00000045;
  position: inherit;
  bottom: 0;
  height: 25vh;
  width: 100%;
  z-index: 50;
}
#bar {
  position: relative;
  text-align: center;
  width: 100%;
  padding-top: 25px;
}
#currentTime,
#totalTime {
  transform: translateY(-50%);
  padding: 0 2%;
  font-size: 3vh;
}
@media only screen and (min-height: 500px) {
  #currentTime,
  #totalTime {
    font-size: 2.25vh !important;
  }
}
#currentTime,
#progress-bar,
#totalTime {
  color: white;
  display: inline;
}
#progress-bar {
  position: relative;
  text-align: center;
  height: 0.25em;
  width: 70%;
  border: 1px solid #222;
  background: #333;
  border-radius: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transform: translateY(-160%);
}
#progress {
  height: 100%;
  width: 0%;
  background: red;
  border-radius: 20px;
  text-align: right;
  transition: ease all;
}
#progress > i {
  position: absolute;
  transform: translate(-50%, -35%);
}
.menu {
  position: relative;
  text-align: center;
  /* width: 100%; */
  /* overflow: hidden; */
}
.menu button {
  padding: 10px 14px;
  border-radius: 50%;
  border: none;
  margin: 0 3px;
  background: inherit;
  font-size: 20px;
  text-align: center;
  opacity: 0.75;
  cursor: pointer;
}
.menu button > i {
  padding: 5px 3px 5px 5px;
}
.menu button#play {
  opacity: 1;
  /* border: 2px solid white; */
}
.menu button:focus {
  outline: none;
}
#show-box {
  position: absolute;
  top: 70%;
  left: 50%;
  height: 30vh;
  width: 70%;
  padding: 4vh;
  transform: translate(-50%, -70%);
  overflow: auto;
}

.menuItem {
  text-align: right;
  color: white;
}
/* .float-song-card > h2,
.float-song-card > h4 {
  position: relative;
  z-index: 49;
  margin: 2px 0;
} */
.lyrics::-webkit-scrollbar,
#show-box::-webkit-scrollbar {
  width: 5px;
}
.lyrics::-webkit-scrollbar-track,
#show-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.lyrics::-webkit-scrollbar-thumb,
#show-box::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 15px;
}
.floating-icon {
  position: absolute;
  bottom: 5%;
  z-index: 1000;
  font-size: 4vh;
  border: 1.5px solid white;
  border-radius: 50%;
  padding: 1vh 2vh;
  background: #222;
}
.floating-icon:nth-child(1) {
  right: 3vh;
}
.floating-icon:nth-child(2) {
  right: 12vh;
}

.seek {
  align-items: center;
  justify-content: center;
  flex: 3;
  width: 80% !important;
  margin: auto;
}

@media (max-width: 800px) {
  .seek {
    width: 65% !important;
  }
}
.vol {
  align-items: center;
  justify-content: center;
  flex: 3;
  /* width: 5%; */
  margin: 2px auto auto 0;
}

/** Text area */
.text-area-custom {
  font-size: 1.3em;
  font-family: "Poppins", sans-serif;
  font-style: bolder;
  width: 900px;
  height: 230px;
  line-height: 1.5;
  padding: 15px 10px;
  border: 1px solid gray;
  color: white;
  text-align: center;
  background: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
  transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
    transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
  margin-top: 60px;
}

@media (max-width: 916px) {
  .text-area-custom {
    width: 330px;
  }
}

.text-area-custom ::placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  color: white;
  opacity: 1;
}

.text-area-custom:focus {
  background: rgb(57, 134, 153, 0.1);
  color: white;
  font-style: italic;
  font-size: 1.5em;
  text-align: center;
  /* background: hsl(0, 7%, 20%); */
  transform: scale(1.02);
}
/** End text area */

/** Text search *
.editor-name input {
  font-family: "Poppins", sans-serif;
  border: 0px;
  color: white;
  background-color: #1a3753;
}

.editor-name input:focus {
  background: #1a3753;
  color: white;
  font-style: italic;
}
.editor-name i{
  margin-top: 5px;
  color: #676879;
}
/** End text search */


@media only screen and (min-height: 700px) {
  .textarea {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .hideSmallMedia {
    display: none;
  }
  .lyrics {
    width: 100%;
    height: 60vh;
    text-align: center;
    overflow-y: scroll;
    font-size: 1vh;
  }
  .lyrics .current + h2 {
    opacity: 0.5;
    font-size: 150%;
  }
  .lyrics .current1 + h3 {
    opacity: 0.5;
    font-size: 150%;
  }
  .lyrics .current {
    opacity: 1;
    font-size: 350%;
    transform: translateY(25%);
  }

  .lyrics .current1 {
    opacity: 1;
    font-size: 350%;
    transform: translateY(25%);
  }
}
.currentItem {
  color: #4abd22;
  font-weight: bolder;
}

.resultItem{
  /* border-bottom: 1px solid green; */
  border-top: 1px solid green;
  margin: 2px 10% 0px 10%;
  padding: 12px 0 12px 0;
}
.resultItem:hover{
  background-color: #1a3753;
}
#quickSearch{
  height: 60vh;
  color: white;
  overflow-y: scroll;
}
.quickSearch::-webkit-scrollbar {
  display: none;
}
.song-animate {
    font-size: 0;
    /* position: absolute; */
    /* visibility: hidden; */
    width: 20px;
    height: 23px;
  }
  .song-animate
  > i {
  position: relative;
  display: inline-block;
  width: 3px;
  height: 100%;
  margin-right: 2px;
}
  .song-animate
    > i:after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: green;
  }
div.song-animate.pause i:after {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.song-animate
    > i:nth-of-type(1):after {
    -webkit-animation: waves 0.6s linear 0s infinite alternate;
    animation: waves 0.6s linear 0s infinite alternate;
  }
  .song-animate
    > i:nth-of-type(2):after {
    -webkit-animation: waves 0.9s linear 0s infinite alternate;
    animation: waves 0.9s linear 0s infinite alternate;
  }
  .song-animate
    > i:nth-of-type(3):after {
    -webkit-animation: waves 1.1s linear 0s infinite alternate;
    animation: waves 1.1s linear 0s infinite alternate;
  }
  
@-webkit-keyframes waves {
  10% {
    height: 20%;
  }
  20% {
    height: 60%;
  }
  40% {
    height: 40%;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 50%;
  }
}
@keyframes waves {
  10% {
    height: 20%;
  }
  20% {
    height: 60%;
  }
  40% {
    height: 40%;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 50%;
  }
}

.button.play {
  border-style: solid;
  box-sizing: border-box;
  border-width: 10px 0px 10px 25px;
  border-color: transparent transparent transparent green;
}

/* Dropdown Button */
.dropdown {
  position: relative;
  display: inline-block;
  background-color: #198754;
  border-radius: 3px;
  padding: 10px;
  color: white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  /* padding: 12px 16px; */
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
  background-color: #0d324d;
}

.dropdown-content p:hover{
  background-color: #63b175;
  cursor: pointer;
  text-decoration: none;
  color: white;
  padding:  12px 12px;
}

/** Search Apple Btn */
#searchInput {
    height: 50px;
    /* width: 100%; */
    border: 2px solid #144d07;
    border-radius: 20px;
    /* margin: 0 auto 10px 20px; */
    /* margin: 0 0 0 20px; */
    /* padding: 4px; */
}

#searchInput i {
    size: 2.5em;
    color: #144d07;
}

#searchInput input {
    font-family: "Poppins", sans-serif;
    border: 0px;
    color: white;
    background-color: rgba(13, 50, 77, 0.05);
    padding: 8px 10px 0 10px;
    width: 90%;
}

#searchInput input ::placeholder {
    border: 0px;
    color: white;
    background-color: rgba(13, 50, 77, 0.05);;
}

#searchInput input:focus {
    background-color: rgba(13, 50, 77, 0.05);;
    color: white;
    outline: none !important;
}

@media (max-width: 1000px) {
  #searchInput {
    height: 50px;
    width: 90%;
    border: 2px solid #144d07;
    border-radius: 20px;
    margin: 0 auto 10px 20px;
  }
}

@media (min-width: 1010px) {
  .hideBigMedia{
    display: none;
  }
}

.areaEditLrc {
    width: 100%;
    height: 500px;
}
</style>