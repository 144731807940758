<template>
    <div class=" " id="export-video">
     <div class="editor-content">
        <Player/>
        <Subtitles/>
        <Tools/>
     </div>

      <div class="footer ">
        <div id="waveform" style="height: 200px"></div>
        <Graber/>
        <Timeline/>
      </div>
     
      
    </div>
</template>

<script >
 import * as service from "../../utils/DC_CalloutService.js";
 import  {Sub} from './utils/sub'
 import lrc2Sub from './libs/readSub/lrc2sub'
 import Timeline from "./components/Timeline.vue";
 import Graber from "./components/Graber.vue";
 import Player from "./components/Player.vue";
import Subtitles from "./components/Subtitles.vue";
import Tools from "./components/Tools.vue";
import { getFile } from "../../utils/DC_CalloutService.js";

export default {
    components:{
    Timeline,
    Graber,
    Player,
    Subtitles,
    Tools
},
    name:"ExportVideoHome",
    computed: {
        isLoggedIn() {
            return this.$store.state.login;
        },

        user() {
            return this.$store.state.User;
        },
    },
    methods:{
       async fetchLyric(id){
           try {
           this.lyric =  await service.getLyricDetails(id,this.user.token)

           if(this.lyric.lrc_url!=null){
             var  lrcString =  (await getFile(this.lyric.lrc_url)).data
             console.log(lrcString);
            var subs=   lrc2Sub(lrcString)
            console.log(subs);
            this.$store.commit("editor/setSubs",subs)
            }else{
            fetch("https://subplayer.js.org/sample.json")
                .then((resp)=>resp.json())
                .then(resp=>{
                var subs = resp.map((item)=>new Sub(item));
                    this.$store.commit("editor/setSubs",subs)
                })
            }
           } catch (error) {
                console.log(error);
           }
        }
        
    },
    data(){
        return {
            lyric: null
        }
    },
    mounted(){
        this.$store.dispatch("editor/initPlayer",{ 
            container: document.querySelector('#waveform'),
            mediaElement: document.querySelector('#video'),
        })

        this.fetchLyric(this.$route.query.lyric_id)
    }

}

</script>

<style lang="scss" scoped>
#export-video{
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 150px);
    justify-content: space-between;

    .editor-content{
        padding-bottom: 20px;
        display: flex;
        flex-direction: row;
    }
    
    .footer{
        position: relative;
        
    }
}


</style>