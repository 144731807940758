import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {  editor} from './export_video_store'
Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        editor
    },
    state: {
        User: {
            id: '',
            name: '',
            pseudo: '',
            email: '',
            valide: '',
            date_inscription: '',
            email_verified_at: '',
            updated_at: '',
            date_modification: '',
            username: '',
            token: '',
            roleName: '',
            roleId: ''
        },
        login: false,
        currentPlaylist: []
    },
    plugins: [createPersistedState(
        {
            reducer: (state) => {
              let reducer = Object.assign({}, state)
              delete reducer.editor
              return (reducer)
            }
          }
    )],
    mutations: {
        login(state, user) {
            state.User = user;
            state.login = true;
        },
        logout(state) {
            state.User = "";
            state.login = false;
        },
        insertMusic(state, playlist) {
            state.currentPlaylist = playlist
        }
    },
    actions: {
        login(context, user) {
            context.commit("login", user);
        },
        logout(context) {
            context.commit("logout");
        },
        insertMusic(context, playlist) {
            context.commit("insertMusic", playlist);
        }
    },
})