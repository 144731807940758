<template>
    <div>
        <!-- Begin Page Content -->
        <div class="container-fluid mt-3">
            <!-- DataTales -->
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">All Lyrics To review (<i>{{requestList.length}}</i>   requests Loaded)</h6>
                </div>
                <div class="card-body">
                    <div class="text-center" v-if="!listReqLoaded">
                        <i class="loadMore text-center mt-5 pt-5">This operation can take few minutes!</i>
                    </div>
                    <div v-if="listReqLoaded">                        
                        <div class="table-responsive">
                            <div class="text-center">
                                <div class="dropdown no-arrow">
                                    <a class="dropdown-toggle btn btn-primary" href="#" role="button" id="displayColumns"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="icon text-white-50">
                                            <i class="fas fa-filter"></i>
                                        </span>
                                        <span class="text pl-2">Display columns</span>                                     
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                        aria-labelledby="displayColumns">
                                        <template  v-for="(c, i) in columns">
                                            <a class="dropdown-item toggle-vis" :id="c" :key="i" :data-column="i">
                                                {{ c }}
                                            </a>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <table class="table table-bordered text-center" id="dataTableAllRequest" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Title</th>
                                        <th>Artist_name</th>
                                        <th>Provider</th>
                                        <th>External_id</th>
                                        <th>Created_at</th>
                                        <th>Updated_at</th>
                                        <th>Album</th>
                                        <th>Author</th>
                                        <th>Language</th>
                                        <th class="userColDef">Assigned_to</th>
                                        <th>Assigned_by</th>
                                        <th>Assigned_at</th>
                                        <th>Review</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(r, i) in requestList" :key="i">
                                        <td>{{i}}</td>
                                        <td>{{r.title}}</td>
                                        <td>{{r.artist_name}}</td>
                                        <!-- <td>{{r.provider}}</td> -->
                                        <td> 
                                            <a href="#" :class="`btn ${ (r.provider != 'Apple Music') ? 'btn-warning' : 'btn-primary'} btn-circle`">
                                                <i :class="`${(r.provider != 'Apple Music') ? 'fas fa-laptop' : 'fab fa-apple'}`"></i>
                                            </a>                                    
                                        </td>
                                        <td>{{r.external_id}}</td>
                                        <td>{{r.created_at ? r.created_at.substring(0, 16).replace('T', ' ') : ''}}</td>
                                        <td>{{r.updated_at ? r.updated_at.substring(0, 16).replace('T', ' ') : ''}}</td>
                                        <td>{{r.album}}</td>
                                        <td>{{r.author}}</td>
                                        <td>{{r.language}}</td>
                                        <td class="userColDef">{{r.assigned_to}}</td>
                                        <td>{{r.assigned_by}}</td>
                                        <td>{{r.assigned_at ? r.assigned_at.substring(0, 16).replace('T', ' ') : ''}}</td>
                                        <td>
                                            <a href="#" class="btn btn-success  btn-circle" @click="playInApplePlayer(i)">
                                                <!-- <span class="icon text-white-50"> -->
                                                    <i class="fas fa-eye"></i>
                                                <!-- </span> -->
                                                <!-- <span class="text pl-2">review</span> -->
                                            </a>
                                        </td>
                                        <td>
                                            <span class="row">
                                                <span class="col-3 ml-1">
                                                    <a class="btn btn-primary btn-circle" href="#" @click="viewFile(i)" customClass='colorTooltip' data-bs-toggle="tooltip" data-bs-placement="top" title="View and edit LRC">
                                                        <i class="bi bi-music-note-list"></i>
                                                    </a>
                                                </span>
                                                <span class="col-3 ml-2" data-bs-toggle="modal" data-bs-target="#editLrcTag">
                                                    <a class="btn btn-primary btn-circle" data-bs-toggle="tooltip" href="#" customClass='colorTooltip' data-bs-placement="top" title="Edit LRC tags" @click="currentReqSelected(i)">
                                                        <i class="bi bi-bookmark-plus"></i>
                                                    </a>
                                                </span>
                                                <!-- <span class="col-3">
                                                    <a class="btn btn-primary btn-circle" href="#" customClass='colorTooltip' data-bs-toggle="tooltip" data-bs-placement="top" title="Play in Apple Player" @click="playInApplePlayer(index)">
                                                        <i class="bi bi-play-circle"></i>
                                                    </a>
                                                </span> -->
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div><br/>        
                        
                        <div class="row">
                            <div class="col-8"></div>
                            <div class="col-2 loadMore text-end pe-2" :hidden="isLoadMore">
                                <p class="load-more" @click="getLrcList()">Load more data (50)</p>
                            </div>
                            <div class="col-2 loadMore pe-2" :hidden="isLoadMore">
                                <p class="load-more" @click="seeAllLrc()">Load all Lrc</p>
                            </div>
                        </div>
                        <br/>
                        <h3 class="warning_msg">{{emptyMsg}}</h3>
                    </div>

                    <div v-else>
                        <Spinner v-bind:showSpinner="listReqLoaded" type="lg"></Spinner>
                    </div>

                    <div :hidden="isPlayInApple">
                        <Spinner v-bind:showSpinner="isPlayInApple" type="lg"></Spinner>
                    </div>

                    <div class="modal fade" id="editLrcTag" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
                            <div class="modal-content" id="editLrcContentModal">
                                <div class="modal-header">
                                    <h4 class="modal-title headerEdit" id="staticBackdropLabel">
                                        Edit Tags for: <strong> {{currentReq.artist}}, {{currentReq.title}} </strong>
                                    </h4>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBtnLrcTag">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <label for="lrcTag" class="form-label">Lrc Tags (separate each value with comma ",")</label>
                                    <input id="lrcTag" v-model="lrcTagInput" type="text" class="form-control" placeholder="Enter tags here" aria-label="album">
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" class="btn btn-success" @click="updateLrcTag()" :disabled="isUpdateTag">Update LRC Tag</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <LrcEditModal v-bind:music="music" v-bind:lyrics="lyrics_p" v-bind:is-show-modal="isShowModal" v-bind:is-settings="isSettings"></LrcEditModal>
        <ChooseLocalSong v-bind:music="this.music_l" v-bind:is-show-modal="isPlayInLocal"></ChooseLocalSong>
    </div>
</template>

<script>
import LrcEditModal from '../LyricsEditContent.vue'
import Spinner from '../SpinnerAfBig.vue';
import ChooseLocalSong from '../ChooseLocalSong.vue';
import * as service from "../../utils/DC_CalloutService.js";
import router from "../../router";
import * as dc_player from '../../utils/DC_player.js';
import { Lrc } from "lrc-kit";
import { Music, Constants } from '../../utils/DC_player.js';

const $ = require("jquery");
window.$ = $;

const CONSTANTS = new Constants();

export default {
    name: "AllLyricsSync",
    data() {
        return {
            columns: ['Id', 'Title', 'Artist_name', 'Provider', 'External_id', 'Created_at', 'Updated_at', 'Album', 'Author',
                    'Language', 'Assigned_to', 'Assigned_by', 'Assigned_at', 'Is Lyrics', 'Review', 'Actions'],
            columnsToHide: ['Language', 'Assigned_to', 'Assigned_by', 'Assigned_at','Created_at', 'Updated_at', 'Author', 'Actions'],
            requestList: [],
            reqSaveListTmp: [],
            listReqLoaded: false,
            emptyMsg: '',
            isShowModal: false,
            currentReq: {},
            music: {},
            pageNumber: 1,
            lyrics_p: '',
            lrcTagInput: '',
            isUpdateTag: true,
            isPlayInApple: true,
            isPlayInLocal: false,
            music_l: {},
            isLoadMore: true,
            lastPage: 0,
            isLoadDataDesc: false,
            isSettings: false,
            checkedTable: [],
            isShowLrcActionsBtn: true,
            isUpdateLrcStatus: false,
            idUser: '',
        };
    },

    props: {
       
    },

    components: {
        LrcEditModal,
        Spinner,
        ChooseLocalSong
    },

    computed: {
        isLoggedIn() {
            return this.$store.state.login;
        },

        user() {
            return this.$store.state.User;
        },
    },

    mounted() {
        this.idUser = this.user.id;
        this.currentReq = new dc_player.Music();
        this.getLrcList();
    },

    methods: {
        async getFile(index) {
            const selected = this.requestList[index];
            if (!selected.lyric_url && !selected.lrc_url) {
                alert('Url not found for this row');
                return false;
            }

            if (selected.lyric_url) {
                const data = await service.getFile(selected.lyric_url, this.user.token);
                this.currentReq.lyricsText = (typeof data.data == 'string') ? data.data : '';
            }

            if (selected.lrc_url) {
                const url = selected.lrc_url;
                const dataLrc = await service.getFile(url, this.user.token);
                this.currentReq.lyrics = (typeof dataLrc.data == 'string') ? dataLrc.data : '';
                this.lyrics_p = this.currentReq.lyrics;
            }
            return true;
        },

        viewFile(index) {
            this.currentReqSelected(index);
            const isfile = this.getFile(index);
            if (!isfile) return;

            this.music = this.currentReq;
            this.isShowModal = true;
            this.isSettings = true;
        },

        currentReqSelected(index) {
            const selected = this.requestList[index];
            this.currentReq.id = selected.id;
            this.currentReq.artist = selected.artist_name;
            this.currentReq.title = selected.title;
            this.currentReq.externalId = selected.external_id;
            this.currentReq.lrc_url = selected.lrc_url;
            this.currentReq.lyric_url = selected.lyric_url;
            this.lrcTagInput = selected.tags.map(elt => elt.name);
            this.currentReq.indexList = index;
        },

        async getLastPage(){
             await service.getAllListLrc(100000, this.user.token, this.idUser)
                .then((res) => {
                    if (res.data.data.data.length == 0) {
                        this.lastPage = res.data.data.last_page;
                    }
                }).catch(() => {this.lastPage = 0});
        },

        async getLrcList() {
            try {
                this.listReqLoaded = false;
                let hideCols = this.columnsToHide;
                if(this.pageNumber == 1 && this.lastPage == 0) {
                    await this.getLastPage();
                    if(this.lastPage != 0){
                        this.isLoadDataDesc = true;
                        this.pageNumber = this.lastPage;
                    }
                }
                await service.getAllListLrc(this.pageNumber, this.user.token, this.idUser)
                    .then((res) => {
                        if (res.data.data.data.length > 0) {
                            this.reqSaveListTmp = [];
                            this.reqSaveListTmp = [...res.data.data.data].reverse().filter(r => (r.reviewed_by == 'null' || !r.reviewed_by) && r.user?.id != this.user.id);
                        }
                        if (this.reqSaveListTmp.length == 0) {
                            this.listReqLoaded = true;
                            this.emptyMsg = 'Not record found!';
                            return;
                        }

                        var table = $('#dataTableAllRequest').DataTable();
                        table.destroy();

                        this.requestList = [...this.requestList, ...this.reqSaveListTmp];
                        $(document).ready(function() {
                            let table = $('#dataTableAllRequest').DataTable();
                            $('a.toggle-vis').on( 'click', function (e) {
                                e.preventDefault();
                                let column = table.column( $(this).attr('data-column') );                                
                                column.visible() ? $(`#${$(this).attr('id')}`).addClass('text-danger') : $(`#${$(this).attr('id')}`).removeClass('text-danger');
                                column.visible( ! column.visible() );
                            } );
                            
                            hideCols.forEach(c => {
                                let column = table.column( $(`#${c}`).attr('data-column'));
                                $(`#${c}`).addClass('text-danger');
                                column.visible( false );
                            });
                        });

                        const check = this.isLoadDataDesc ? (1 < this.pageNumber) : (res.data.data.last_page > this.pageNumber);

                        if (check) {
                            if(this.isLoadDataDesc) 
                                this.pageNumber--;
                            else 
                                this.pageNumber++;

                            this.isLoadMore = false;
                        } else {
                            this.isLoadMore = true;
                        }
                        this.listReqLoaded = true;
                    }).catch((error) => {
                        console.log(error);
                        this.emptyMsg = 'An error occur please contact system administrator!';
                        this.listReqLoaded = true;
                    }).finally(()=>{
                        if(this.idUser) $( "#userColDef" ).remove();
                        this.hideColumns();
                    })             
            } catch (error) {
                this.listReqLoaded = true;
                this.showToast('An error occur, contact your administrator', 'error');
            }
        },

        async updateLrcTag() {
            const data = await service.updateLrc(this.currentReq.id, this.currentReq.artist, this.currentReq.title, null, this.lrcTagInput, this.user.token);
            const res = data.status == 200
            if (res) {
                this.requestList[this.currentReq.indexList].tags = this.lrcTagInput;
            }
            this.showToast(res ? 'Success update' : 'Update error', res ? 'success' : 'error');

            if (res) {
                const btn = document.getElementById("closeBtnLrcTag");
                btn.click();
            }
        },

        async playInApplePlayer(index) {
            try {
                this.currentReqSelected(index);
                if (!this.currentReq.externalId) {
                    this.playLocalsong();
                    return;
                }

                this.isPlayInApple = false;

                await this.getFile(index);

                let lrc, lineslrc = [];
                if (this.currentReq.lyrics) {
                    lrc = Lrc.parse(this.currentReq.lyrics);
                    let tab = this.currentReq.lyrics.trim().split("\n").filter(line => line.trim());
                    for (let index = 0; index < tab.length; index++) {
                        if (tab[index].includes('[0')) {
                            lineslrc.push(tab[index]);
                        }
                    }

                    let mus = new Music(
                        this.currentReq.id,
                        this.currentReq.title,
                        this.currentReq.artist,
                        this.currentReq.album,
                        undefined,
                        "https://afrikalyrics.com/assets/images/logo-fav.png",
                        undefined,
                        lrc,
                        lineslrc,
                        this.currentReq.lyricsText, this.currentReq.externalId);
                    this.isPlayInApple = true;
                    if (mus.id && mus.externalId) router.push({ name: "lyricsPlayerApple", params: { music_p: mus } });
                }
            } catch (error) {
                this.isPlayInApple = true;
                this.showToast('An error occur! we cannot play this song in Apple Player', 'error');
            }
        },

        playLocalsong() {
            this.isPlayInLocal = true;
            this.music_l = this.currentReq;
        },

        showToast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 5000
            })
        },    

        async ws_updateLrc(lrcChecked) {
            await service.updateLrc(lrcChecked.id, lrcChecked.artist_name, lrcChecked.title, null, null, 
                                    this.user.token, null, CONSTANTS.LRC_STATUS_PUBLISHED)
            .then(() => {
                
            })
            .catch(() => {
                
            });
        },

        async seeAllLrc(){
            await this.getLrcList();
            if(!this.isLoadMore){
                this.seeAllLrc();
            }
        },

        hideColumns(){
            $(document).ready(function() {
                let table = $('#dataTableAllRequest').DataTable();
                this.columnsToHide.forEach(c => {
                    let column = table.column( $(`#${c}`).attr('data-column'));
                    column.visible( false );
                });
            });
        }
    },

    watch: {
        lrcTagInput: function (val) {
            this.isUpdateTag = val != '' ? false : true;
        },

        checkedTable: function(newVal){
            if(newVal.length){ 
                this.isShowLrcActionsBtn = false
            }else{
                this.isShowLrcActionsBtn = true
            }
        }

    }
};
</script>

<style scoped>

.load-more{
    cursor: pointer;
}

.load-more:hover{
    color: green;
    font-weight: bold;
}

a{
    cursor: pointer;
}
</style>
