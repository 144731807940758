<template>
    <div class="player" >
        <div class="video">
            <video id="video" @click="onPlayerClicked" ref="player" src="https://subplayer.js.org/sample.mp4?t=1"></video>
            <div v-if="currentSub" class="subtitle">
                <textarea  @focus="(event)=>onTextAreaFocused(currentSub,event)" class="textarea" :class="player.paused?'pause':''"  :value="currentSub.text"  @input="(event)=>updateSub(event,currentSub)"></textarea>
            </div>
        </div>
       
    </div>
</template>

<script>
    export default {
        data(){
            return {

            }
        },
        computed:{
            player(){
                return this.$store.state.editor.player;
            },
            currentSub(){
                var currentIndex = this.$store.getters['editor/currentIndex']
                if(currentIndex>=0){
                    return this.$store.state.editor.subs[currentIndex]
                }else{
                    return null
                }   
            }
        },
        mounted(){
            this.$store.dispatch("editor/initVideoPlayer",this.$refs.player)
        },
        methods:{
            updateSub(event,sub){
            this.$store.commit("editor/updateSub",{
                sub,
                obj:{
                text:event.target.value,
            }
            })
        },
        onTextAreaFocused(sub,event){
            event.stopPropagation()
            const player =this.$store.state.editor.player
           
            if(player ){
                 player.pause()
                if (player.duration >=sub.startTime) {
                    this.$store.commit("editor/setCurrentTime", sub.startTime + 0.001)
                }
            }
        },
            onPlayerClicked(){
                const player = this.$store.state.editor.player
                if(player){
                    if(player.playing){
                        player.pause()
                    }else{
                        player.play()
                    }
                }

            }
        }

    }
</script>

<style lang="scss" scoped>
    .player{
        display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .video {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: auto;
        position: relative;

        video {
            position: relative;
            z-index: 10;
            outline: none;
            max-height: 100%;
            max-width: 100%;
            min-width: 500px;
            box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 80%);
            background-color: #000;
            cursor: pointer;
        }

        .subtitle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 20;
            left: 0;
            right: 0;
            bottom: 5%;
            width: 100%;
            padding: 0 20px;
            user-select: none;
            pointer-events: none;

            .operate {
                padding: 5px 15px;
                color: #fff;
                font-size: 13px;
                border-radius: 3px;
                margin-bottom: 5px;
                background-color: rgb(0 0 0 / 75%);
                border: 1px solid rgb(255 255 255 / 20%);
                cursor: pointer;
                pointer-events: all;
            }

            .textarea {
                width: 100%;
                outline: none;
                resize: none;
                text-align: center;
                line-height: 1.2;
                border: none;
                color: #fff;
                font-size: 20px;
                padding: 5px 10px;
                user-select: all;
                pointer-events: all;
                background-color: rgb(0 0 0 / 0);
                text-shadow: rgb(0 0 0) 1px 0px 1px, rgb(0 0 0) 0px 1px 1px, rgb(0 0 0) -1px 0px 1px,
                    rgb(0 0 0) 0px -1px 1px;

                &.pause {
                    background-color: rgb(0 0 0 / 50%);
                }
            }
        }
    }
}
</style>