<template>
    <div class="card o-hidden border-0 shadow-lg">
        <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
                <div class="col-lg-5 d-lg-block bg-register-image">
                    <form class="user p-5" v-on:submit.prevent="quickSearch()">
                        <div class="input-group text-center">
                            <input v-model="searchKey" type="text" class="form-control large" placeholder="Search song in Apple music...">
                            <div class="input-group-append">
                                <button class="btn btn-success" type="button" @click="quickSearch()">
                                    <i class="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form>

                    <fieldset class="form-group row pl-5">
                        <div class="col-sm-10">
                            <template  v-for="(s, i) in searchResults">
                                <div class="form-check"  :key="i">
                                    <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1"  @click='songSelected(i)'>
                                    <label class="form-check-label" for="gridRadios1">{{ s.attributes.name }}, {{ s.attributes.artistName }} -- {{ s.attributes.albumName }}</label>
                                </div>
                                <hr  :key="i+searchResults.length+1"/>
                            </template>
                        </div>
                    </fieldset>

                    <Spinner v-bind:showSpinner="!isSearch" type="lg"></Spinner>
                </div>
                <div class="col-lg-7">
                    <div class="p-5">
                        <div class="text-center">
                            <h1 class="h4 text-gray-900 mb-4">Add new lyrics request!</h1>
                        </div>
                        <form class="user">
                            <div class="form-group row">
                                <div class="col-sm-6 mb-3 mb-sm-0">
                                    <label for="appleMusicID">Apple Music Id</label>
                                    <input type="text" class="form-control" id="appleMusicID" v-model="request.appleMusicID"
                                        placeholder="" required>
                                </div>
                                <div class="col-sm-6">
                                    <label for="album">Album</label>
                                    <input type="text" class="form-control" id="album" v-model="request.album"
                                        placeholder="">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6 mb-3 mb-sm-0">
                                    <label for="artistName">Artiste Name</label>
                                    <input type="text" class="form-control" id="artistName" v-model="request.artistName"
                                        placeholder="" required>
                                </div>
                                <div class="col-sm-6">
                                    <label for="title">Artiste title</label>
                                    <input type="text" class="form-control" id="title" v-model="request.title"
                                        placeholder="" required>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6 mb-3 mb-sm-0">
                                    <label for="musicLang">Langage</label>
                                    <input type="text" class="form-control" id="musicLang" v-model="request.musicLang"
                                        placeholder="">
                                </div>
                                <div class="col-sm-6">
                                    <label for="musicCountry">Country</label>
                                    <input type="text" class="form-control" id="musicCountry" v-model="request.musicCountry"
                                        placeholder="">
                                </div>
                            </div>
                            <div class="form-group row">
                                    <div class="col-sm-6 mb-3 mb-sm-0">
                                        <label for="musicGenre">Genre</label>
                                    <input type="text" class="form-control" id="musicGenre" v-model="request.musicGenre"
                                        placeholder="">
                                </div>
                                <div class="col-sm-6">
                                    <label for="musicWrittenBy">Written By</label>
                                    <input type="text" class="form-control" id="musicWrittenBy" v-model="request.musicWrittenBy"
                                        placeholder="">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="musicWrittenBy">Lyrics Text</label>
                                <textarea class="form-control" id="musicLyrics" v-model="request.musicLyrics"
                                    placeholder=""></textarea>
                            </div>                             
                            <div class="form-group row">
                                <label>Select Users to Assign</label>
                            </div>
                            <div class="form-group row ml-1">
                                <div class="col-3 mb-2" v-for="(u, index) in usersList" :key="index">
                                    <span class="checked_block">
                                        <label class="container_check">{{ u.name || u.username || u.email }}
                                            <input type="checkbox" :id="u.id" :value="u.id" v-model="request.usersSelected">
                                            <span class="checkmark"></span>
                                        </label>
                                    </span>
                                </div>
                            </div>
                            <a class="btn btn-success btn-user btn-block" @click="createRequest()">
                                Create lyrics request
                            </a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from '../SpinnerAfBig.vue';
import * as appleMusic from "../../utils/DC_AppleMusic.js";
import * as service from "../../utils/DC_CalloutService.js";

const $ = require("jquery");
window.$ = $;

export default {
    components: {
        Spinner,
    },

  data() {
    return {
        users: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
        isSearch: false,
        searchKey: '',
        searchResults: [],
        request: {
            appleMusicID: '',
            album: '',
            artistName: '',
            title: '',
            musicLang: '',
            musicCountry: '',
            musicGenre: '',
            musicWrittenBy: '',
            musicLyrics: '',
            usersSelected: [],
            artworkUrl: ''
        },
        usersList: [],
    }
  },

    methods: {
        async quickSearch(){
            if(this.searchKey){
                this.isSearch = true;
                const isMusicId = +this.searchKey ? true : false;
                try {          
                    const res = isMusicId ? await appleMusic.searchSongWithId(this.searchKey) : await appleMusic.searchSong(this.searchKey.replaceAll(/\s|,|,\s/g,'+'));
                    if((isMusicId && (res.data && res.data.data.length > 0)) || (!isMusicId && (res.data && res.data.results.songs.data.length > 0))){
                        this.searchResults = !isMusicId ? res.data.results.songs.data :  res.data.data;
                    }else{
                        this.showToast('Not result found for this search', 'info');
                    }
                    this.isSearch = false;
                } catch (error) {
                    this.showToast('Not result found for this search', 'info');
                    this.isSearch = false;
                }
            }
        },

        songSelected(index){
            const song = this.searchResults[index].attributes;
            this.request.appleMusicID = this.searchResults[index].id;
            this.request.album = song.albumName;
            this.request.artistName = song.artistName;
            this.request.title = song.name;
            // this.request.musicLang = song.musicLang;
            // this.request.musicCountry = song.musicCountry;
            this.request.musicGenre = song.genreNames[0];
            // this.request.musicWrittenBy = song.musicWrittenBy;
            this.request.durationInMillis = song.durationInMillis;
            this.request.releaseDate = song.releaseDate;            
            this.request.artworkUrl = song.artwork.url;
            this.request.colors = [song.artwork.bgColor, song.artwork.textColor1, song.artwork.textColor2, song.artwork.textColor3, song.artwork.textColor5];
        },

        showToast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 5000
            })
        },

        createRequest() {
            let fileTxt = new File(
                [this.request.musicLyrics],
                `[AF_Lyrics]${this.request.title}_${this.request.artistName}.txt`
            );
            service.saveLrc(this.request.artistName, this.request.title, null, fileTxt, 'Apple Music', this.request.appleMusicID,
                            this.user.token, 'draft', this.request)
            .then((result) => {
                if (result?.data) {
                    this.showToast("Request save success !!", 'success');
                } else {
                    this.showToast("Request not save !!", 'error');
                }
            })
            .catch(function (error) {
                console.log("@@@@@ fetch lyrics error: ", error);
                this.showToast("System Error! Please contact administrator", 'error');
            });
        },

        async getUsers(){
             await service.getUsersList(this.user.token)
                .then((res) => {
                    if (res.data.data.length) {
                        this.usersList = res.data.data;
                    }else{
                        this.showToast('Not user found', 'warning');
                    }
                }).catch(() => {this.showToast('Get users error', 'error');});
        },
    },

    mounted: async function () {
       this.getUsers();
    },

    computed: {
        user() {
            return this.$store.state.User;
        },
    }
}
</script>

<style scoped>

</style>