class Music {
    constructor(id, title, artist, album, time, cover, src, lrc, lyrics, lyricsText, externalId = null, userEmail = null, status = null) {
        this.id = id;
        this.title = title;
        this.artist = artist;
        this.album = album;
        this.time = time;
        this.cover = cover;
        this.src = src;
        this.lyrics = lyrics;
        this.lrc = lrc;
        this.lyricsText = lyricsText;
        this.externalId = externalId;
        this.userEmail = userEmail;
        this.status = status;
    }
}

class Constants {
    constructor() {
        /** LRC Status */
        this.LRC_STATUS_DRAFT = 'draft';
        this.LRC_STATUS_WAITING = 'waiting';
        this.LRC_STATUS_VERFIED = 'verified';
        this.LRC_STATUS_PUBLISHED = 'published';

        /**User role and permission */
        this.LRC_USER_ROLE_SIMPLE = 1; // 'user';
        this.LRC_USER_ROLE_EDITOR = 2; // lyric-editor';
        this.LRC_USER_ROLE_SUPERVISOR = 4; // 'supervisor';
        this.LRC_USER_ROLE_ADMIN = 3; // super-admin';

        /**Users ACCESS Security */
        // this.LRC_USER_LEVEL = [this.LRC_USER_ROLE_EDITOR, this.LRC_USER_ROLE_SUPERVISOR, this.LRC_USER_ROLE_ADMIN];
        // this.LRC_EDITOR_LEVEL = [this.LRC_USER_ROLE_EDITOR, this.LRC_USER_ROLE_SUPERVISOR, this.LRC_USER_ROLE_ADMIN];
        // this.LRC_SUPERVISOR_LEVEL = [this.LRC_USER_ROLE_EDITOR, this.LRC_USER_ROLE_SUPERVISOR, this.LRC_USER_ROLE_ADMIN];
        // this.LRC_ADMIN_LEVEL = [this.LRC_USER_ROLE_EDITOR, this.LRC_USER_ROLE_SUPERVISOR, this.LRC_USER_ROLE_ADMIN];

        this.LRC_SHOW_PUBLISH_BTN = [this.LRC_USER_ROLE_EDITOR, this.LRC_USER_ROLE_ADMIN];
        this.LRC_EDIT_ALL_LYRICS = [this.LRC_USER_ROLE_EDITOR, this.LRC_USER_ROLE_ADMIN];
        this.LRC_CHANGE_STATUS = [this.LRC_USER_ROLE_EDITOR, this.LRC_USER_ROLE_ADMIN];
        this.LRC_DELETE_ALL_LRC = [this.LRC_USER_ROLE_ADMIN];
        this.LRC_MARK_COMPLETED_LYRIC = [this.LRC_USER_ROLE_ADMIN];
        this.LRC_MANAGE_USER_ROLES_PERMISSIONS = [this.LRC_USER_ROLE_ADMIN];
        this.SHOW_ALL_LRC = [this.LRC_USER_ROLE_EDITOR, this.LRC_USER_ROLE_ADMIN];
        this.LRC_BLOCK_STATUS = [this.LRC_STATUS_VERFIED, this.LRC_STATUS_PUBLISHED];
    }
}

function localDownload(content, name) {
    let element = document.createElement("a");
    element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(content)
    );
    element.setAttribute("download", name);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

function showToast(message, type) {
    this.$toasted.show(message, {
        type: type,
        theme: 'primary',
        position: 'top-center',
        duration: 5000
    })
}

export { Music, localDownload, showToast, Constants };